import { useState } from "react";
import AdminButton from "../../../core/admin/adminButton";
import AdminModal from "../../../core/admin/adminModal";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import LeftArrow from "../../../../img/svg/leftArrow";
import RightArrow from "../../../../img/svg/rightArrow";
import { useWatches } from "../../../../context/watchProvider";
import AdminToast from "../../../core/admin/adminToast";
import XIcon from "../../../../img/svg/xIcon";
import { braceletMaterialFields, caseMaterialFields, glassFields, movementFields, stateFields, statusColors, statusFields } from "../../../../utils/fields";
import { IWatch } from "./adminWatches";

export default function WatchModal({ close, editableWatch, refresh }: { close: () => void, editableWatch: IWatch | null, refresh: () => void }) {

    const { updateWatch, getAdvertiser } = useWatches();

    const [imagesModal, setImagesModal] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    const [imageIndex, setImageIndex] = useState(0);
    const [update, setUpdate] = useState(false);
    const [status, setStatus] = useState(editableWatch?.status);

    const [propsModal, setPropsModal] = useState(false);
    const [loadingAdv, setLoadingAdv] = useState(false);
    const [advModal, setAdvModal] = useState(false);
    const [advertiser, setAdvertiser] = useState<{ id: string, email: string, phoneNumber: string, name: string } | null>(null);

    const [isUpdating, setIsUpdating] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const [comment, setComment] = useState(editableWatch?.comment);

    const handleImageLoad = () => {
        setImageLoading(false);
    }

    const nextImage = () => {
        setImageLoading(true);
        setImageIndex(imageIndex + 1);
    }

    const prevImage = () => {
        setImageLoading(true);
        setImageIndex(imageIndex - 1);
    }

    const doUpdate = async () => {
        setIsUpdating(true);
        if(editableWatch?.status === "sold"){
            setBadToast(true);
            setIsUpdating(false);
            return null;
        }
        if (editableWatch?.id && status) {
            const updateCall = await updateWatch(editableWatch?.id, { status: status, isBanner: false });
            if (updateCall) {
                setGoodToast(true);
                setIsUpdating(false);
                setUpdate(false);
                editableWatch.status = status;
                refresh();
                return null;
            }
            setBadToast(true);
            setIsUpdating(false);
        }

    }

    const updateComment = async () => {
        setIsUpdating(true);
        if (editableWatch?.id && (comment || comment === "")) {
            const updateCall = await updateWatch(editableWatch?.id, { comment: comment });
            if (updateCall) {
                setGoodToast(true);
                setIsUpdating(false);
                setUpdate(false);
                editableWatch.comment = comment;
                refresh();
                return null;
            }
            setBadToast(true);
            setIsUpdating(false);
        }
        setIsUpdating(false);
    }

    const getAdvertiserInfo = async () => {
        if (editableWatch?.advertiser) {
            setAdvModal(true);
            setLoadingAdv(true)
            const adv = await getAdvertiser(editableWatch?.advertiser);
            if (adv) {
                setAdvertiser({ id: adv?.id, name: adv?.name, email: adv?.email, phoneNumber: adv?.phoneNumber });
                setLoadingAdv(false);
            }
        }
    }

    let propsCount = 0;
    if (editableWatch?.serial) {
        propsCount++;
    }
    if (editableWatch?.state) {
        propsCount++;
    }
    if (editableWatch?.functions) {
        propsCount++;
    }
    if (editableWatch?.movement) {
        propsCount++;
    }
    if (editableWatch?.braceletMaterial) {
        propsCount++;
    }
    if (editableWatch?.caseMaterial) {
        propsCount++;
    }
    if (editableWatch?.diameter) {
        propsCount++;
    }
    if (editableWatch?.tightness) {
        propsCount++;
    }
    if (editableWatch?.glass) {
        propsCount++;
    }
    if (editableWatch?.content) {
        propsCount++;
    }
    if (editableWatch?.price) {
        propsCount++;
    }

    return (
        <AdminModal close={close}>
            <div className="flex w-fit m-auto items-center gap-2 mt-8 mb-4">
                <p className="border border-[#D1B57A] text-center w-fit m-auto p-2"><strong>ID:</strong> {editableWatch?.id}</p>
                <AdminButton borderColor="#000" borderWidth={1} onClick={getAdvertiserInfo}>Informações do anunciante</AdminButton>
            </div>
            <div className="border border-black w-full md:w-1/2 px-2 py-2 m-auto">
                <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Marca</p><p className="px-2 font-bold w-full text-center">{editableWatch?.brand}</p></div>
                <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Modelo</p><p className="px-2 font-bold w-full text-center">{editableWatch?.model}</p></div>
                <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Ano</p><p className="px-2 font-bold w-full text-center">{editableWatch?.year}</p></div>
            </div>
            <div className="flex justify-center items-center gap-2">
                <div className="w-fit border border-black mt-2 px-6 py-2">
                    <p className="font-bold text-lg">Detalhes: {propsCount}/11</p>
                    <div className="m-auto w-fit mt-2">
                        <AdminButton borderColor="#D1B57A" disabled={propsCount === 0} borderWidth={3} onClick={() => setPropsModal(true)}>Ver Detalhes</AdminButton>
                    </div>
                </div>
                <div className="w-fit border border-black mt-2 px-6 py-2">
                    <p className="font-bold text-lg">Nº de imagens: {editableWatch?.images?.length || 0}</p>
                    <div className="m-auto w-fit mt-2">
                        <AdminButton borderColor="#D1B57A" disabled={!editableWatch?.images?.length} borderWidth={3} onClick={() => setImagesModal(true)}>Ver Imagens</AdminButton>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 m-auto border border-black mt-2 px-6 py-2">
                <p className="font-bold text-lg text-center">Comentar / Solicitar alteração</p>
                <textarea className="w-full" value={comment} onChange={e => setComment(e.target.value)}></textarea>
                <div className="m-auto w-fit mt-2">
                    <AdminButton borderColor="#D1B57A" borderWidth={3} onClick={() => updateComment()}>Atualizar comentário</AdminButton>
                </div>
            </div>


            <div className="flex flex-col md:flex-row items-center gap-2 w-fit m-auto mt-8">
                <div className="flex text-lg border border-black mb-1">
                    <p className="text-center px-2 bg-[#444] text-white px-2">Status</p>
                    {
                        Object.keys(statusFields).map((k, i) => {
                            if (editableWatch?.status === k) {
                                return (
                                    <p key={i} className="px-2" style={{color: statusColors[k]}}>{statusFields[k]}</p>
                                )
                            }
                            return null;
                        })
                    }
                </div>

                {
                    editableWatch?.status === "sold" ?
                    null :
                    update ?
                        <div className="flex gap-2">
                            <select name="statuses" id="statuses" className="text-center" value={status} onChange={(e) => setStatus(e.target.value)}>
                                {
                                    Object.keys(statusFields).map((k, i) => (
                                        <option key={i} value={k}>{statusFields[k]}</option>
                                    ))
                                }
                            </select>
                            <button disabled={isUpdating} className="bg-[#ff3333] text-[#cccccc] p-2 active:bg-[#ff9999]" onClick={() => setUpdate(false)}>Cancelar</button>
                            <button disabled={isUpdating} className="bg-[#00bb00] text-[#cccccc] p-2 active:bg-[#88bb88]" onClick={doUpdate}>Atualizar</button>
                        </div>
                        :
                        <AdminButton onClick={() => setUpdate(true)}>
                            Atualizar status
                        </AdminButton>
                }

            </div>
            {
                imagesModal ?
                    <div className="fixed top-0 left-0 w-full h-full bg-[#000000dd]" onClick={() => setImagesModal(false)}>
                        <div className="w-[90%] md:w-[600px] h-fit m-auto mt-10 bg-[#000000dd] border border-[#D1B57A] flex flex-col items-center gap-4 pt-4 relative" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-2 right-2" onClick={() => setImagesModal(false)}><XIcon fill="#ccc" /></button>
                            <div className="overflow-auto w-full">
                                
                                {
                                    imageLoading ?
                                        <ClockAnimation fill="#dedede" />
                                        : null
                                }
                                <img src={editableWatch?.images[imageIndex].imgUrl} alt="watch" onLoad={handleImageLoad} hidden={imageLoading} className="m-auto max-w-full max-h-[350px] mt-10" />
                                <div className="flex justify-evenly w-full mt-4">
                                    <button disabled={imageLoading || imageIndex === 0} onClick={prevImage} className="border border-[#D1B57A] text-white text-lg text-lg p-2 hover:text-[#999] active:text-white disabled:text-[#333]">
                                        <LeftArrow fill={imageLoading || imageIndex === 0 ? "#333" : "#fff"} />
                                    </button>
                                    <button disabled={imageLoading || imageIndex >= editableWatch?.images?.length - 1} onClick={nextImage} className="border border-[#D1B57A] text-white text-lg text-lg p-2 hover:text-[#999] active:text-white disabled:text-[#333]">
                                        <RightArrow fill={imageLoading || imageIndex >= editableWatch?.images?.length - 1 ? "#333" : "#fff"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                propsModal && editableWatch ?
                    <div className="fixed top-0 left-0 w-full h-full bg-[#000000dd]" onClick={() => setPropsModal(false)}>
                        <div className="w-[90%] h-[90%] m-auto mt-4 bg-[#000000dd] border border-[#D1B57A] flex flex-col items-center gap-4 pt-4 relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-2 right-2" onClick={() => setPropsModal(false)}><XIcon fill="#ccc" /></button>
                            <div className="w-full overflow-auto">
                                {/* Serial */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Serial</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.serial}</p>
                                </div>
                                {/* Estado */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Estado</h3>
                                    <p className="flex-1 text-center text-white">{stateFields[editableWatch.state]}</p>
                                </div>
                                {/* Funções */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Funções</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.functions}</p>
                                </div>
                                {/* movimento - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Movimento</h3>
                                    <p className="flex-1 text-center text-white">{movementFields[editableWatch?.movement]}</p>
                                </div>
                                {/* material do bracelete - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Material do Bracelete</h3>
                                    <p className="flex-1 text-center text-white">{braceletMaterialFields[editableWatch?.braceletMaterial]}</p>
                                </div>
                                {/* material da caixa - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Material da Caixa</h3>
                                    <p className="flex-1 text-center text-white">{caseMaterialFields[editableWatch?.caseMaterial]}</p>
                                </div>
                                {/* diametro - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Diâmetro (mm)</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.diameter}</p>
                                </div>
                                {/* estanqueidade - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Estanqueidade (ATM)</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.tightness}</p>
                                </div>
                                {/* vidro - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Vidro</h3>
                                    <p className="flex-1 text-center text-white">{glassFields[editableWatch?.glass]}</p>
                                </div>
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Caixa e documentos originais</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.boxAndDocuments ? "Sim" : "Não"}</p>
                                </div>
                                {/* conteudo fornecido - text */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Conteúdo adicional</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.content}</p>
                                </div>
                                {/* preço - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Preço (R$)</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.price}</p>
                                </div>
                                {/* outras caracteristicas - text */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Descrição</h3>
                                    <p className="flex-1 text-center text-white">{editableWatch?.description}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    : null
            }
            {
                advModal ?
                    <div className="fixed top-0 left-0 w-full h-full bg-[#000000dd]" onClick={() => setAdvModal(false)}>
                        <div className="w-[90%] md:w-[500px] h-fit m-auto mt-10 bg-[#ccc] border border-[#D1B57A] flex flex-col items-center gap-4 py-4 relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-2 right-2" onClick={() => setAdvModal(false)}><XIcon fill="#000" /></button>
                            {
                                loadingAdv ?
                                    <ClockAnimation fill="#000" />
                                    :
                                    <div className="w-full">
                                        <div className="flex flex-col md:flex-row py-4">
                                            <p className="border border-[#D1B57A] text-center text-white bg-black w-fit m-auto p-2"><strong>ID Anunciante:</strong></p>
                                            <p className="border border-[#D1B57A] text-center text-white bg-black w-fit m-auto p-2"><strong>{advertiser?.id}</strong></p>
                                        </div>
                                        <div className="border border-black m-auto px-2 py-2">
                                            <div className="flex text-lg border border-black mb-1"><p className="w-1/4 text-center px-2 bg-[#444] text-white">Nome</p><p className="px-2 font-bold w-full text-center">{advertiser?.name}</p></div>
                                            <div className="flex text-lg border border-black mb-1"><p className="w-1/4 text-center px-2 bg-[#444] text-white">Email</p><p className="px-2 font-bold w-full text-center">{advertiser?.email}</p></div>
                                            <div className="flex text-lg border border-black mb-1"><p className="w-1/4 text-center px-2 bg-[#444] text-white">Telefone</p><p className="px-2 font-bold w-full text-center">{advertiser?.phoneNumber}</p></div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    : null
            }
            {
                isUpdating ?
                    <div className="absolute bottom-0 right-4">
                        <ClockAnimation />
                    </div>
                    : null
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} color="#00bb00" message="Relógio atualizado!" />
            <AdminToast toast={badToast} setToast={setBadToast} color="#ff3333" message="Erro na operação!" />
        </AdminModal>
    )
}
