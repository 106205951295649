export default function Switch({ on }: { on: boolean | undefined}) {
    return (
        <div className="w-fit h-6 bg-mono rounded-full flex items-center"
            style={{backgroundColor: on ? "#00BB00" : "#B3B3B3"}}
        >
            <div className="h-6 w-8 bg-white border border-mono rounded-full"
                style={{
                    backgroundColor: on ? "transparent" : "#FFFFFF",
                    border: on ? "none" : "1px solid #B3B3B3"
                }}
            >

            </div>
            <div className="h-6 w-8 rounded-full"
                style={{
                    backgroundColor: on ? "#FFFFFF" : "transparent",
                    border: on ? "1px solid #B3B3B3" : "none"
                }}
            >

            </div>
        </div>
    )
}