export default function Clock4({ width, fill = "#000" }: { width: string, fill?: string }) {
    return (
        <svg width={width} id="e4IvotBFX671" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
            <polygon fill={fill} points="0,-30 25.980762,15 -25.980762,15 0,-30" transform="matrix(-.003854-.389374 1.60278-.015864 126.131 151.268)" strokeWidth="0" />
            <ellipse rx="102.16" ry="102.16" transform="matrix(1.40374 0 0 1.40374 150 150)" fill="none" stroke={fill} strokeWidth="4" />
            <ellipse fill={fill} rx="3.90414" ry="3.90414" transform="matrix(3.03772 0 0 3.03772 150 150)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="translate(146.912 13.0837)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(.851343 0.52461-.52461 0.851343 221.785 32.9034)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(.00327 0.999995-.999995 0.00327 21.9509 146.87)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(-.509925 0.860219-.860219-.509925 38.1163 88.1628)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(-.002212 0.999998-.999998-.002212 287.522 146.922)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="translate(146.912 277.053)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(.474719 0.880137-.880137 0.474719 270.234 85.691)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(-.544062 0.839045-.839045-.544062 271.72 214.142)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(-.878468 0.477801-.477801-.878468 216.825 269.713)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(.872918 0.487867-.487867 0.872918 84.7224 258.447)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(.520814 0.85367-.85367 0.520814 37.2122 208.999)" strokeWidth="0" />
            <rect fill={fill} width="6.17687" height="9.45375" rx="0" ry="0" transform="matrix(-.800746 0.599004-.599004-.800746 85.7731 38.6329)" strokeWidth="0" />
            <polygon fill={fill} points="0,-30 25.980762,15 -25.980762,15 0,-30" transform="matrix(.253253 0.203364-1.559363 1.941913 174.465201 119.468777)" strokeWidth="0" />
        </svg>

    )
}