import { useState } from "react";
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";

export default function MobileWatchImages({ images }: { images: { imgUrl: any, cImgUrl: any }[] }) {

    const [currentImg, setCurrentImg] = useState(0);

    if (!images.length) {
        return (
            <div>
                Nenhuma imagem disponível
            </div>
        )
    }

    const handleNextImage = () => {
        if (currentImg < images.length - 1) {
            setCurrentImg(currentImg + 1);
        }
    }

    const handlePrevImage = () => {
        if (currentImg > 0) {
            setCurrentImg(currentImg - 1);
        }
    }

    return (
        <div className="relative m-auto border-b border-mono flex items-center bg-moninho">
            <button onClick={handlePrevImage} className="bg-white border border-mono rounded-full p-2 absolute bottom-2 left-2 transform rotate-90"><ChevronDownIcon fill={currentImg === 0 ? "#B3B3B3" : "#000000"} /></button>
            <img className="w-full h-auto" src={images[currentImg].imgUrl} alt="display" />
            <button onClick={handleNextImage} className="bg-white border border-mono rounded-full p-2 absolute bottom-2 right-2 transform -rotate-90"><ChevronDownIcon fill={currentImg === images.length - 1 ? "#B3B3B3" : "#000000"} /></button>
        </div>
    )
}
