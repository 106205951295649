import React from "react";
import { Link } from "react-router-dom";

interface IField {
    to: string;
    label: string;
    icon: React.ReactNode;
    selected?: boolean;
}

export default function SideField({ to, icon, label, selected=false }: IField) {
    return (
        <Link to={to} className={`flex items-center gap-2 px-2 py-2 justify-start text-[#333333] text-center font-semibold hover:bg-[#cccccc] ${selected ? "bg-[#e0e0e0]" : null}`}>{icon}{label}</Link>
    )
}
