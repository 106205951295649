import { useState } from "react"
import ClockAnimation from "../../../../img/svg/clockAnimation";
import RequestDetailsModal from "./requestDetailsModal";
import AdminToast from "../../../core/admin/adminToast";
import { parseTimestamp } from "../../../../utils/functions";

export interface IRequest {
    id: string;
    advertiser: string;
    review: string;
    reason: string;
    createdAt: Date;
    solved: boolean;
}

export default function Requests({ requests, loading, feedback, refresh }: { requests: IRequest[] | null, loading: boolean, feedback: string, refresh: () => Promise<null> }) {

    
    
    const [modal, setModal] = useState(false);
    const [editableRequest, setEditableRequest] = useState<IRequest | null>(null);
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    

    const handleEditModal = (request: IRequest) => {
        setEditableRequest(request);
        setModal(true);
    }

    return (
        <div className="pt-4">
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div>
                        <p className="text-center text-red-500">{feedback}</p>
                        {
                            requests?.filter(req => !req.solved).map((r, i) => (
                                <div key={i} className="border border-mono h-fit bg-moninho relative mb-4 min-h-20">
                                    <h4 className="text-center font-bold text-lg mt-8">Solicitada exclusão por motivo:</h4>
                                    <p className="text-center my-4">{r.reason}</p>
                                    <div className="absolute top-2 left-2 flex gap-4">
                                        <button onClick={() => handleEditModal(r)} className="text-white px-2 py-1 rounded bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]">Detalhes</button>
                                    </div>
                                    <div className="absolute top-2 right-2 flex gap-4">
                                        <p>{parseTimestamp(r.createdAt)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
            }
            {
                modal && editableRequest ?
                <RequestDetailsModal close={() => setModal(false)} badToast={() => setBadToast(true)} goodToast={() => setGoodToast(true)} refresh={refresh} request={editableRequest}/>
                : null
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} message="Solicitação atualizada!" color="#00bb00" />
            <AdminToast toast={badToast} setToast={setBadToast} message="Erro!" color="#ff0000" />
        </div>
    )
}
