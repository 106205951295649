import { useState } from "react"
import ImageViewer from "../../core/imageViewer";

export default function WatchImages({ images }: { images: {imgUrl: any, cImgUrl: any}[] }) {

    const [currentImg, setCurrentImg] = useState(0);

    if(!images?.length){
        return (
            <div>
                Nenhuma imagem disponível
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="bg-moninho p-2 flex flex-row md:flex-col gap-2">
                {
                    images.map((img, i) => (
                        <div key={i} className="w-16 h-16 border flex items-center overflow-hidden cursor-pointer" style={{border: i === currentImg ? "1px solid black" : undefined}} onClick={() => setCurrentImg(i)}>
                            <img src={img?.cImgUrl} alt="display"/>
                        </div>
                    ))
                }
            </div>
            <div className="bg-moninho">
                <ImageViewer imgSrc={images[currentImg]?.imgUrl}/>
            </div>
        </div>
    )
}
