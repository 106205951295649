import { useEffect, useState } from "react";
import PlusIcon from "../../../../img/svg/plusIcon";
import CreateAdModal from "./createAdModal";
import { collection, deleteDoc, doc, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import CompleteAdModal from "./completeAdModal";
import DeleteIcon from "../../../../img/svg/deleteIcon";
import { deleteObject, getStorage, listAll, ref } from "firebase/storage";
import { parseFirebaseTimestamp } from "../../../../utils/functions";

export interface IAd {
    id: string;
    createdAt: Timestamp;
    until: Timestamp;
    link: string;
    imgUrl: string;
    mImgUrl: string;
    name: string;
    visible: boolean;
    order: number;
    draft: boolean;
}

export default function AdminAds() {

    const [createModal, setCreateModal] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);
    const [ads, setAds] = useState<IAd[]>([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState<number | null>(null);
    const [editableAd, setEditableAd] = useState<IAd | null>(null);
    const [deletion, setDeletion] = useState<number | null>(null);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        doGetAds();
    }, [])

    const doGetAds = async () => {
        try {
            setLoading(true);
            setCreateModal(false);
            const docs: { id: string; until: Timestamp, link: string, imgUrl: string, mImgUrl: string, name: string, visible: boolean, order: number, draft: boolean, createdAt: Timestamp }[] = [];
            const docsSnap = await getDocs(collection(db, "ads"));
            if (docsSnap.empty) {
                setAds([]);
                setLoading(false);
                return null;
            }
            docsSnap.forEach(document => {
                const data = document.data();
                docs.push({
                    id: document.id,
                    createdAt: data.createdAt,
                    until: data.until,
                    link: data.link,
                    imgUrl: data.imgUrl,
                    mImgUrl: data.mImgUrl,
                    name: data.name,
                    visible: data.visible,
                    order: data.order,
                    draft: data.draft
                })
            })
            setAds(docs);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleCompleteModal = async (ad: IAd) => {
        setEditableAd(ad);
        setCompleteModal(true);
    }

    const handleDeletion = async (ad: IAd, idx: number) => {
        try {
            setDeleteLoading(idx);
            setDeletion(null);
            if (!ad.id) {
                setDeleteLoading(null);
                return null;
            }
            // excluir imagens do relógio
            const storage = getStorage();
            const folderRef = ref(storage, `ads/${ad?.id}`);
            const result = await listAll(folderRef);

            const deletePromises = result.items.map((itemRef) => {
                return deleteObject(itemRef);
            })

            await Promise.all(deletePromises);

            // excluir documento do relógio
            const docRef = doc(db, "ads", ad.id);
            await deleteDoc(docRef);

        } catch (error) {
            console.log(error);
        } finally {
            setDeleteLoading(null);
            doGetAds();
        }
    }

    if (loading) {
        return (
            <main className="p-4">
                <h1 className="text-xl text-center font-bold">Anúncios</h1>
                <div className="bg-white pt-2 pb-10 mt-2 relative">
                    <ClockAnimation />
                </div>
            </main>
        )
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">Anúncios</h1>
            <div className="bg-white py-10 mt-2 relative">
                <button onClick={() => setCreateModal(true)} className="w-fit m-auto absolute top-2 right-2 p-2 bg-[#00bb00] flex items-center gap-2 hover:bg-[#88bb88] active:bg-[#00bb00] font-bold text-white"><PlusIcon fill="#fff" width="20px" /> Criar</button>
                <div className="flex justify-center w-fit gap-10 m-auto">
                    <div onClick={() => setTab(0)} className="cursor-pointer flex items-center border-mono border rounded p-2 gap-4">
                        <p>Em exibição: {ads.filter(a => a.visible && a.until > Timestamp.now()).length}</p>
                    </div>
                    <div onClick={() => setTab(1)} className="cursor-pointer flex items-center border-mono border rounded p-2 gap-4">
                        <p>Outros: {ads.filter(a => !a.visible || a.until < Timestamp.now()).length}</p>
                    </div>
                </div>
            </div>

            <div className="bg-white pt-2 pb-10 mt-2 relative px-2">
                <div className="flex justify-center gap-4 border-b mb-4 border-mono">
                    <h2 onClick={() => setTab(0)} className="text-lg py-2 cursor-pointer text-center font-bold flex-1" style={{backgroundColor: tab === 0 ? "#eee" : ""}}>Em exibição</h2>
                    <h2 onClick={() => setTab(1)} className="text-lg py-2 cursor-pointer text-center font-bold flex-1" style={{backgroundColor: tab === 1 ? "#eee" : ""}}>Outros</h2>
                </div>
                {
                    ads?.filter(a => tab === 0 ? a.visible && a.until > Timestamp.now() : !a.visible || a.until < Timestamp.now()).map((ad, idx) => (
                        <div key={idx} className="flex justify-between border border-mono rounded p-4 mb-4">
                            <div className="flex-1">
                                <p className="text-center font-bold">{ad.name}</p>
                                <p className="text-center">Visível até <strong>{parseFirebaseTimestamp(ad.until)}</strong></p>
                            </div>
                            {
                                deletion === idx ?
                                    <div className="flex items-center gap-2">
                                        <button onClick={() => handleDeletion(ad, idx)} className="px-2 py-1 bg-[#ff3333] active:bg-[#ff9999] hover:bg-[#ff9999] text-white">
                                            Excluir
                                        </button>
                                        <button onClick={() => setDeletion(null)} className="text-white px-2 py-1 bg bg-[#ff3333] active:bg-[#ff9999] hover:bg-[#ff9999]">
                                            Cancelar
                                        </button>
                                    </div>
                                    : deleteLoading === idx ?
                                        <div>
                                            <ClockAnimation />
                                        </div>
                                        :
                                        <div className="flex items-center gap-2">
                                            <button onClick={() => handleCompleteModal(ad)} className="px-2 py-1 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7] text-white">
                                                Ver
                                            </button>
                                            <button onClick={() => setDeletion(idx)} className="px-2 py-1 bg bg-[#ff3333] active:bg-[#ff9999]">
                                                <DeleteIcon width="24px" fill="#fff" />
                                            </button>
                                        </div>
                            }
                        </div>
                    ))
                }
            </div>
            {
                createModal ?
                    <CreateAdModal close={() => setCreateModal(false)} finish={doGetAds} /> :
                    null
            }
            {
                completeModal && editableAd ?
                    <CompleteAdModal refresh={doGetAds} close={() => setCompleteModal(false)} setEditableAd={setEditableAd} editableAd={editableAd} />
                    : null
            }
        </main>
    )
}
