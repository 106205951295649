import { Link } from "react-router-dom"
import GMTIcon from "../../img/svg/gmtIcon"

export default function NotFound() {
    return (
        <main className="h-screen">
            <div className="flex flex-col items-center justify-center h-2/3 gap-4">
                
                <h1 className="text-3xl font-bold">404</h1>
                <p className="text-lg">Página não encontrada!</p>
                <Link to="/">
                    <GMTIcon width="200px"/>
                </Link>
            </div>
        </main>
    )
}