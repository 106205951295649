import { Navigate, Outlet, useLocation } from "react-router-dom";
import { IUser } from "../context/authProvider";

export default function RequireAuth({ user, roles=["customer"] }: { user: IUser | null, roles?: string[]}) {

    const location = useLocation();

    return (
        user && user.roles.some(role => roles.includes(role)) ?
        <Outlet/>
        : user && !user.roles.some(role => roles.includes(role)) ?
        <p>Não autorizado</p>
        :
        <Navigate to="/gatekeeper" state={{from: location}}/>
    )
}
