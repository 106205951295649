import { useEffect, useState } from "react"
import Reviews from "./reviews";
import Requests, { IRequest } from "./requests";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function AdminReviews() {

    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [requests, setRequests] = useState<IRequest[] | null>(null);

    useEffect(() => {
        getRequests();
        // eslint-disable-next-line
    }, []);

    const getRequests = async () => {
        try {
            setLoading(true);
            setFeedback("");
            let docs: IRequest[] = [];
            const q = collection(db, "reviewsrequests");
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setLoading(false);
                setFeedback("Nenhum resultado encontrado");
                setRequests(null);
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    advertiser: document.data().advertiser,
                    review: document.data().review,
                    reason: document.data().reason,
                    createdAt: document.data().createdAt?.toDate(),
                    solved: document.data().solved,
                })
            })

            setRequests(docs);
            setLoading(false);
            return null;
        } catch (error) {
            console.log(error);
            setFeedback("Erro na busca!");
            setLoading(false);
            return null;
        }
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">Avaliações</h1>
            <div className="bg-white px-4 py-10 mt-2">
                <div className="flex border-b border-mono relative">
                    <p className="flex-1 text-center border-r border-mono cursor-pointer"
                        onClick={() => setTab(0)}
                        style={{backgroundColor: tab === 0 ? "#DDDDDD" : "transparent"}}
                    >
                        Avaliações
                    </p>
                    <p className="flex-1 text-center cursor-pointer"
                        onClick={() => setTab(1)}
                        style={{backgroundColor: tab === 1 ? "#DDDDDD" : "transparent"}}
                    >
                        Solicitações
                    </p>
                    {
                        requests?.filter(req => !req.solved).length ?
                        <div className="rounded-full w-4 h-4 bg-red-500 absolute -top-1 -right-1"></div>
                        : null
                    }
                </div>
                {
                    tab === 0 ?
                    <Reviews requests={requests}/>
                    : tab === 1 ?
                    <Requests feedback={feedback} loading={loading} requests={requests} refresh={getRequests}/>
                    : null
                }
            </div>
        </main>
    )
}
