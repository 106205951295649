import { useState } from "react";
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";
import { IFAQ } from "../admin/faqs/adminFaqs";

export default function ExpandableFAQ({ faq, index }: { faq: IFAQ, index: number }) {

    const [expanded, setExpanded] = useState(false);

    return (
        <div onClick={() => setExpanded(x => !x)} className="border border-mono rounded px-4 py-2 bg-moninho w-[90%] md:w-[60%] m-auto mb-4 hover:bg-white cursor-pointer relative">
            <div className="flex">
                <p className="text-lg break-all font-[600]">{index+1}.</p>
                <p className="flex-1 text-center text-lg break-all px-8 font-[600]">{faq.question}</p>
            </div>
            {
                expanded ?
                    <p className="text-center text-md break-all px-8 py-4 mt-2 border-t border-mono">{faq.answer}</p>
                    : null
            }
            <div className="absolute bottom-2 right-2">
                {
                    expanded ?
                        <div className="transform rotate-180">
                            <ChevronDownIcon />
                        </div>
                        :
                        <ChevronDownIcon />
                }
            </div>
        </div>
    )
}
