import { useEffect, useState } from "react";
import { useWatches } from "../../../context/watchProvider";
import Bannerzin from "../../core/bannerzin";
import { useNavigate } from "react-router-dom";
import LoadingBalls from "../../core/loadingBalls";

export default function WatchesGrid({ criter }: { criter: "views" | "date" }) {

    const { getWatchesByIds, getLatest10Watches, top10 } = useWatches();
    const navigate = useNavigate();

    const [watches, setWatches] = useState<{ [key: string]: any; id: string; }[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (criter === "date") {
            doGetLatest10();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (criter === "views") {
            doGetTop10();
        }
        // eslint-disable-next-line
    }, [top10]);

    const doGetTop10 = async () => {
        setLoading(true);
        if (!top10) {
            setLoading(false);
            return null;
        }
        const ws = await getWatchesByIds(top10.map(w => w.watch));
        if (!ws) {
            setLoading(false);
            return null;
        }
        // ordenar pelo mais visto
        const fullWs = ws.map(w => {return {...w, count: top10.filter(v => v.watch === w.id)[0].count}});
        setWatches(fullWs.sort((a, b) => b.count - a.count));
        setLoading(false);
    }

    const doGetLatest10 = async () => {
        setLoading(true);
        const ws = await getLatest10Watches();
        if (!ws) {
            setLoading(false);
            return null;
        }
        setWatches(ws);
        setLoading(false);
    }

    if (loading) {
        return (
            <div className="flex items-center h-[200px] my-4 w-fit m-auto">
                <LoadingBalls />
            </div>
        )
    }

    return (
        <div className="px-4 my-4">
            <div className="w-full border border-mono rounded bg-[#eee] overflow-hidden">
                <div className="text-center md:text-left p-4 border-b border-mono">
                    <p className="text-lg text-center font-bold">{criter === "date" ? "NOVIDADES" : "RELÓGIOS MAIS ACESSADOS"}</p>
                </div>
                <div className="p-2 flex gap-2 overflow-x-scroll items-stretch">
                    {
                        watches.map((w, i) => (
                            <Bannerzin key={i} banner={w} onClick={() => navigate(`watches/${w.slug}`)} overlay={false} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
