export default function Bannerzin({ banner, onClick, overlay = true, sponsor = false }: { banner: { [key: string]: any }, onClick: () => void, overlay?: boolean, sponsor?: boolean }) {

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        onClick();
    }

    const image = overlay ? banner?.imgUrl : banner?.images?.sort((a: { order: number }, b: { order: number }) => a.order - b.order)[0]?.cImgUrl;

    return (
        <div className="w-[220px] flex-none select-none cursor-pointer bg-white p-1 rounded relative z-[3]" onClick={(e) => handleClick(e)}>
            <div className="h-[220px] w-full relative">
                <img src={image} alt="banner" className="object-cover h-full w-full" />
                <div className="absolute top-0 w-full h-full"></div>
                {
                    sponsor ?
                        <div className="absolute top-2 left-2 bg-[#ccc] border border-mono rounded px-2">Patrocinado</div>
                        : null
                }
            </div>
            <div className="py-2 text-primary">
                <p className="text-[10px] text-center">{banner?.brand.toUpperCase()} / {banner?.year}</p>
                <h3 className="text-base text-center font-bold -mt-1 text-primary">{banner?.model.toUpperCase()}</h3>
                {/* <p className="text-sm text-center -mt-1">R${banner.price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> */}
            </div>
            {
                overlay ?
                    <div className="absolute top-0 right-0 w-full h-full bg-[#00000077] pointer-events-none rounded"></div>
                    : null
            }
        </div>
    )
}
