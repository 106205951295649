import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/authProvider";
import MenuIcon from "../../../img/svg/menuIcon";
import GMTIcon from "../../../img/svg/gmtIcon";
import UserIcon from "../../../img/svg/userIcon";
import { useEffect, useState } from "react";
import XIcon from "../../../img/svg/xIcon";

export default function Header({ scrollY, isMobile }: { scrollY: number, isMobile: boolean }) {

    const { user } = useAuth();
    const location = useLocation();

    const [burguer, setBurguer] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const headerBase = location.pathname !== "/" && !location.pathname.includes("about");

    if (isMobile) {
        return (
            <header className="text-center h-20 text-white z-[100] relative"
                style={{
                    background: headerBase ? "" : "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0) 90%)",
                    backgroundColor: headerBase ? "#F3F3F3" : "",
                    color: headerBase ? "#1F2429" : "#fff",
                }}
            >
                <div className="flex justify-between px-10 h-full items-center">
                    <div className="flex items-center gap-4 cursor-pointer" onClick={() => setBurguer(true)}>
                        <MenuIcon fill={headerBase ? "#1F2429" : "white"} />
                    </div>

                    {
                        headerBase ?
                            <Link to="/"><GMTIcon fill="#1F2429" /></Link>
                            : null
                    }

                    {
                        user ?
                            <Link to="/me" className="flex items-center gap-2"><UserIcon width="20px" fill={scrollY > 50 || headerBase ? "#1F2429" : "#FFFFFF"} /></Link>
                            :
                            <Link to="/gatekeeper" className="flex items-center gap-2"><UserIcon width="20px" fill={scrollY > 50 || headerBase ? "#1F2429" : "#FFFFFF"} /></Link>
                    }
                </div>
                {
                    burguer ?
                        <div onClick={() => setBurguer(false)} className="fixed top-0 left-0 h-screen w-full bg-[#000000cc] px-2 py-4">
                            <div className="bg-[#f3f3f3] text-black relative">
                                <Link className="block p-4 border-b border-mono" to="/"
                                    style={{ backgroundColor: location.pathname === "/" ? "#d7d7d7" : "transparent" }}>
                                    INÍCIO
                                </Link>
                                <Link className="block p-4 border-b border-mono" to="/watches"
                                    style={{ backgroundColor: location.pathname === "/watches" ? "#d7d7d7" : "transparent" }}>
                                    RELÓGIOS
                                </Link>
                                <Link className="block p-4 border-b border-mono" to="/about"
                                    style={{ backgroundColor: location.pathname === "/about" ? "#d7d7d7" : "transparent" }}>
                                    SOBRE
                                </Link>
                                <Link className="block p-4 border-b border-mono" to="/faq"
                                    style={{ backgroundColor: location.pathname === "/faq" ? "#d7d7d7" : "transparent" }}>
                                    FAQ
                                </Link>
                                {
                                    user ?
                                        <div className="flex-1">
                                            <Link to="/me" className="block p-4"
                                                style={{ backgroundColor: location.pathname === "/me" ? "#d7d7d7" : "transparent" }}>MINHA ÁREA</Link>
                                        </div>

                                        :
                                        <div className="flex-1">
                                            <Link to="/gatekeeper" className="block p-4"
                                                style={{ backgroundColor: location.pathname === "/gatekeeper" ? "#d7d7d7" : "transparent" }}>ENTRAR</Link>
                                        </div>
                                }
                                <div onClick={() => setBurguer(false)} className="absolute top-2 left-2 cursor-pointer"><XIcon /></div>
                            </div>
                        </div>
                        : null
                }
            </header>
        )
    }

    return (
        <header className="w-full text-center h-20 z-10 relative" style={{
            background: headerBase ? "" : "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0) 90%)",
            backgroundColor: headerBase ? "#F3F3F3" : "",
            color: headerBase ? "#1F2429" : "#fff",
        }}>
            <div className="flex justify-center px-10 h-full items-center">
                <div className="flex items-center gap-12 flex-1">
                    <Link to="/watches">RELÓGIOS</Link>
                    <Link to="/about">SOBRE</Link>
                    <Link to="/faq">FAQ</Link>
                </div>
                {
                    headerBase ?
                        <div className="flex-1">
                            <Link to="/" className="flex justify-center w-fit m-auto"><GMTIcon fill="#1F2429" /></Link>
                        </div>
                        : null
                }
                {
                    user ?
                        <div className="flex-1">
                            <Link to="/me" className="w-fit m-auto mr-0 flex items-center justify-end gap-4"><p>MINHA ÁREA</p><UserIcon width="20px" fill={scrollY > 50 || headerBase ? "#1F2429" : "#FFFFFF"} /></Link>
                        </div>

                        :
                        <div className="flex-1">
                            <Link to="/gatekeeper" className="w-fit m-auto mr-0 flex items-center justify-end gap-4"><p>ENTRAR</p><UserIcon width="20px" fill={scrollY > 50 || headerBase ? "#1F2429" : "#FFFFFF"} /></Link>
                        </div>
                }
            </div>
        </header>
    )
}
