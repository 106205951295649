import { Dispatch, SetStateAction, useState } from "react";
import { useAuth } from "../../../context/authProvider";
import GoogleIcon from "../../../img/svg/googleIcon";
import EyeSlashIcon from "../../../img/svg/eyeSlashIcon";
import EyeIcon from "../../../img/svg/eyeIcon";
import LoadingBalls from "../../core/loadingBalls";

export default function Login({ setGate }: { setGate: Dispatch<SetStateAction<number>> }) {

    const { logIn, googleLogIn } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleLogin = async () => {
        if(!email || !password){
            return null
        }
        setLoading(true);
        setError(false);
        const doLogin = await logIn(email, password);
        if(!doLogin){
            setLoading(false);
            setError(true);
            return null;
        }
        setLoading(false);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter"){
            handleLogin();
        }
    }

    return (
        <div className="flex flex-col w-[300px] m-auto gap-4">
            <button className="border border-[#939393] hover:bg-[#eee] rounded flex items-center text-primary px-4 py-2" onClick={googleLogIn}><GoogleIcon/><p className="text-center flex-1">Entrar com Google</p></button>
            <div className="h-1 w-[200px] m-auto border-t border-primary"></div>
            <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="EMAIL" className="border border-[#B3B3B3] text-center p-2"/>
            <div className="relative">
                <div onClick={() => setShowPass(!showPass)} className="absolute top-[6px] right-2 cursor-pointer">
                    {
                        showPass ?
                        <EyeSlashIcon fill="#b3b3b3"/>
                        : <EyeIcon fill="#b3b3b3"/>
                    }
                </div>
                <input onKeyDown={handleKeyDown} type={showPass ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} placeholder="SENHA" className="w-full border border-[#B3B3B3] text-center p-2" />
            </div>
            <p className="text-center text-secondary mt-2 hover:text-primary cursor-pointer" onClick={() => setGate(2)}>Esqueci minha senha</p>
            <button onClick={handleLogin} className="border w-full h-10 m-auto bg-secondary text-white p-1 active:bg-[#aaaaaa] mt-2">{loading ? <div className="m-auto w-fit"><LoadingBalls width={80} color="#fff"/></div> : <p className="m-0">Entrar</p>}</button>
            {
                error ?
                <p className="text-center text-red-500">Ops, algo deu errado!</p>
                : null
            }
        </div>
    )
}
