import { IWatch } from "../watches/adminWatches";

export default function HiddenWatches({ watches }: { watches: IWatch[] }) {

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-10 mt-4">
            <h2 className="text-center font-bold text-lg mb-4">Escondidos</h2>
            {
                watches.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">Marca</th>
                                <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                <th className="border border-[#aaaaaa] p-2">Ano</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                watches.map((w, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p className="text-center">Nenhum relógio listado</p>
            }
        </div>
    )
}
