// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, browserLocalPersistence, setPersistence, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { projectConfig } from "./project";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: projectConfig["apiKey"] || "AIzaSyBVOKM14WKzgYO3WF34FPjuI5kDe6obBC0",
  authDomain: projectConfig["authDomain"] || "gmt-alpha.firebaseapp.com",
  projectId: projectConfig["projectId"] || "gmt-alpha",
  storageBucket: projectConfig["storageBucket"] || "gmt-alpha.appspot.com",
  messagingSenderId: projectConfig["messagingSenderId"] || "698516767887",
  appId: projectConfig["appId"] || "1:698516767887:web:b8a1c53854ea0634491cfa"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Export firestore database
const db = getFirestore(app);
// Firebase storage
const storage = getStorage(app);

setPersistence(auth, browserLocalPersistence);

export { auth, db, storage, googleProvider };
export default app;
