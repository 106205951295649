import { addDoc, collection, doc, getDoc, updateDoc, getDocs, query, where, deleteDoc, documentId, serverTimestamp, orderBy, limit, WhereFilterOp, startAfter, getCountFromServer, QueryDocumentSnapshot, DocumentData } from "firebase/firestore";
import { createContext, useContext, useState } from "react";
import { db } from "../firebase";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { itemsPerPage } from "../utils/fields";

interface WatchContextType {
    createWatch: (advertiser: string, brand: string, model: string, year: number, slug: string) => Promise<string | null>;
    getWatch: (watchId: string) => Promise<{ id: string, [key: string]: any } | null>;
    updateWatch: (watchId: string, infos: {}) => Promise<boolean | null>;
    deleteWatch: (userId: string | null, watchId: string) => Promise<boolean | null>;
    getWatches: (parameter: string, value: string) => Promise<{ id: string, [key: string]: any }[] | null | []>;
    getWatchesByIds: (ids: string[]) => Promise<{ id: string, [key: string]: any }[] | null>;
    getCartWatches: (cart: string[]) => Promise<{ id: string, [key: string]: any }[] | null | []>;
    getAdvertiser: (advertiserId: string) => Promise<{ id: string, [key: string]: any } | null>;
    getAdvertiserBySlug: (slug: string) => Promise<{ id: string, [key: string]: any } | null>;
    getMyWatches: (advertiserId: string) => Promise<{ id: string, [key: string]: any }[] | null | []>;
    searchWatches: (filters: { field: string, operator: WhereFilterOp, value: string | string[] | number }[], price?: { from: number, to: number }, year?: { from: number, to: number }, last?: QueryDocumentSnapshot<DocumentData, DocumentData>) => Promise<{ total: number, data: { id: string, [key: string]: any }[], last: QueryDocumentSnapshot<DocumentData, DocumentData> } | null>;
    getWatchBySlug: (slug: string) => Promise<{} | null>;
    addToCart: (userId: string, watchId: string) => Promise<boolean | null>;
    removeFromCart: (userId: string, watchId: string) => Promise<boolean | null>;
    getCartItems: (userId: string) => Promise<boolean | null>;
    cart: string[] | null;
    cartUpdated: boolean;
    getPurchasedWatches: (userId: string) => Promise<{ id: string, [key: string]: any }[] | null>;
    getAdvertiserReviews: (advertiser: string) => Promise<{ id: string, rate: number, review: string, createdAt: Date, hidden: boolean }[] | null>;
    getAdvertiserWatches: (advertiserId: string) => Promise<{ id: string, [key: string]: any }[] | null | []>;
    getWatchViews: (watchId: string) => Promise<{ id: string, watch: string, count: number } | null>;
    countWatchView: (viewId: string, count: number) => Promise<true | null>;
    getTop10Watches: () => Promise<null>;
    getLatest10Watches: () => Promise<{ id: string, [key: string]: any }[] | null>;
    top10: { id: string, watch: string, count: number }[] | null;
}

const WatchContext = createContext<WatchContextType | undefined>(undefined);

export const WatchProvider = ({ children }: { children: React.ReactNode }) => {

    const [cart, setCart] = useState<string[] | null>(null);
    const [cartUpdated, setCartUpdated] = useState(false);
    const [top10, setTop10] = useState<{ id: string, watch: string, count: number }[] | null>(null);

    async function addToCart(userId: string, watchId: string) {
        try {
            const q = query(collection(db, "carts"), where("userId", "==", userId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                await addDoc(collection(db, "carts"), {
                    userId, items: [watchId], createdAt: serverTimestamp()
                })
                return true;
            }
            const docSnap = docsSnap.docs[0];
            if (docSnap.data().items.includes(watchId)) {
                return null;
            }
            const docRef = doc(db, "carts", docSnap.id);
            await updateDoc(docRef, { items: [...docSnap.data().items, watchId] });
            setCartUpdated(false);
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function removeFromCart(userId: string, watchId: string) {
        try {
            const q = query(collection(db, "carts"), where("userId", "==", userId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            const docSnap = docsSnap.docs[0];
            if (!docSnap.data().items.includes(watchId)) {
                return null;
            }
            const docRef = doc(db, "carts", docSnap.id);
            await updateDoc(docRef, { items: [...docSnap.data().items.filter((item: string) => item !== watchId)] });
            setCartUpdated(false);
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getCartItems(userId: string) {
        try {
            const q = query(collection(db, "carts"), where("userId", "==", userId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setCart([]);
                setCartUpdated(true);
                return true;
            }
            const docSnap = docsSnap.docs[0];
            setCart(docSnap.data().items);
            setCartUpdated(true);
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function createWatch(advertiser: string, brand: string, model: string, year: number, slug: string) {
        try {
            if (!brand || !model || !year || !slug) {
                return null;
            }
            const docRef = await addDoc(collection(db, "watches"), {
                advertiser,
                brand: brand.toLowerCase().trim(),
                model: model.toLowerCase().trim(),
                year,
                slug,
                status: "draft",
                createdAt: serverTimestamp(),
                images: []
            })

            return docRef.id
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getWatch(watchId: string) {
        try {
            const docRef = doc(db, "watches", watchId);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                return null;
            }
            return { ...docSnap.data(), id: docSnap.id };
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function updateWatch(watchId: string, infos: { [key: string]: any }) {
        try {
            const docRef = doc(db, "watches", watchId);
            const loweredInfos: { [key: string]: any } = {};
            for (let key in infos) {
                if (typeof infos[key] === "string" && ["brand", "model"].includes(key)) {
                    loweredInfos[key] = infos[key].toLowerCase().trim();
                } else {
                    loweredInfos[key] = infos[key];
                }
            }
            await updateDoc(docRef, loweredInfos);
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function deleteWatch(userId: string | null, watchId: string) {
        try {
            if (!userId) {
                return null;
            }

            // excluir imagens do relógio
            const storage = getStorage();
            const folderRef = ref(storage, `watches/${userId}/${watchId}`);
            const result = await listAll(folderRef);

            const deletePromises = result.items.map((itemRef) => {
                return deleteObject(itemRef);
            })

            await Promise.all(deletePromises);

            // excluir documento do relógio
            const docRef = doc(db, "watches", watchId);
            await deleteDoc(docRef);

            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getWatches(parameter: string, value: string) {
        try {
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where(parameter, "==", value));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    ...document.data()
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getWatchesByIds(ids: string[]) {
        try {
            if(!ids.length){
                return null;
            }
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("__name__", "in", ids), where("status", "==", "display"));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    ...document.data()
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getCartWatches(cart: string[]) {
        try {
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("status", "==", "display"), where(documentId(), "in", cart));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    slug: document.data().slug,
                    cImgUrl: document.data().images?.length ? document.data().images[0].cImgUrl : undefined,
                    brand: document.data().brand,
                    model: document.data().model,
                    year: document.data().year,
                    price: document.data().price,
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getAdvertiserWatches(advertiserId: string) {
        try {
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("status", "==", "display"), where("advertiser", "==", advertiserId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    slug: document.data().slug,
                    cImgUrl: document.data().images?.length ? document.data().images[0].cImgUrl : undefined,
                    brand: document.data().brand,
                    model: document.data().model,
                    year: document.data().year,
                    price: document.data().price,
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getPurchasedWatches(userId: string) {
        try {
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("status", "==", "sold"), where("buyer", "==", userId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    slug: document.data().slug,
                    cImgUrl: document.data().images?.length ? document.data().images[0].cImgUrl : undefined,
                    brand: document.data().brand,
                    model: document.data().model,
                    year: document.data().year,
                    price: document.data().price,
                    advertiser: document.data().advertiser
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function searchWatches(filters: { field: string, operator: WhereFilterOp, value: string | string[] | number }[], price?: { from: number, to: number }, year?: { from: number, to: number }, last?: QueryDocumentSnapshot<DocumentData, DocumentData>) {
        try {
            let docs: { id: string;[key: string]: any }[] = [];
            let q = query(collection(db, "watches"), where("status", "==", "display"));
            if (!price && !year) {
                q = query(q, limit(itemsPerPage));
            }
            let totalQuery = query(collection(db, "watches"), where("status", "==", "display"));
            if (last) {
                q = query(q, startAfter(last));
            }
            for (let i = 0; i < filters.length; i++) {
                const currentFilter = filters[i];
                q = query(q, where(currentFilter.field, currentFilter.operator, currentFilter.value));
                totalQuery = query(totalQuery, where(currentFilter.field, currentFilter.operator, currentFilter.value));
            }
            const total = await getCountFromServer(totalQuery);
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    brand: document.data().brand,
                    model: document.data().model,
                    year: document.data().year,
                    price: document.data().price,
                    slug: document.data().slug,
                    cImgUrl: document.data().images?.length ? document.data().images[0].cImgUrl : undefined,
                })
            })
            if (price?.from) {
                docs = docs.filter(d => d.price >= price.from);
            }
            if (price?.to) {
                docs = docs.filter(d => d.price <= price.to);
            }
            if (year?.from) {
                docs = docs.filter(d => d.year >= year.from);
            }
            if (year?.to) {
                docs = docs.filter(d => d.year <= year.to);
            }
            return { total: total.data().count, data: docs, last: docsSnap.docs[docsSnap.docs.length - 1] };
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getAdvertiser(advertiserId: string) {
        try {
            if (!advertiserId) {
                return null;
            }
            const userRef = doc(db, "users", advertiserId);
            const userSnap = await getDoc(userRef);
            if (!userSnap.exists()) {
                return null;
            }
            return { ...userSnap.data(), id: userSnap.id };
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getAdvertiserBySlug(slug: string) {
        try {
            let docs: { id: string, [key: string]: any } = { id: "none" };
            const q = query(collection(db, "users"), where("slug", "==", slug));
            const docSnap = await getDocs(q);
            if (docSnap.empty) {
                return null;
            }

            docSnap.forEach(document => {
                docs = {
                    id: document.id,
                    createdAt: document.data().createdAt?.toDate(),
                    slug: document.data().slug,
                    name: document.data().name,
                    email: document.data().email,
                    phoneNumber: document.data().phoneNumber,
                    description: document.data().description,
                    instagram: document.data().instagram,
                    facebook: document.data().facebook,
                    site: document.data().site
                }
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getAdvertiserReviews(advertiser: string) {
        try {
            let docs: { id: string, rate: number, review: string, createdAt: Date, hidden: boolean }[] = [];
            const q = query(collection(db, "reviews"), where("advertiser", "==", advertiser));
            const docSnap = await getDocs(q);
            if (docSnap.empty) {
                return null;
            }

            docSnap.forEach(document => {
                docs.push(
                    {
                        id: document.id,
                        rate: document.data().rate,
                        review: document.data().review,
                        createdAt: document.data().createdAt?.toDate(),
                        hidden: document.data().hidden
                    }
                )
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getMyWatches(advertiserId: string) {
        try {
            const docs: { id: string;[key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("advertiser", "==", advertiserId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    ...document.data()
                })
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getWatchBySlug(slug: string) {
        try {
            let docs = {}
            const q = query(collection(db, "watches"), where("slug", "==", slug));
            const docSnap = await getDocs(q);
            if (docSnap.empty) {
                return null;
            }

            docSnap.forEach(document => {
                docs = {
                    id: document.id,
                    status: document.data().status,
                    brand: document.data().brand,
                    model: document.data().model,
                    year: document.data().year,
                    price: document.data().price,
                    advertiser: document.data().advertiser,
                    description: document.data().description,
                    braceletMaterial: document.data().braceletMaterial,
                    caseMaterial: document.data().caseMaterial,
                    content: document.data().content,
                    diameter: document.data().diameter,
                    functions: document.data().functions,
                    glass: document.data().glass,
                    movement: document.data().movement,
                    slug: document.data().slug,
                    state: document.data().state,
                    tightness: document.data().tightness,
                    boxAndDocuments: document.data().boxAndDocuments,
                    images: document.data().images
                }
            })
            return docs;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getWatchViews(watchId: string) {
        try {
            const q = query(collection(db, "watchesviews"), where("watch", "==", watchId));
            const docSnap = await getDocs(q);
            if (docSnap.empty) {
                const docRef = await addDoc(collection(db, "watchesviews"), {
                    watch: watchId,
                    count: 0
                })

                return {
                    id: docRef.id,
                    watch: watchId,
                    count: 0
                }
            }
            const docao = docSnap.docs[0];
            return {
                id: docao.id,
                watch: docao?.data()?.watch,
                count: docao?.data()?.count
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function countWatchView(viewId: string, count: number) {
        try {
            const docRef = doc(db, "watchesviews", viewId);
            await updateDoc(docRef, { count });
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getTop10Watches() {
        const bimbaCollectionRef = collection(db, "watchesviews");
        const q = query(bimbaCollectionRef, orderBy("count", "desc"), limit(10));

        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                setTop10([]);
                return null;
            }
            const top10Docs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                watch: doc.data().watch,
                count: doc.data().count
            }));
            const watches = await getWatchesByIds(top10Docs.map(d => d.watch));
            const watchesIds = watches?.map(w => w.id);
            const displayedWatches = top10Docs?.filter(t => watchesIds?.includes(t.watch));
            if (!displayedWatches) {
                return null;
            }
            setTop10(displayedWatches.sort((a: { count: number }, b: { count: number }) => a.count - b.count));
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function getLatest10Watches() {
        const bimbaCollectionRef = collection(db, "watches");
        const q = query(bimbaCollectionRef, where("status", "==", "display"), orderBy("createdAt", "desc"), limit(10));

        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return null;
            }
            const latest10Docs = querySnapshot.docs.map(document => ({
                id: document.id,
                status: document.data().status,
                brand: document.data().brand,
                model: document.data().model,
                year: document.data().year,
                price: document.data().price,
                slug: document.data().slug,
                images: document.data().images,
                createdAt: document.data().createdAt
            }));
            return latest10Docs;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return (
        <WatchContext.Provider value={
            {
                createWatch, getWatch,
                updateWatch, deleteWatch,
                getWatches, getWatchesByIds, getMyWatches,
                getCartWatches, getAdvertiserBySlug,
                getAdvertiser, searchWatches,
                getWatchBySlug, addToCart,
                getCartItems, removeFromCart,
                cart, cartUpdated, getPurchasedWatches,
                getAdvertiserReviews, getAdvertiserWatches,
                getWatchViews, countWatchView, getTop10Watches,
                getLatest10Watches, top10
            }
        }>
            {children}
        </WatchContext.Provider>
    )
}

export const useWatches = (): WatchContextType => {
    const context = useContext(WatchContext);
    if (context === undefined) {
        throw new Error('useWatches must be used within a WatchProvider');
    }
    return context;
};

export default WatchContext;
