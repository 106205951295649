import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { useAuth } from "../../../../context/authProvider";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { createDate } from "../../../../utils/functions";

export default function CreateAdModal({ close, finish }: { close: () => void, finish: () => void }) {

    const { userId } = useAuth();
    const [name, setName] = useState("");
    const [link, setLink] = useState("");
    const [until, setUntil] = useState("");
    const [loading, setLoading] = useState(false);
    const [errored, setErrored] = useState(false);

    const createAdDraft = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setLoading(true);
            if (!userId) {
                throw new Error("Autenticação necessária");
            }

            const docRef = await addDoc(collection(db, "ads"), {
                name,
                link,
                until: createDate(until),
                draft: true,
                createdAt: serverTimestamp(),
                visible: false,
                order: 0
            })

            if (!docRef.id) {
                setLoading(false);
                setErrored(true);
                return null;
            }
            setErrored(false);
            setLoading(false);
            finish();
        } catch (error) {
            setLoading(false);
            setErrored(true);
            console.log(error);
        }
    }

    const handleUntil = (value: string) => {
        const barless = value.replace("/", "").replace("/", "");
        if ((!/^\d+$/.test(barless) && barless !== "") || barless.length > 8) {
            return null;
        }
        if(barless.length > 4){
            setUntil(`${barless.substring(0, 2)}/${barless.substring(2, 4)}/${barless.substring(4)}`)
            return null;
        }
        if(barless.length > 2){
            setUntil(`${barless.substring(0, 2)}/${barless.substring(2)}`)
            return null;
        }
        setUntil(barless);
    }

    return (
        <AdminModal close={close}>
            <h1 className="text-center text-lg font-bold mb-4">Criar rascunho de anúncio - Dados iniciais</h1>

            <form onSubmit={e => createAdDraft(e)} className="flex flex-col">
                <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                    <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Nome</h3>
                    <input className="flex-1 text-center px-2" type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Nome" />
                </div>
                <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                    <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Link</h3>
                    <input className="flex-1 text-center px-2" type="text" value={link} onChange={e => setLink(e.target.value)} placeholder="Link" />
                </div>
                <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                    <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Data final</h3>
                    <input className="flex-1 text-center px-2" type="text" value={until} onChange={e => handleUntil(e.target.value)} placeholder="Data de expiração" />
                </div>
                <p className="font-bold text-center">A data deve estar no formato dd/mm/aaaa</p>
                <button className="bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] text-white w-fit m-auto py-1 px-2 mt-4">Criar</button>
            </form>
            {
                loading ?
                    <ClockAnimation />
                    : null
            }
            {
                errored ?
                    <p className="text-red-500 text-center">Ocorreu um erro na operação</p>
                    : null
            }

            {/* <AdImagesUploader/> */}
        </AdminModal>
    )
}
