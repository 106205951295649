import { useState } from "react";
import { braceletMaterialFields, caseMaterialFields, glassFields, movementFields, stateFields } from "../../../utils/fields";
import ExpandableFilter from "./expandlableFilter";
import LoadingBalls from "../../core/loadingBalls";
import { useWatches } from "../../../context/watchProvider";
import { DocumentData, QueryDocumentSnapshot, WhereFilterOp } from "firebase/firestore";

export default function Filters({ setWatches, loading, setLoading, setLoadingMore, setQuery, setLast, setTotal }: {
    setWatches: React.Dispatch<React.SetStateAction<{ id: string, [key: string]: any }[] | null>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setLoadingMore: React.Dispatch<React.SetStateAction<boolean>>,
    setQuery: React.Dispatch<React.SetStateAction<{ filters: any, price: any, year: any }>>,
    setLast: React.Dispatch<React.SetStateAction<QueryDocumentSnapshot<DocumentData, DocumentData> | undefined>>,
    setTotal: React.Dispatch<React.SetStateAction<number>>,
}) {

    const { searchWatches } = useWatches();

    const [states, setStates] = useState<string[]>([]);
    const [brand, setBrand] = useState("");
    const [yearFrom, setYearFrom] = useState("");
    const [yearTo, setYearTo] = useState("");
    const [priceFrom, setPriceFrom] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [movement, setMovement] = useState("");
    const [caseMaterial, setCaseMaterial] = useState("");
    const [braceletMaterial, setBraceletMaterial] = useState("");
    const [glass, setGlass] = useState("");

    const handleStates = (value: string) => {
        if (states.includes(value)) {
            setStates(s => s.filter(s => s !== value));
            return null;
        }
        setStates(s => [...s, value]);
    }

    const handleYearFrom = (value: string) => {
        if (value === "") {
            setYearFrom("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setYearFrom(value);
    }

    const handleYearTo = (value: string) => {
        if (value === "") {
            setYearTo("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setYearTo(value);
    }

    const handlePriceFrom = (value: string) => {
        if (value === "") {
            setPriceFrom("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setPriceFrom(value);
    }

    const handlePriceTo = (value: string) => {
        if (value === "") {
            setPriceTo("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setPriceTo(value);
    }

    const doSearchWatches = async () => {
        setLoading(true);
        const filters: { field: string, operator: WhereFilterOp, value: string | string[] | number }[] = [];
        if (states.length) {
            filters.push({ field: "state", operator: "in", value: states });
        }
        if (brand) {
            filters.push({ field: "brand", operator: "==", value: brand.toLowerCase() });
        }
        if (movement) {
            filters.push({ field: "movement", operator: "==", value: movement });
        }
        if (caseMaterial) {
            filters.push({ field: "caseMaterial", operator: "==", value: caseMaterial });
        }
        if (braceletMaterial) {
            filters.push({ field: "braceletMaterial", operator: "==", value: braceletMaterial });
        }
        if (glass) {
            filters.push({ field: "glass", operator: "==", value: glass });
        }


        const foundWatches = await searchWatches(filters, priceFrom || priceTo ? { from: parseInt(priceFrom), to: parseInt(priceTo) } : undefined, yearFrom || yearTo ? { from: parseInt(yearFrom), to: parseInt(yearTo) } : undefined);
        if (foundWatches?.data) {
            setWatches(foundWatches?.data);
            setTotal(foundWatches?.total);
            setLast(foundWatches?.last);
            setQuery({ filters, price: priceFrom || priceTo ? { from: parseInt(priceFrom), to: parseInt(priceTo) } : undefined, year: yearFrom || yearTo ? { from: parseInt(yearFrom), to: parseInt(yearTo) } : undefined })
        }
        setLoading(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const redefineFilters = () => {
        setStates([]);
        setBrand("");
        setYearFrom("");
        setYearTo("");
        setPriceFrom("");
        setPriceTo("");
        setMovement("");
        setCaseMaterial("");
        setBraceletMaterial("");
        setGlass("");
    }

    return (
        <div className="w-[200px] rounded bg-[#D3D3D3] p-2 flex flex-col gap-2 h-fit">
            <ExpandableFilter name="Marca">
                <input type="text" value={brand} onChange={(e) => setBrand(e.target.value)} className="border border-mono py-1 w-full text-center" placeholder="Marca" />
            </ExpandableFilter>

            <ExpandableFilter name="Estado">
                {
                    Object.keys(stateFields).map((sf, i) => (
                        <div key={i} className="flex px-4 gap-4">
                            <input type="checkbox" id={sf} value={sf} checked={states.includes(sf)} onChange={e => handleStates(e.target.value)} />
                            <label htmlFor={sf} className="flex-1">{stateFields[sf]}</label>
                        </div>
                    ))
                }
            </ExpandableFilter>

            <ExpandableFilter name="Ano">
                {/* <input type="text" value={year} onChange={(e) => handleYear(e.target.value)} className="border border-mono py-1 w-full text-center" placeholder="Ano" /> */}
                <div className="flex items-center gap-2">
                    <p className="w-8 whitespace-nowrap">De</p>
                    <input type="text" value={yearFrom} onChange={(e) => handleYearFrom(e.target.value)} className="flex-1 border border-mono py-1 w-full text-center" placeholder="Ano" />
                </div>
                <div className="flex items-center gap-2 mt-1">
                    <p className="w-8 whitespace-nowrap">Até</p>
                    <input type="text" value={yearTo} onChange={(e) => handleYearTo(e.target.value)} className="flex-1 border border-mono py-1 w-full text-center" placeholder="Ano" />
                </div>
            </ExpandableFilter>

            <ExpandableFilter name="Preço">
                <div className="flex items-center gap-2">
                    <p className="w-12 whitespace-nowrap">De R$</p>
                    <input type="text" value={priceFrom} onChange={(e) => handlePriceFrom(e.target.value)} className="flex-1 border border-mono py-1 w-full text-center" placeholder="Preço" />
                </div>
                <div className="flex items-center gap-2 mt-1">
                    <p className="w-12 whitespace-nowrap">Até R$</p>
                    <input type="text" value={priceTo} onChange={(e) => handlePriceTo(e.target.value)} className="flex-1 border border-mono py-1 w-full text-center" placeholder="Preço" />
                </div>
            </ExpandableFilter>

            <ExpandableFilter name="Movimento">
                <select className="w-full border border-mono text-center" value={movement} onChange={e => setMovement(e.target.value)}>
                    <option value="">Todos</option>
                    {
                        Object.keys(movementFields).map((f, i) => (
                            <option key={i} value={f}>{movementFields[f]}</option>
                        ))
                    }
                </select>
            </ExpandableFilter>

            <ExpandableFilter name="Caixa">
                <select className="w-full border border-mono text-center" value={caseMaterial} onChange={e => setCaseMaterial(e.target.value)}>
                    <option value="">Todos</option>
                    {
                        Object.keys(caseMaterialFields).map((f, i) => (
                            <option key={i} value={f}>{caseMaterialFields[f]}</option>
                        ))
                    }
                </select>
            </ExpandableFilter>

            <ExpandableFilter name="Bracelete">
                <select className="w-full border border-mono text-center" value={braceletMaterial} onChange={e => setBraceletMaterial(e.target.value)}>
                    <option value="">Todos</option>
                    {
                        Object.keys(braceletMaterialFields).map((f, i) => (
                            <option key={i} value={f}>{braceletMaterialFields[f]}</option>
                        ))
                    }
                </select>
            </ExpandableFilter>

            <ExpandableFilter name="Vidro">
                <select className="w-full border border-mono text-center" value={glass} onChange={e => setGlass(e.target.value)}>
                    <option value="">Todos</option>
                    {
                        Object.keys(glassFields).map((f, i) => (
                            <option key={i} value={f}>{glassFields[f]}</option>
                        ))
                    }
                </select>
            </ExpandableFilter>
            <button disabled={loading} onClick={doSearchWatches} className="h-8 pointer-events-auto text-md bg-primary text-white px-4 py-1 active:opacity-70">{loading ? <div className="w-fit m-auto"><LoadingBalls color="#fff" width={80} /></div> : "Aplicar"}</button>
            <button disabled={loading} onClick={redefineFilters} className="h-8 pointer-events-auto text-md bg-primary text-white px-4 py-1 active:opacity-70">Redefinir</button>
        </div>
    )
}