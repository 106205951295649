import { Dispatch, SetStateAction, useState } from "react";
import { useWatches } from "../../../../../context/watchProvider";
import ClockAnimation from "../../../../../img/svg/clockAnimation";
import { IWatch } from "../../watches/adminWatches";

export default function ImagesReorderer({ watch, setWatch, update }: { watch: IWatch | null, setWatch: Dispatch<SetStateAction<IWatch | null>>, update: () => void }) {

    const { updateWatch } = useWatches();
    const [loading, setLoading] = useState(false);

    const handleImgOrder = (idx: number, value: string) => {
        if(!watch?.id){
            return null;
        }
        let images = watch?.images;
        if(!images.length){
            return null;
        }
        if(value === ""){
            images[idx] = {...images[idx], order: 0};
        setWatch({...watch, id: watch?.id, images});
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if(parseInt(value) > 99){
            return null;
        }
        images[idx] = {...images[idx], order: parseInt(value)};
        setWatch({...watch, id: watch?.id, images});
    }

    const saveOrder = async () => {
        if(!watch?.id){
            return null;
        }
        setLoading(true);
        await updateWatch(watch?.id, {images: watch?.images.sort((a: {order: number}, b: {order: number}) => a.order - b.order)});
        update();
        setLoading(false);
    }

    return (
        <div className="border border-mono rounded w-full p-4 flex flex-wrap gap-4 items-center">
            {
                loading ?
                    <ClockAnimation />
                    : watch?.images?.length ?
                        watch.images.map((img: {imgUrl: string, cImgUrl: string, order: number}, idx: number) => (
                            <div key={idx} className="w-32 h-32 border border-mono rounded flex items-center p-1 relative">
                                <div>
                                    <img src={img?.cImgUrl} alt="relojo" />
                                </div>
                                    <input className="absolute text-center border border-mono -top-2 -right-2 w-10 p-2" type="text" value={img?.order} onChange={e => handleImgOrder(idx, e.target.value)}/>
                            </div>
                        ))
                        : null
            }
            <div>
            <button onClick={saveOrder} className="bg-[#00bb00] text-white px-4 py-1 hover:bg-[#88bb88] active:bg-[#88bb88]">Salvar ordem</button>
            </div>
        </div>
    )
}
