import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { useWatches } from "../../../../context/watchProvider";
import { IWatch } from "../watches/adminWatches";

export default function ApprovalWatches({ watches, refresh }: { watches: IWatch[], refresh: () => void }) {

    const { updateWatch } = useWatches();

    const [confirmModal, setConfirmModal] = useState(false);
    const [watch, setWatch] = useState<IWatch | null>(null);

    const doMoveWatch = async (watch: IWatch | null) => {
        if (!watch?.id) {
            return null;
        }
        const response = await updateWatch(watch.id, { status: "draft" });
        if (response) {
            refresh();
        }
    }

    const openConfirmModal = (w: IWatch) => {
        setWatch(w);
        setConfirmModal(true);
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-10 mt-4">
            <h2 className="text-center font-bold text-lg mb-4">Aguardando aprovação</h2>
            {
                watches.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">Marca</th>
                                <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                <th className="border border-[#aaaaaa] p-2">Ano</th>
                                <th className="border border-[#aaaaaa] p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                watches.map((w, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                        <td className="border border-[#aaaaaa] p-2"><button className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]" onClick={() => openConfirmModal(w)}>Editar</button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p className="text-center">Nenhum relógio listado</p>
            }
            {
                confirmModal ?
                    <AdminModal close={() => setConfirmModal(false)}>
                        <h2 className="text-lg text-center">Para editar este relógio, ele precisa ser movido para a área de rascunhos.<br /><strong>Mover relógio para os rascunhos?</strong></h2>
                        <div className="flex gap-4 justify-center mt-10">
                            <button className="flex-1 text-white px-2 bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#00bb00] h-fit py-2" onClick={() => doMoveWatch(watch)}>Sim</button>
                            <button className="flex-1 text-white px-2 bg-[#ff3333] hover:bg-[#ff9999] active:bg-[#ff3333] h-fit py-2" onClick={() => setConfirmModal(false)}>Cancelar</button>
                        </div>
                    </AdminModal>
                    : null
            }
        </div>
    )
}
