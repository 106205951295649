import { useState } from "react";
import AdminButton from "../../../core/admin/adminButton";
import AdminModal from "../../../core/admin/adminModal";
import { IWatch } from "../watches/adminWatches";
import { braceletMaterialFields, caseMaterialFields, glassFields, movementFields, stateFields } from "../../../../utils/fields";
import XIcon from "../../../../img/svg/xIcon";
import AdminToast from "../../../core/admin/adminToast";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import CheckIcon from "../../../../img/svg/checkIcon";

export default function CandidateWatchModal({ watch, close, handleBanner, banners, refresh }: { watch: IWatch, close: () => void, handleBanner: (watchId: IWatch) => Promise<boolean | null>, banners: string[], refresh: () => Promise<void> }) {

    const [propsModal, setPropsModal] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);
    const [loading, setLoading] = useState(false);

    const doHandleBanner = async () => {
        try {
            setLoading(true);
            const bannerPromise = await handleBanner(watch);
            if (bannerPromise) {
                setGoodToast(true);
                setLoading(false);
                await refresh();
                close();
                return null;
            }
            setBadToast(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <AdminModal close={close}>
            <h1 className="text-lg font-bold text-center mt-4">Relógio</h1>
            <div className="flex w-fit m-auto items-center gap-2 mt-2 mb-4">
                <p className="border border-[#D1B57A] text-center w-fit m-auto p-2"><strong>ID:</strong> {watch?.id}</p>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center mt-2 gap-2">
                <div className="border border-black w-full md:w-1/2 px-2 py-2">
                    <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Marca</p><p className="px-2 font-bold w-full text-center">{watch?.brand}</p></div>
                    <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Modelo</p><p className="px-2 font-bold w-full text-center">{watch?.model}</p></div>
                    <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Ano</p><p className="px-2 font-bold w-full text-center">{watch?.year}</p></div>
                </div>
                <div className="border border-black px-6 py-2">
                    <div className="m-auto w-fit">
                        <AdminButton borderColor="#D1B57A" borderWidth={3} onClick={() => setPropsModal(true)}>Ver Detalhes</AdminButton>
                    </div>
                </div>
            </div>
            <div className="flex-1 border border-black w-[300px] m-auto mt-2">
                <h2 className="text-center text-md border-b border-black">Imagem de exibição</h2>
                <img src={watch?.images?.length ? watch?.images[0].cImgUrl : undefined} alt="display" className="w-full " />
            </div>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div className="flex items-center gap-4 w-fit m-auto mt-4">
                        <div className="flex gap-2 items-center">
                            <div className="w-fit h-fit border border-black">
                                {
                                    banners.includes(watch.id) ?
                                        <CheckIcon fill="#008800" />
                                        :
                                        <XIcon fill="#dd0000" />
                                }
                            </div>
                            <p className="text-center text-md">Banner</p>
                        </div>
                        <AdminButton onClick={doHandleBanner}>{watch.isBanner ? "Remover banner" : "Tornar banner"}</AdminButton>
                    </div>
            }
            {
                propsModal && watch ?
                    <div className="fixed top-0 left-0 w-full h-full bg-[#000000dd]" onClick={() => setPropsModal(false)}>
                        <div className="w-[90%] h-[90%] m-auto mt-4 bg-[#000000dd] border border-[#D1B57A] flex flex-col items-center gap-4 pt-4 relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-2 right-2" onClick={() => setPropsModal(false)}><XIcon fill="#ccc" /></button>
                            <div className="w-full overflow-auto">
                                {/* Serial */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Serial</h3>
                                    <p className="flex-1 text-center text-white">{watch?.serial}</p>
                                </div>
                                {/* Estado */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Estado</h3>
                                    <p className="flex-1 text-center text-white">{stateFields[watch.state]}</p>
                                </div>
                                {/* Funções */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Funções</h3>
                                    <p className="flex-1 text-center text-white">{watch?.functions}</p>
                                </div>
                                {/* movimento - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Movimento</h3>
                                    <p className="flex-1 text-center text-white">{movementFields[watch?.movement]}</p>
                                </div>
                                {/* material do bracelete - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Material do Bracelete</h3>
                                    <p className="flex-1 text-center text-white">{braceletMaterialFields[watch?.braceletMaterial]}</p>
                                </div>
                                {/* material da caixa - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Material da Caixa</h3>
                                    <p className="flex-1 text-center text-white">{caseMaterialFields[watch?.caseMaterial]}</p>
                                </div>
                                {/* diametro - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Diâmetro (mm)</h3>
                                    <p className="flex-1 text-center text-white">{watch?.diameter}</p>
                                </div>
                                {/* estanqueidade - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Estanqueidade (ATM)</h3>
                                    <p className="flex-1 text-center text-white">{watch?.tightness}</p>
                                </div>
                                {/* vidro - select */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Vidro</h3>
                                    <p className="flex-1 text-center text-white">{glassFields[watch?.glass]}</p>
                                </div>
                                {/* conteudo fornecido - text */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Conteúdo fornecido</h3>
                                    <p className="flex-1 text-center text-white">{watch?.content}</p>
                                </div>
                                {/* preço - number */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Preço (R$)</h3>
                                    <p className="flex-1 text-center text-white">{watch?.price}</p>
                                </div>
                                {/* outras caracteristicas - text */}
                                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Descrição</h3>
                                    <p className="flex-1 text-center text-white">{watch?.description}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    : null
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} message="Banners atualizados!" color="#00bb00" />
            <AdminToast toast={badToast} setToast={setBadToast} message="Erro na operação!" color="#ff3333" />
        </AdminModal>
    )
}