import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"
import App from "./App";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./context/authProvider";
import { WatchProvider } from "./context/watchProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <WatchProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </WatchProvider>
    </AuthProvider>
  </React.StrictMode>
);
