import { useNavigate, useParams } from "react-router-dom"
import { useWatches } from "../../../context/watchProvider";
import { useEffect, useState } from "react";
import WatchImages from "./watchImages";
import MobileWatchImages from "./mobileWatchImages";
import PlusIcon from "../../../img/svg/plusIcon";
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";
import { braceletMaterialFields, caseMaterialFields, glassFields, movementFields, stateFields } from "../../../utils/fields";
import AdvertiserModal from "./advertiserModal";
import { useAuth } from "../../../context/authProvider";
import LoadingBalls from "../../core/loadingBalls";
import { calculateRate, formatPhoneNumber } from "../../../utils/functions";
import TemplateStars from "../../core/templateStars";
import Review from "../../core/review";

export default function WatchPage({ isMobile }: { isMobile: boolean }) {

    const { slug } = useParams();
    const { getWatchBySlug, addToCart, getCartItems, removeFromCart, cart, cartUpdated, getAdvertiser, getAdvertiserReviews, getWatchViews, countWatchView } = useWatches();
    const { userId } = useAuth();
    const navigate = useNavigate();

    const [watch, setWatch] = useState<{ [key: string]: any }>({});
    const [details, setDetails] = useState(false);
    const [advertiserModal, setAdvertiserModal] = useState(false);
    const [advertiser, setAdvertiser] = useState<{ [key: string]: any } | null>(null);
    const [reviews, setReviews] = useState<{ rate: number, review: string, createdAt: Date }[] | null>(null);
    const [reviewIdx, setReviewIdx] = useState(0);

    const [loading, setLoading] = useState(true);
    const [cartLoading, setCartLoading] = useState(false);
    const [advertiserLoading, setAdvertiserLoading] = useState(false);

    useEffect(() => {
        doGetWatch();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        doGetAdvertiserAndItsReviews();
        doCountView();
        // eslint-disable-next-line
    }, [watch])

    useEffect(() => {
        if (!cartUpdated && userId) {
            getCartItems(userId);
        }
        // eslint-disable-next-line
    }, [cartUpdated])

    const doGetWatch = async () => {
        setLoading(true);
        if (!slug) {
            setLoading(false);
            return null;
        }
        const w = await getWatchBySlug(slug);
        if (!w) {
            setLoading(false);
            return null;
        }
        setWatch(w);
        setLoading(false);
    }

    const handleCart = async () => {
        if (!userId) {
            navigate("/gatekeeper");
            return null;
        }
        setCartLoading(true);
        await addToCart(userId, watch.id);
        setCartLoading(false);
    }

    const doRemoveFromCart = async () => {
        if (!userId) {
            return null;
        }
        setCartLoading(true);
        await removeFromCart(userId, watch.id);
        setCartLoading(false);
    }

    const doGetAdvertiserAndItsReviews = async () => {
        if(!watch){
            return null;
        }
        const adv = await getAdvertiser(watch.advertiser);
        if (!adv) {
            return null;
        }
        setAdvertiser(adv);
        const revs = await getAdvertiserReviews(adv.id);
        if (!revs) {
            return null;
        }
        setReviews(revs.filter(r => !r.hidden));
    }

    const handleAdvertiserModal = async () => {
        if (advertiser) {
            setAdvertiserModal(true);
            return null;
        }
        setAdvertiserLoading(true);
        setAdvertiserModal(true);
        const adv = await getAdvertiser(watch.advertiser);
        if (!adv) {
            setAdvertiserLoading(false);
            setAdvertiserModal(false);
            return null;
        }
        setAdvertiser(adv);
        setAdvertiserLoading(false);
    }

    const handleReviewPassage = (type: "prev" | "next") => {
        if (!reviews) {
            return null;
        }
        if (type === "next") {
            if (reviewIdx >= reviews.length - 1) {
                return null;
            }
            setReviewIdx(reviewIdx + 1);
        } else {
            if (reviewIdx <= 0) {
                return null;
            }
            setReviewIdx(reviewIdx - 1);
        }
    }

    const doCountView = async () => {
        if(!watch?.id){
            return null;
        }
        const views = await getWatchViews(watch.id);
        if(!views){
            return null;
        }
        const count = views.count + 1;
        await countWatchView(views.id, count);
    }

    if (loading) {
        return (
            <main className="h-screen">
                <div className="w-fit m-auto mt-10">
                    <LoadingBalls />
                </div>
            </main>
        )
    }

    return (
        <main className="px-2 md:px-10 py-10">
            <div className="flex flex-col md:flex-row justify-between flex-wrap gap-10">
                <div className="">
                    {
                        isMobile ?
                            null
                            :
                            <WatchImages images={watch?.images} />
                    }
                </div>
                <div className="flex-1 flex flex-col justify-between gap-4">
                    <div className="border-b border-mono text-center md:text-left pb-4">
                        <p className="text-sm text-center">{watch?.brand?.toUpperCase()} / {watch?.year}</p>
                        <h1 className="text-2xl font-bold text-center">{watch?.model?.toUpperCase()}</h1>
                        <p className="text-center">R${watch?.price?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    {
                        isMobile ?
                            <MobileWatchImages images={watch?.images} />
                            :
                            null
                    }
                    {
                        watch?.description ?
                        <p className="text-center md:text-left flex-1 border-b pb-4 border-mono">{watch?.description}</p>
                        :
                        <p className="text-center flex-1 border-b pb-4 text-mono border-mono">Nenhuma descrição</p>
                    }

                    {
                        watch.status === "display" ?
                            <div className="flex flex-col gap-4 w-full md:w-[400px] m-auto">
                                {
                                    cart?.includes(watch.id) ?
                                        <div className="flex gap-4 justify-center items-center">
                                            <p>Este item já está na sua lista</p>
                                            {
                                                cartLoading ?
                                                    <LoadingBalls />
                                                    :
                                                    <button onClick={doRemoveFromCart} className="text-secondary">Remover</button>
                                            }
                                        </div>
                                        :
                                        <button onClick={handleCart} className="w-full hover:bg-moninho active:bg-moninho h-10 py-2 border border-black">{cartLoading ? <div className="m-auto w-fit"><LoadingBalls /></div> : "ADICIONAR À MINHA LISTA"}</button>
                                }
                                <button onClick={handleAdvertiserModal} className="w-full hover:opacity-80 active:opacity-80 py-2 text-white bg-secondary">FALAR COM O ANUNCIANTE</button>
                            </div>
                            :
                            <p className="text-red-500 text-center">Item indisponível</p>
                    }
                </div>
            </div>
            <div className="">
                {
                    details ?
                        <div className="border-t border-mono py-1 mt-10">
                            <div onClick={() => setDetails(false)} className="w-fit hover:bg-moninho cursor-pointer m-auto transform rotate-180 px-4 mb-1"><ChevronDownIcon fill="#B3B3B3" width="30px" /></div>
                            <div className="flex flex-col md:flex-row justify-center gap-4">
                                <div className="flex-1">
                                    <h2 className="text-center mb-2 text-lg bg-primary text-white">Sobre o relógio</h2>
                                    <div className="border rounded grid grid-cols-2 w-full md:w-[500px] m-auto">
                                        <p className="py-2 px-2 border-b font-bold">Estado</p>
                                        <p className="py-2 px-2 border-b">{stateFields[watch?.state]}</p>
                                        
                                        <p className="py-2 px-2 border-b bg-moninho font-bold">Preço</p>
                                        <p className="py-2 px-2 border-b bg-moninho">R${watch?.price?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>

                                        <p className="py-2 px-2 border-b font-bold">Funções</p>
                                        <p className="py-2 px-2 border-b">{watch?.functions}</p>

                                        <p className="py-2 px-2 border-b bg-moninho font-bold">Caixa e documentos originais</p>
                                        <p className="py-2 px-2 border-b bg-moninho">{watch?.boxAndDocuments ? "Sim" : "Não"}</p>

                                        <p className="py-2 px-2 border-b font-bold">Movimento</p>
                                        <p className="py-2 px-2 border-b">{movementFields[watch?.movement]}</p>

                                        <p className="py-2 px-2 border-b bg-moninho font-bold">Material da caixa</p>
                                        <p className="py-2 px-2 border-b bg-moninho">{caseMaterialFields[watch?.caseMaterial]}</p>

                                        <p className="py-2 px-2 border-b font-bold">Diâmetro (mm)</p>
                                        <p className="py-2 px-2 border-b">{watch?.diameter}</p>

                                        <p className="py-2 px-2 border-b bg-moninho font-bold">Estanqueidade (atm)</p>
                                        <p className="py-2 px-2 border-b bg-moninho">{watch?.tightness}</p>

                                        <p className="py-2 px-2 border-b font-bold">Vidro</p>
                                        <p className="py-2 px-2 border-b">{glassFields[watch?.glass]}</p>

                                        <p className="py-2 px-2 bg-moninho font-bold">Material do bracelete</p>
                                        <p className="py-2 px-2 bg-moninho">{braceletMaterialFields[watch?.braceletMaterial]}</p>

                                        <p className="py-2 px-2 border-b font-bold">Conteúdo adicional</p>
                                        <p className="py-2 px-2 border-b">{watch?.content}</p>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    {
                                        advertiser ?
                                            <div className="w-full">
                                                <h2 className="text-center mb-2 text-lg bg-primary text-white">Sobre o anunciante</h2>
                                                <div className="border rounded grid grid-cols-[150px_1fr] w-full md:w-[500px] m-auto">
                                                    <p className="py-2 px-2 border-b font-bold">Nome</p>
                                                    <p className="py-2 px-2 border-b">{advertiser.name}</p>

                                                    <p className="py-2 px-2 bg-moninho border-b font-bold">Email</p>
                                                    <p className="py-2 px-2 bg-moninho border-b break-words">{advertiser.email}</p>

                                                    <p className="py-2 px-2 border-b font-bold">Telefone</p>
                                                    <p className="py-2 px-2 border-b">{formatPhoneNumber(advertiser.phoneNumber)}</p>

                                                    {
                                                        reviews ?
                                                            <p className="py-2 px-2 border-b font-bold">Nota geral</p>
                                                            : null
                                                    }
                                                    {
                                                        reviews ?
                                                            <div className="py-2 px-2 border-b"><TemplateStars width="30px" rate={calculateRate(reviews)} /></div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        reviews ?
                                            <div className="w-full mt-4">
                                                <h2 className="text-lg text-center bg-primary text-white">Avaliações</h2>
                                                <div className="flex justify-between items-center border border-moninho rounded mt-2">
                                                    <button onClick={() => handleReviewPassage("prev")} disabled={reviewIdx <= 0} className="transform rotate-90"><ChevronDownIcon fill={reviewIdx <= 0 ? "#b3b3b3" : "#000"} /></button>
                                                    <Review review={reviews[reviewIdx]} />
                                                    <button onClick={() => handleReviewPassage("next")} disabled={reviewIdx >= reviews.length - 1} className="transform -rotate-90"><ChevronDownIcon fill={reviewIdx >= reviews.length - 1 ? "#b3b3b3" : "#000"} /></button>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div onClick={() => setDetails(true)} className="border-t border-b hover:bg-moninho cursor-pointer border-mono w-fit m-auto flex items-center gap-10 px-4 py-1 mt-10">
                            <p className="font-[600] text-mono">DETALHES</p>
                            <PlusIcon fill="#B3B3B3" width="20px" />
                        </div>
                }

            </div>
            {
                advertiserModal ?
                    <AdvertiserModal adv={advertiser} loading={advertiserLoading} close={() => setAdvertiserModal(false)} />
                    : null
            }
        </main>
    )
}
