import { Link } from "react-router-dom";
import FacebookIcon from "../../../img/svg/facebookIcon";
import InstagramIcon from "../../../img/svg/instagramIcon";
import YoutubeIcon from "../../../img/svg/youtubeIcon";
import MailIcon from "../../../img/svg/mailIcon";
import gioia from "../../../img/gioia.png";
// import PhoneIcon from "../../../img/svg/phoneIcon";
// import WhatsappIcon from "../../../img/svg/whatsappIcon";

export default function Footer() {
    return (
        <footer className="bg-primary flex flex-col md:flex-row text-[#A2ABB3] p-4 py-10 justify-evenly relative">
            <div className="flex-1 flex items-center gap-2 justify-center mt-2 flex-row md:flex-col text-[#767D82] text-center text-lg pb-4 md:pb-0">
                <Link to="/watches" className="w-full hover:text-[#A2ABB3] hover:border-[#A2ABB3] border px-2 rounded border-[#767d82]">Relógios</Link>
                <Link to="/about" className="w-full hover:text-[#A2ABB3] hover:border-[#A2ABB3] border px-2 rounded border-[#767d82]">Sobre</Link>
                <Link to="/faq" className="w-full hover:text-[#A2ABB3] hover:border-[#A2ABB3] border px-2 rounded border-[#767d82]">FAQ</Link>
            </div>
            <div className="flex-1 text-center flex flex-col py-10 md:py-0">
                <h4>NOS ACOMPANHE NAS REDES</h4>
                <div className="flex flex-1 items-center justify-evenly mt-4 md:mt-2">
                    <Link to="https://facebook.com/gmtmenos3" target="_blank">
                        <FacebookIcon fill="#A2ABB3" />
                    </Link>
                    <Link to="https://youtube.com.br/gmtmenos3" target="_blank">
                        <YoutubeIcon fill="#A2ABB3" />
                    </Link>
                    <Link to="https://instagram.com/gmtmenos3" target="_blank">
                        <InstagramIcon fill="#A2ABB3" />
                    </Link>
                </div>
            </div>
            <div className="flex-1 h-full text-center pt-4 md:pt-0">
                <h4>CONTATO</h4>
                <div className="flex flex-1 items-center mt-2 flex-col text-[#767D82]">
                    {/* <Link target="_blank" to="tel:(41) 99192-3734" className="flex hover:text-[#A2ABB3] items-center gap-2">
                        <PhoneIcon fill="#A2ABB3" /><p>(41) 99192-3734</p>
                    </Link> */}
                    {/* <Link target="_blank" to="https://api.whatsapp.com/send?phone=5541991923734" className="flex hover:text-[#A2ABB3] items-center gap-2">
                        <WhatsappIcon fill="#A2ABB3" /><p>(41) 99192-3734</p>
                    </Link> */}
                    <Link target="_blank" to="mailto:contato@gmtmenos3.com.br" className="flex hover:text-[#A2ABB3] items-center gap-2">
                        <MailIcon fill="#A2ABB3" /><p>contato@gmtmenos3.com.br</p>
                    </Link>
                </div>
            </div>
            <div className="absolute bottom-0 right-0 w-20">
                        <a href="https://gioiaperformance.com/" target="_blank" rel="noreferrer"><img src={gioia} alt="gioia" /></a>
            </div>
        </footer>
    )
}
