import { useEffect, useState } from "react"
import { useWatches } from "../../../../context/watchProvider";

export default function DisplayedBanner({ banner, reorder, refresh }: { banner: { [key: string]: any }, reorder: boolean, refresh: () => Promise<null | undefined> }) {

    const { updateWatch } = useWatches();

    const [order, setOrder] = useState(banner.order || 0);

    useEffect(() => {
        setOrder(banner.order);
        // eslint-disable-next-line 
    }, [refresh])

    const handleOrder = (value: string) => {
        if (!banner?.id) {
            return null;
        }
        if (value === "") {
            setOrder(0);
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if (parseInt(value) > 99) {
            return null;
        }
        setOrder(value);
    }

    const updateOrder = async () => {
        if (!banner?.id) {
            return null;
        }
        const updateTask = await updateWatch(banner.id, { order });
        if (!updateTask) {
            return null;
        }
        refresh();
    }

    return (
        <div className="flex flex-col md:flex-row border-[2px] border-black mt-4 bg-[#ccc] relative">
            <div className="w-full md:w-[300px] border border-black m-auto">
                <img src={banner.imgUrl} alt="banner" className="max-h-[200px] m-auto p-1" />
            </div>
            <div className="flex flex-col flex-1 border border-black pt-2 md:pt-0">
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">ID</h3>
                    <p className="flex-1 text-center">{banner.id}</p>
                </div>
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Serial</h3>
                    <p className="flex-1 text-center">{banner.serial}</p>
                </div>
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Marca</h3>
                    <p className="flex-1 text-center">{banner.brand}</p>
                </div>
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Modelo</h3>
                    <p className="flex-1 text-center">{banner.model}</p>
                </div>
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Ano</h3>
                    <p className="flex-1 text-center">{banner.year}</p>
                </div>
                <div className="flex w-[90%] m-auto border border-[#333] mb-2">
                    <h3 className="w-1/3 md:2-1/4 text-center px-2 bg-[#444] text-white">Preço</h3>
                    <p className="flex-1 text-center">R${banner.price}</p>
                </div>
            </div>
            {
                reorder ?
                    <div className="absolute -top-2 -left-2 flex gap-2">
                        <input className=" bg-white border border-mono p-1 w-8 text-center" type="text" value={order} onChange={e => handleOrder(e.target.value)} />
                        <button onClick={updateOrder} disabled={order === banner.order} className="px-2 disabled:bg-mono bg-[#00bb00] active:bg-[#88bb88] hover:bg-[#88bb88] text-white">Salvar ordem</button>
                    </div>
                    : null
            }
        </div>
    )
}