import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { IFAQ } from "./adminFaqs";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import AdminToast from "../../../core/admin/adminToast";
import ClockAnimation from "../../../../img/svg/clockAnimation";

export default function EditFAQModal({ close, faq, finish }: { close: () => void, faq: IFAQ, finish: (faq: IFAQ) => void }) {

    const [question, setQuestion] = useState(faq.question);
    const [answer, setAnswer] = useState(faq.answer);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const updateFaq = async () => {
        try {
            if (!question || !answer) {
                return null;
            }
            setLoading(true);
            const docRef = doc(db, "faqs", faq.id);
            await updateDoc(docRef, {
                question,
                answer
            });
            finish({ ...faq, question, answer });
        } catch (error) {
            console.log(error);
            setBadToast(true);
        } finally {
            setLoading(false);
        }
    }

    const handleQuestion = (e: string) => {
        if (e.length < 152) {
            setQuestion(e);
        }
    }

    const handleAnswer = (e: string) => {
        if (e.length < 500) {
            setAnswer(e);
        }
    }

    return (
        <AdminModal close={close}>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div>
                        <h2 className="text-center text-lg font-bold mt-4">FAQ</h2>
                        <input disabled={!edit} className="block w-full p-2 border border-mono outline-none m-auto mb-4" type="text" value={question} onChange={e => handleQuestion(e.target.value)} />
                        <textarea disabled={!edit} className="block w-full p-2 border border-mono outline-none m-auto mb-4" value={answer} onChange={e => handleAnswer(e.target.value)} />
                        {
                            edit ?
                                <div className="flex items-center justify-center gap-4">
                                    <button onClick={updateFaq} disabled={answer === faq.answer && question === faq.question} className="px-2 py-1 flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] disabled:bg-mono">Salvar</button>
                                    <button onClick={() => setEdit(false)} className="px-2 py-1 flex-1 text-white bg-[#000] hover:bg-[#666] active:bg-[#666]">Cancelar</button>
                                </div>
                                :
                                <button onClick={() => setEdit(true)} className="block m-auto px-2 py-1 flex-1 text-white bg-[#000] hover:bg-[#666] active:bg-[#666]">Editar</button>
                        }
                    </div>
            }
            <AdminToast toast={badToast} setToast={setBadToast} color="#bb0000" message="Erro!" />
        </AdminModal>
    )
}
