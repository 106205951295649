import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/authProvider";
import { useWatches } from "../../../../context/watchProvider"
import ClockAnimation from "../../../../img/svg/clockAnimation";
import TemplateStars from "../../../core/templateStars";
import RequestModal from "./requestModal";
import { parseTimestamp } from "../../../../utils/functions";
import AdminToast from "../../../core/admin/adminToast";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function MyReviews() {

    const { getAdvertiserReviews } = useWatches();
    const { userId } = useAuth();

    const [reviews, setReviews] = useState<{ id: string, rate: number; review: string; createdAt: Date; }[] | null>(null);
    const [requests, setRequests] = useState<{review: string, id: string}[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [editableReview, setEditableReview] = useState<{ id: string, rate: number; review: string; createdAt: Date; } | null>(null);
    const [editableRequest, setEditableRequest] = useState<{id: string, review: string} | undefined>(undefined);
    const [requestModal, setRequestModal] = useState(false);

    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    useEffect(() => {
        doGetRequests();
        doGetReviews();
        // eslint-disable-next-line
    }, [])

    const doGetReviews = async () => {
        if (!userId) {
            return null;
        }
        setLoading(true);
        const revs = await getAdvertiserReviews(userId);
        if(!revs){
            return null;
        }
        setReviews(revs.filter(r => !r.hidden));
        setLoading(false);
    }

    const doGetRequests = async () => {
        try{
            setLoading(true);
            let reqs: {review: string, id: string}[] = [];
            const q = query(collection(db, "reviewsrequests"), where("advertiser", "==", userId), where("solved", "==", false));
            const docSnap = await getDocs(q);
            if (docSnap.empty) {
                setLoading(false);
                return null;
            }

            docSnap.forEach(document => {
                reqs.push({
                    review: document.data().review,
                    id: document.id
                });
            })
            setRequests(reqs);
            setLoading(false);
        }catch(error){
            console.log(error);
            setLoading(false);
            return null;
        }
    }

    const handleRequestModal = (r: { id: string, rate: number; review: string; createdAt: Date; }) => {
        setEditableReview(r);
        setEditableRequest(requests?.filter(req => req.review === r.id)[0]);
        setRequestModal(true);
    }

    const refresh = async () => {
        await doGetRequests();
        await doGetReviews();
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">Minhas avaliações</h1>
            <div className="bg-white px-4 py-10 mt-2">
                {
                    loading ?
                        <ClockAnimation />
                        :
                        reviews?.map((r, i) => (
                            <div key={i} className="min-h-24 flex flex-col md:flex-row border border-mono items-center items-stretch w-full bg-moninho m-auto mb-4">
                                <div className="min-h-full px-4 py-2 flex-1">
                                    <div className="w-fit m-auto mb-2 flex gap-4 items-center">
                                        <TemplateStars width="30px" rate={r.rate} />
                                        <p>{parseTimestamp(r.createdAt)}</p>
                                    </div>
                                    <p>{r.review}</p>
                                </div>
                                <div className="md:w-44 py-2 px-4 border-t md:border-l md:border-t-0 border-mono flex flex-col justify-center items-center gap-4">
                                    {
                                        requests?.map(req => req.review).includes(r.id) ?
                                        <p>Exclusão solicitada</p>
                                        :
                                        null
                                    }
                                        <button onClick={() => handleRequestModal(r)} className="border border-mono px-2 py-1 bg-white hover:bg-[#ff9999] active:bg-[#ff9999]">Solicitar exclusão</button>
                                </div>
                            </div>
                        ))
                }
            </div>
            {
                requestModal && editableReview ?
                <RequestModal request={editableRequest} review={editableReview} close={() => setRequestModal(false)} refresh={refresh} goodToast={() => setGoodToast(true)} badToast={() => setBadToast(true)}/>
                : null
            }
            <AdminToast message="Solicitação enviada!" toast={goodToast} setToast={setGoodToast} color="#00bb00" />
            <AdminToast message="Erro!" toast={badToast} setToast={setBadToast} color="#ff0000" />
        </main>
    )
}