import { useEffect, useState } from "react";
import Clock1 from "./clock1";
import Clock2 from "./clock2";
import Clock3 from "./clock3";
import Clock4 from "./clock4";

export default function ClockAnimation({ width="50px", fill="#000" }: { width?: string, fill?: string }) {
    
    const [time, setTime] = useState(0);

    useEffect(() => {
        let t = 0;
        const cronos = setInterval(() => {
            t++;
            if(t>3){
                t=0;
            }
            setTime(t);
        }, 1000);

        return () => {
            clearInterval(cronos);
        }
    }, [])

    return (
        <div className={`m-auto`}>
            <div className="flex items-center justify-center min-h-[50px]">
                {
                    time === 0 ?
                    <Clock1 fill={fill} width={width}/>
                    : time === 1 ?
                    <Clock2 fill={fill} width={width}/>
                    : time === 2 ?
                    <Clock3 fill={fill} width={width}/>
                    : <Clock4 fill={fill} width={width}/>
                }
            </div>
            <h4 className="text-center" style={{color: fill}}>Carregando...</h4>
        </div>
    )
}
