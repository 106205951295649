import { useEffect, useState } from "react";
import { useAuth } from "../../../context/authProvider"
import { useWatches } from "../../../context/watchProvider";
import LoadingBalls from "../../core/loadingBalls";
import { useNavigate } from "react-router-dom";
import ReviewModal from "./reviewModal";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export default function MyPurchases() {

    const { userId } = useAuth();
    const { getPurchasedWatches } = useWatches();
    const navigate = useNavigate();

    const [watches, setWatches] = useState<{ [key: string]: any; id: string; }[]>([]);
    const [reviews, setReviews] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [reviewWatch, setReviewWatch] = useState<{ [key: string]: any; id: string; } | null>(null);

    useEffect(() => {
        doGetPurchasedWatches();
        getReviews();
        // eslint-disable-next-line
    }, []);

    const doGetPurchasedWatches = async () => {
        if (!userId) {
            return null;
        }
        setLoading(true);
        const data = await getPurchasedWatches(userId);
        if (!data) {
            setLoading(false);
            return null;
        }
        setWatches(data);
        setLoading(false);
    }

    const getReviews = async () => {
        try {
            const q = query(collection(db, "reviews"), where("userId", "==", userId));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            let docs: string[] = [];
            docsSnap.forEach(d => {
                docs.push(d.data().watch);
            })
            setReviews(docs);
        }catch(error){
            console.log(error);
        }
    }

    const handleReviewModal = (watch: { [key: string]: any; id: string; }) => {
        setReviewWatch(watch);
        setReviewModal(true);
    }

    return (
        <div className="text-[#939393] w-full border border-mono rounded">
            <h2 className="text-xl border-b border-mono text-center p-2">Minhas compras</h2>
            {
                watches.some(w => !reviews.includes(w.id)) ?
                    <p className="text-center text-red-500">Lembre-se de avaliar as suas compras!</p>
                    : null
            }
            {
                !watches?.length ?
                    <p className="mt-4 text-center">Nenhum item comprado</p>
                    :
                    <div className="flex gap-4 mt-2 flex-wrap p-2 justify-evenly md:justify-start">
                        {
                            loading ?
                                <div className="w-fit m-auto"><LoadingBalls /></div>
                                :
                                watches?.map((w, i) => (
                                    <div key={i} className="border border-mono w-[150px] overflow-x-hidden">
                                        <div className="relative h-[150px]">
                                            <img src={w.cImgUrl} alt="thumb" className="h-full object-cover" />
                                        </div>
                                        <div className="text-center text-black bg-moninho py-1">
                                            <p className="whitespace-nowrap my-1"><strong>{w.brand.toUpperCase()}</strong> {w.year}</p>
                                            <div className="flex gap-2 m-auto w-fit">
                                                <button onClick={() => navigate(`/watches/${w.slug}`)} className="text-sm bg-primary text-white px-4 py-1 active:opacity-70">VER</button>
                                                {
                                                    !reviews.includes(w.id) ?
                                                        <div className="relative">
                                                            <div className="w-4 h-4 bg-red-500 rounded-full absolute -top-2 -right-2"></div>
                                                            <button title="Avalie sua compra" onClick={() => handleReviewModal(w)} className="text-sm bg-primary text-white p-1 active:opacity-70">AVALIAR</button>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
            }
            {
                reviewModal && reviewWatch?
                <ReviewModal watch={reviewWatch} close={() => setReviewModal(false)} refresh={getReviews}/>
                : null
            }
        </div>
    )
}