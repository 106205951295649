export default function BannerIcon({ width = "30px", fill = "#000" }: { width?: string, fill?: string }) {
    return (
        <svg width={width} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 61" xmlSpace="preserve" fill="#fff" stroke={fill}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier" strokeWidth={2}>
                <g>
                    <g>
                        <polygon points="8.5,37 8.5,34.5 8.5,26 0,26 6,34 0,42 14.5,42 14.5,37 10.5,37 "></polygon>
                        <polygon points="61,26 52.5,26 52.5,34.5 52.5,37 50.5,37 45.5,37 45.5,42 61,42 55,34 "></polygon>
                        <rect x="10.5" y="19" width="40" height="16"></rect>
                    </g>
                </g>
            </g>
        </svg>
    )
}
