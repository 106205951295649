import { useState } from "react";
import { useAuth } from "../../../context/authProvider";
import MenuIcon from "../../../img/svg/menuIcon";
import XIcon from "../../../img/svg/xIcon";
import SideBar from "./sideBar";
import GMTIcon from "../../../img/svg/gmtIcon";
import { Link } from "react-router-dom";

export default function AdminHeader() {

    const { user } = useAuth();

    const [bar, setBar] = useState(false);

    return (
        <header className="bg-[#fafafa] shadow-md">
            <div className="flex justify-between text-center p-4 items-center">
                <div onClick={() => setBar(!bar)} className="md:hidden cursor-pointer">
                    {
                        bar ?
                        <XIcon fill="#333333"/>
                        :
                        <MenuIcon fill="#333333"/>
                    }
                </div>
                <Link to="/">
                    <GMTIcon />
                </Link>
                <h3>{user?.email}</h3>
            </div>
            {
                bar ?
                <SideBar isMobile/>
                : null
            }
        </header>
    )
}
