import AdminsList from "./adminsList";
import AdvertisersList from "./advertisersList";
import FindUser from "./findUser";

export default function AdminUsers() {


    return (
        <main className="relative px-4">
            <h1 className="text-center font-bold text-xl my-4">Usuários</h1>
            <FindUser />
            <AdvertisersList/>
            <AdminsList/>
        </main>
    )
}