import { useEffect, useState } from "react";
import { IFAQ } from "../admin/faqs/adminFaqs";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import LoadingBalls from "../../core/loadingBalls";
import ExpandableFAQ from "./expandableFaq";

export default function FAQPage() {

    const [faqs, setFaqs] = useState<IFAQ[] | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getFaqs();
        // eslint-disable-next-line
    }, [])

    const getFaqs = async () => {
        try {
            setLoading(true);
            let docs: IFAQ[] = [];

            const q = query(collection(db, "faqs"), where("display", "==", true));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setLoading(false);
                return null;
            }
            docsSnap.forEach(doc => {
                docs.push({
                    id: doc.id,
                    question: doc.data().question,
                    answer: doc.data().answer,
                    display: doc.data().display,
                    order: doc.data().order
                })
            })
            setFaqs(docs.sort((a, b) => a.order - b.order));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <main className="min-h-[50vh] pb-20">
            <h1 className="text-center text-2xl font-bold my-10">Perguntas frequentes</h1>
            {
                loading ?
                    <div className="w-fit m-auto mt-20">
                        <LoadingBalls />
                    </div>
                    :
                    faqs?.map((f, i) => (
                        <ExpandableFAQ faq={f} index={i} key={i}/>
                    ))
            }
        </main>
    )
}
