import { useNavigate } from "react-router-dom";
import XIcon from "../../../img/svg/xIcon";
import { formatPhoneNumber } from "../../../utils/functions";
import LoadingBalls from "../../core/loadingBalls";
import InstagramIcon from "../../../img/svg/instagramIcon";
import SiteIcon from "../../../img/svg/siteIcon";
import WhatsappIcon from "../../../img/svg/whatsappIcon";

export default function AdvertiserModal({ adv, loading, close }: { adv: { [key: string]: string } | null; loading: boolean; close: () => void }) {

    const navigate = useNavigate();

    return (
        <div onClick={close} className="fixed z-10 top-0 left-0 bg-[#00000088] w-full h-full overflow-x-hidden px-4 md:px-0">
            <div onClick={e => e.stopPropagation()} className="w-full md:w-[700px] m-auto mt-2 md:mt-10 rounded bg-white relative">
                <div onClick={close} className="absolute top-1 right-1 cursor-pointer"><XIcon fill="#ffffff" /></div>
                <h1 className="bg-primary text-white text-center rounded-t py-2">INFORMAÇÕES DO ANUNCIANTE</h1>
                {
                    loading ?
                        <div className="w-fit m-auto mt-10"><LoadingBalls /></div>
                        : !adv ?
                            <p className="text-center mt-10">Nenhuma informação disponível</p>
                            :
                            <div className="flex flex-col justify-center items-center h-full">
                                <div className="md:grid flex flex-col md:grid-cols-[100px_1fr] border w-full">
                                    <h2 className="bg-moninho p-4 font-bold">Nome</h2>
                                    <h2 className="bg-moninho flex-1 md:p-4 py-1 text-center">{adv?.name}</h2>

                                    <p className="p-4 font-bold">Email</p>
                                    <p className="md:p-4 py-1 flex-1 text-center">{adv.email}</p>

                                    <p className="bg-moninho p-4 font-bold">Telefone</p>
                                    <div className="bg-moninho flex-1">
                                    <a className="py-1 md:p-4 text-center block w-fit m-auto text-secondary" href={`https://wa.me/55${adv?.phoneNumber}`} target="_blank" rel="noreferrer">{formatPhoneNumber(adv?.phoneNumber)}</a>
                                    </div>
                                </div>
                                <div className="w-full p-4 flex justify-evenly items-center border-b">
                                    {
                                        adv?.phoneNumber ?
                                            <a href={`https://wa.me/55${adv?.phoneNumber}`} target="_blank" rel="noreferrer" className="rounded bg-moninho p-2">
                                                <WhatsappIcon />
                                            </a>
                                            : null
                                    }
                                    {
                                        adv?.instagram ?
                                            <a href={`https://instagram.com/${adv?.instagram}`} target="_blank" rel="noreferrer" className="rounded bg-moninho p-2">
                                                <InstagramIcon />
                                            </a>
                                            : null
                                    }
                                    {
                                        adv?.site ?
                                            <a href={`${adv?.site}`} target="_blank" rel="noreferrer" className="rounded bg-moninho p-2">
                                                <SiteIcon />
                                            </a>
                                            : null
                                    }
                                </div>
                                <button onClick={() => navigate(`/advertisers/${adv.slug}`)} className="bg-primary w-fit m-auto text-white px-4 py-1 hover:opacity-70 active:opacity-70 my-4">PÁGINA DO ANUNCIANTE</button>
                            </div>
                }
            </div>
        </div>
    )
}
