import { useEffect, useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { useWatches } from "../../../../context/watchProvider";
import Review from "../../../core/review";
import { formatPhoneNumber } from "../../../../utils/functions";
import { IRequest } from "./requests";
import { IReview } from "./reviews";

export default function RequestDetailsModal({ close, request, goodToast, badToast, refresh }: { close: () => void, request: IRequest, refresh: () => Promise<null>, goodToast: () => void, badToast: () => void }) {

    const { getAdvertiser } = useWatches();

    const [loading, setLoading] = useState(false);
    const [advertiser, setAdvertiser] = useState<{ [key: string]: any; id: string; } | null>(null);
    const [review, setReview] = useState<IReview | null>(null);
    const [edit, setEdit] = useState(false);
    const [cancel, setCancel] = useState(false);

    useEffect(() => {
        doGetAdvertiser();
        doGetReview();
        // eslint-disable-next-line
    }, []);

    const doGetAdvertiser = async () => {
        setLoading(true);
        const adv = await getAdvertiser(request.advertiser);
        if (!adv) {
            setLoading(false);
            return null;
        }
        setAdvertiser(adv);
        setLoading(false);
    }

    const doGetReview = async () => {
        try {
            setLoading(true);
            if(!request.review){
                return null;
            }
            const docRef = doc(db, "reviews", request?.review);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                setLoading(false);
                return null;
            }
            setReview({
                id: docSnap.id,
                advertiser: docSnap.data().advertiser,
                createdAt: docSnap.data().createdAt.toDate(),
                hidden: docSnap.data().hidden,
                rate: docSnap.data().rate,
                userId: docSnap.data().userId,
                watch: docSnap.data().watch,
                review: docSnap.data().review
            })
            setLoading(false);
            return null;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return null;
        }
    }

    const handleHideOperation = async () => {
        try {
            if(!review){
                return null;
            }
            setLoading(true);

            const docRef = doc(db, "reviews", review.id);
            await updateDoc(docRef, { hidden: true });

            const docRef2 = doc(db, "reviewsrequests", request.id);
            await updateDoc(docRef2, { solved: true });

            setLoading(false);
            goodToast();
            await refresh();
            close();
        } catch (error) {
            console.log(error);
            badToast();
            setLoading(false);
        }
    }

    const handleCancelOperation = async () => {
        try {
            if(!request){
                return null;
            }
            setLoading(true);

            const docRef = doc(db, "reviewsrequests", request.id);
            await updateDoc(docRef, { solved: true });

            setLoading(false);
            goodToast();
            await refresh();
            close();
        } catch (error) {
            console.log(error);
            badToast();
            setLoading(false);
        }
    }

    return (
        <AdminModal close={close}>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div className="">
                        {
                            review ?
                            <div className="border border-mono mt-6 bg-white">
                                <h2 className="text-center text-lg bg-primary text-white">Sobre a avaliação</h2>
                                <Review review={{createdAt: review?.createdAt, rate: review?.rate, review: review?.review}}/>
                            </div>
                            : null
                        }

                            <div className="border border-mono flex-1">
                                <h2 className="text-center text-lg bg-primary text-white">Sobre o anunciante</h2>
                                <div className="border rounded grid grid-cols-2">
                                    <p className="py-2 px-2 border-b bg-moninho font-bold">Nome</p>
                                    <p className="py-2 px-2 border-b bg-moninho">{advertiser?.name}</p>

                                    <p className="py-2 px-2 bg-white border-b font-bold">Email</p>
                                    <p className="py-2 px-2 bg-white border-b break-words">{advertiser?.email}</p>

                                    <p className="py-2 px-2 border-b bg-moninho font-bold">Telefone</p>
                                    <p className="py-2 px-2 border-b bg-moninho">{formatPhoneNumber(advertiser?.phoneNumber)}</p>
                                </div>
                            </div>
                            <div className="border border-mono flex-1">
                                <h2 className="text-center text-lg bg-primary text-white">Sobre o relógio</h2>
                                <div className="border rounded grid grid-cols-2">
                                    <p className="py-2 px-2 border-b bg-moninho font-bold">ID</p>
                                    <p className="py-2 px-2 border-b bg-moninho">{review?.watch}</p>
                                </div>
                            </div>
                        {
                            edit ?
                                <div>
                                    <p className="text-lg text-center font-bold my-4">{request.solved ? "Deseja exibir essa avaliação?" : "Deseja esconder essa avaliação?"}</p>
                                    <div className="flex gap-4">
                                        <button onClick={handleHideOperation} className="flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] disabled:bg-mono py-1">Sim</button>
                                        <button onClick={() => setEdit(false)} className="flex-1 text-white bg-black hover:bg-[#333] active:bg-[#333] py-1">Cancelar</button>
                                    </div>
                                </div>
                                : cancel ?
                                <div>
                                    <p className="text-lg text-center font-bold my-4">Deseja cancelar a solicitação sem alterar a avaliação?</p>
                                    <div className="flex gap-4">
                                        <button onClick={handleCancelOperation} className="flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] disabled:bg-mono py-1">Sim</button>
                                        <button onClick={() => setCancel(false)} className="flex-1 text-white bg-black hover:bg-[#333] active:bg-[#333] py-1">Cancelar</button>
                                    </div>
                                </div>
                                :
                                <div className="flex justify-center gap-4 mt-4">
                                    <button onClick={() => setEdit(true)} className="text-white bg-[#000] hover:bg-[#333] active:bg-[#333] px-2 py-1">{request.solved ? "Exibir avaliação" : "Esconder avaliação"}</button>
                                    <button onClick={() => setCancel(true)} className="text-white bg-[#ff0000] hover:bg-[#ff9999] active:bg-[#ff9999] px-2 py-1">Cancelar solicitação</button>
                                </div>
                        }
                    </div>
            }
        </AdminModal>
    )
}
