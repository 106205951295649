import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/authProvider";
import { useWatches } from "../../../../context/watchProvider";
import DraftWatches from "./drafts/draftWatches";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import AdminToast from "../../../core/admin/adminToast";
import ApprovalWatches from "./approvalWatches";
import AdvertisedWatches from "./advertisedWatches";
import SoldWatches from "./soldWatches";
import HiddenWatches from "./hiddenWatches";
import { IWatch } from "../watches/adminWatches";

export default function MyWatches() {

    const { getMyWatches } = useWatches();
    const { userId } = useAuth();

    const [loading, setLoading] = useState(false);
    const [myWatches, setMyWatches] = useState<IWatch[]>([]);

    const [badToast, setBadToast] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [deleteToast, setDeleteToast] = useState(false);
    const [approvalToast, setApprovalToast] = useState(false);

    const doGetMyWatches = async () => {
        setLoading(true);
        if (userId) {
            const myW = await getMyWatches(userId);
            if(myW){
                setMyWatches(myW);
                setLoading(false);
                return null;
            }
        }
        setMyWatches([]);
        setLoading(false);
        setBadToast(true);
    }

    const finishCreation = () => {
        setGoodToast(true);
        doGetMyWatches();
    }

    const editOk = () => {
        setApprovalToast(true);
        doGetMyWatches();
    }

    const deleteOk = () => {
        setDeleteToast(true);
        doGetMyWatches();
    }

    useEffect(() => {
        doGetMyWatches();
        // eslint-disable-next-line
    }, []);

    return (
        <main className="p-4 relative">
            <h1 className="text-xl text-center font-bold">Meus relógios</h1>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div>
                        <DraftWatches watches={myWatches.filter(w => w.status === "draft")} finish={finishCreation} editOk={editOk} deleteOk={deleteOk} refresh={doGetMyWatches}/>
                        <ApprovalWatches watches={myWatches.filter(w => w.status === "approval")} refresh={doGetMyWatches}/>
                        <AdvertisedWatches watches={myWatches.filter(w => w.status === "display")} refresh={editOk}/>
                        <SoldWatches watches={myWatches.filter(w => w.status === "sold")}/>
                        <HiddenWatches watches={myWatches.filter(w => w.status === "hidden")}/>
                    </div>
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} color="#00bb00" message="Rascunho criado!"/>
            <AdminToast toast={badToast} setToast={setBadToast} message="Nenhum relógio encontrado" color="#ff3333"/>
            <AdminToast toast={deleteToast} setToast={setDeleteToast} message="Relógio removido!" color="#FFC300"/>
            <AdminToast toast={approvalToast} setToast={setApprovalToast} message="Relógio atualizado!" color="#00bb00"/>
        </main>
    )
}
