export default function AdminButton({
    borderColor = "black",
    borderWidth = 0,
    onClick,
    children,
    disabled=false
}: {
    borderColor?: string;
    borderWidth?: number;
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
}) {

    return (
        <button onClick={onClick} disabled={disabled} className="text-[#cccccc] p-2 cursor-pointer disabled:cursor-not-allowed active:opacity-70"
            style={
                {
                    borderColor: borderColor,
                    borderWidth: borderWidth,
                    backgroundColor: disabled ? "#aaa" : "#000"
                }
            }
        >
            {children}
        </button>
    )
}