export default function FacebookIcon({ width = "30px", fill = "#000" }: { width?: string, fill?: string }) {
    return (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill={fill} width={width}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <rect x="0" fill="none" width="20" height="20"></rect>
                <g>
                    <path d="M2.89 2h14.23c.49 0 .88.39.88.88v14.24c0 .48-.39.88-.88.88h-4.08v-6.2h2.08l.31-2.41h-2.39V7.85c0-.7.2-1.18 1.2-1.18h1.28V4.51c-.22-.03-.98-.09-1.86-.09-1.85 0-3.11 1.12-3.11 3.19v1.78H8.46v2.41h2.09V18H2.89c-.49 0-.89-.4-.89-.88V2.88c0-.49.4-.88.89-.88z"></path>
                </g>
            </g>
        </svg>
    )
}
