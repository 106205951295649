import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { addDoc, collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useAuth } from "../../../../context/authProvider";

export default function RequestModal({ review, request, close, refresh, goodToast, badToast }: { review: { id: string, rate: number; review: string; createdAt: Date; }, request: {id: string, review: string} | undefined, close: () => void, refresh: () => void, goodToast: () => void, badToast: () => void }) {

    const { userId } = useAuth();

    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    

    const handleReason = (value: string) => {
        if (value.length > 152) {
            return null;
        }
        setReason(value)
    }

    const handleRequest = async () => {
        try {
            setLoading(true);
            if(request?.id){
                await updateDoc(doc(db, "reviewsrequests", request.id), {solved: false, reason});
            }
            else{
                await addDoc(collection(db, "reviewsrequests"), {
                    advertiser: userId,
                    review: review.id,
                    reason,
                    createdAt: serverTimestamp(),
                    solved: false
                })
            }
            setLoading(false);
            refresh();
            goodToast();
            close();

            return null;

        } catch (error) {
            console.log(error);
            badToast();
            setLoading(false);
        }
    }

    return (
        <AdminModal close={close}>
            <h2 className="text-lg text-center font-bold mb-10">Por que essa avaliação deve ser excluída?</h2>
            <h3 className="text-center mb-2">Escreva abaixo o motivo:</h3>
            <textarea className="px-4 py-2 resize-none outline-none border border-mono w-full m-auto text-center" value={reason} onChange={e => handleReason(e.target.value)}></textarea>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div className="flex gap-4 mt-4">
                        <button disabled={reason === ""} onClick={handleRequest} className="flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] disabled:bg-mono py-1">Enviar solicitação</button>
                        <button onClick={close} className="flex-1 text-white bg-black hover:bg-[#333] active:bg-[#333] py-1">Cancelar</button>
                    </div>
            }
            
        </AdminModal>
    )
}
