import StarIcon from "../../img/svg/starIcon";

export default function ReviewStars({rate, setRate}: {rate: number, setRate: (number: number) => void}) {
    return (
        <div className="flex w-fit cursor-pointer">
            <div onClick={() => setRate(1)}>
                <StarIcon width="60px" stroke="#1F2429" fill={rate >= 1 ? "#F09D38" : "none"}/>
            </div>
            <div onClick={() => setRate(2)}>
                <StarIcon width="60px" stroke="#1F2429" fill={rate >= 2 ? "#F09D38" : "none"}/>
            </div>
            <div onClick={() => setRate(3)}>
                <StarIcon width="60px" stroke="#1F2429" fill={rate >= 3 ? "#F09D38" : "none"}/>
            </div>
            <div onClick={() => setRate(4)}>
                <StarIcon width="60px" stroke="#1F2429" fill={rate >= 4 ? "#F09D38" : "none"}/>
            </div>
            <div onClick={() => setRate(5)}>
                <StarIcon width="60px" stroke="#1F2429" fill={rate >= 5 ? "#F09D38" : "none"}/>
            </div>
        </div>
    )
}
