import { useState } from "react";
import Review from "../../../core/review";
import ReviewDetailsModal from "./reviewDetailsModal";
import { IReview } from "./reviews";
import { IRequest } from "./requests";

export default function ReviewsList({ reviews, requests, refresh, goodToast, badToast }: { reviews: IReview[] | null, requests: IRequest[] | null, refresh: () => Promise<null>, goodToast: () => void, badToast: () => void }) {

    const [modal, setModal] = useState(false);
    const [editableReview, setEditableReview] = useState<IReview | null>(null);

    if (!reviews || !reviews.length) {
        return null;
    }

    const handleEditModal = (r: IReview) => {
        setEditableReview(r)
        setModal(true);
    }

    const isRequest = (review: IReview) => {
        const req: IRequest[] | undefined = requests?.filter(r => r.review === review.id);
        if(req?.length && req[0]?.solved){
            return {message: "Solicitação resolvida", color: "green"};
        }
        if(req?.length && !req[0]?.solved){
            return {message: "Solicitação aberta", color: "red"};
        }
        return {message: "", color: "green"};
    }

    return (
        <div>
            {
                reviews.map((r, i) => (
                    <div key={i} className="border border-mono h-fit bg-moninho flex flex-col md:flex-row mb-4">
                        <Review review={r} />
                        <div className="border-t md:border-t-0 md:border-l border-mono flex flex-col items-center justify-center px-2 gap-2 md:w-48">
                            <div>
                                <p className="font-bold text-lg text-center" style={{ color: r.hidden ? "red" : "green" }}>{r.hidden ? "Escondida" : "Visível"}</p>
                                {
                                    r.hidden || isRequest(r).color === "red" ?
                                    <p className="font-bold text-lg text-center" style={{ color: isRequest(r).color }}>{isRequest(r).message}</p>
                                    : null
                                }
                            </div>
                            <button onClick={() => handleEditModal(r)} className="text-white px-2 py-1 rounded bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]">Detalhes</button>
                        </div>
                    </div>
                ))
            }
            {
                modal && editableReview ?
                    <ReviewDetailsModal close={() => setModal(false)} review={editableReview} goodToast={goodToast} badToast={badToast} refresh={refresh}/>
                    : null
            }
        </div>
    )
}
