import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useEffect, useState } from "react";
import { statusFields } from "../../../../utils/fields";
import ClockAnimation from "../../../../img/svg/clockAnimation";

export default function AdminGeneral() {

    const [statuses, setStatuses] = useState<{ [key: string]: number }>({});
    const [users, setUsers] = useState<{ [key: string]: { label: string, count: number } }>({});
    const [requestsCount, setRequestsCount] = useState(0);
    const [watchesLoading, setWatchesLoading] = useState(false);
    const [requestsLoading, setRequestsLoading] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);

    useEffect(() => {
        countWatchesStatus();
        countRequests();
        countUsers();
    }, []);

    const countWatchesStatus = async () => {
        try {
            setWatchesLoading(true);
            const statusCounts: { [key: string]: any } = {};
            const q = collection(db, "watches");
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setWatchesLoading(false);
                return null;
            }
            docsSnap.forEach(document => {
                const d = document.data();
                const status = d.status;
                if (statusCounts[status]) {
                    statusCounts[status]++;
                } else {
                    statusCounts[status] = 1;
                }
            })

            setStatuses(statusCounts);

        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            setWatchesLoading(false);
        }
    }

    const countRequests = async () => {
        try {
            setRequestsLoading(true);
            const q = query(collection(db, "reviewsrequests"), where("solved", "==", false));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setRequestsLoading(false);
                return null;
            }
            setRequestsCount(docsSnap.size);
        } catch (error) {
            console.log(error);
        } finally {
            setRequestsLoading(false);
        }
    }

    const countUsers = async () => {
        try {
            setUsersLoading(true);
            let admins = 0;
            let advertisers = 0;
            let customers = 0;
            const q = collection(db, "users");
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setUsersLoading(false);
                return null;
            }
            docsSnap.forEach(document => {
                const d = document.data();
                if (d.roles.includes("admin")) {
                    admins++;
                }
                if (d.roles.includes("advertiser")) {
                    advertisers++;
                }
                if (d.roles.includes("customer")) {
                    customers++;
                }
            })

            setUsers({
                admins: { label: "Administradores", count: admins },
                advertisers: { label: "Anunciantes", count: advertisers },
                customers: { label: "Clientes", count: customers }
            })

        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            setUsersLoading(false);
        }
    }

    return (
        <main className="text-center px-4">
            <h1 className="text-center font-bold text-xl my-4">Geral</h1>
            <div className="bg-[#fafafa] py-10 px-2">
                {
                    requestsLoading ?
                        <ClockAnimation />
                        :
                        <div className="border border-mono rounded w-full md:w-[50%] m-auto flex p-2 items-center bg-[#DDDDDD] relative">
                            <h2 className="flex-1 text-center text-lg font-bold">Solicitações sobre avaliações</h2>
                            <p className="flex-1 text-center">{requestsCount}</p>
                            {
                                requestsCount > 0 ?
                                    <div className="w-3 h-3 bg-red-500 rounded-full absolute top-4 right-2"></div>
                                    : null
                            }
                        </div>
                }
                {
                    watchesLoading ?
                        <ClockAnimation />
                        :
                        <div className="border border-mono rounded w-full md:w-[50%] m-auto mt-4">
                            <h2 className="text-center text-lg font-bold bg-mono">Relógios</h2>
                            {
                                Object.keys(statusFields).map((f, i) => (
                                    <div key={i} className="flex items-center border border-mono px-2 relative"
                                        style={{ backgroundColor: i % 2 === 0 ? "" : "#DDDDDD" }}
                                    >
                                        <p className="flex-1 text-left">{statusFields[f]}</p>
                                        <p className="flex-1 text-center border-l border-mono">{statuses[f] || 0}</p>
                                        {
                                            f === "approval" && statuses[f] > 0 ?
                                                <div className="w-2 h-2 bg-red-500 rounded-full absolute top-2 right-2"></div>
                                                : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                }
                {
                    usersLoading ?
                        <ClockAnimation />
                        :
                        <div className="border border-mono rounded w-full md:w-[50%] m-auto mt-4">
                            <h2 className="text-center text-lg font-bold bg-mono">Usuários</h2>
                            {
                                Object.keys(users).map((u, i) => (
                                    <div key={i} className="flex items-center border border-mono px-2"
                                        style={{ backgroundColor: i % 2 === 0 ? "" : "#DDDDDD" }}
                                    >
                                        <p className="flex-1 text-left">{users[u].label}</p>
                                        <p className="flex-1 text-center border-l border-mono">{users[u].count}</p>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </main>
    )
}
