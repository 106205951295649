export default function AdminHome() {

    return (
        <main className="text-center px-4">
            <h1 className="text-center font-bold text-xl my-4">Início</h1>
            <div className="bg-[#fafafa] py-10">
                <p className="text-center text-md mt-10">Boas vindas ao site administrativo do Classificados GMT-3!</p>
            </div>
        </main>
    )
}
