import { useState } from "react";
import Switch from "../../../core/switch";
import { IFAQ } from "./adminFaqs";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import AdminToast from "../../../core/admin/adminToast";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import EditFAQModal from "./editFaqModal";

export default function FAQ({ faq, goodToast, refresh, reorder }: { faq: IFAQ, goodToast: () => void, refresh: (f: IFAQ) => void, reorder: boolean }) {

    const [loading, setLoading] = useState(false);
    const [badToast, setBadToast] = useState(false);
    const [order, setOrder] = useState(faq.order);
    const [modal, setModal] = useState(false);

    const editFaq = async (fields: {}) => {
        try {
            setLoading(true);
            const docRef = doc(db, "faqs", faq.id);
            await updateDoc(docRef, fields);
            refresh({ ...faq, ...fields });
            goodToast();
        } catch (error) {
            console.log(error);
            setBadToast(true);
        } finally {
            setLoading(false);
        }
    }

    const handleOrder = (value: string) => {
        if (value === "") {
            setOrder(0);
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if (parseInt(value) > 99) {
            return null;
        }
        setOrder(parseInt(value));
    }

    const saveOrder = async () => {
        try {
            setLoading(true);
            const docRef = doc(db, "faqs", faq.id);
            await updateDoc(docRef, { order });
            refresh({ ...faq, order });
            goodToast();
        } catch (error) {
            console.log(error);
            setBadToast(true);
        } finally {
            setLoading(false);
        }
    }

    const finish = (faq: IFAQ) => {
        refresh(faq);
        setModal(false);
        goodToast();
    }

    if (loading) {
        return (
            <div className="flex bg-moninho border border-mono items-center p-2 mb-4">
                <ClockAnimation />
            </div>
        )
    }

    return (
        <div className="flex flex-col md:flex-row bg-moninho border border-mono items-center relative mb-4">
            <p className="text-center flex-1">{faq.question}</p>
            <div className="flex flex-row md:flex-col items-center justify-center gap-4 border-mono border-t md:border-t-0 md:border-l p-2">
                <button onClick={() => editFaq({ display: !faq.display })}><Switch on={faq.display} /></button>
                <button onClick={() => setModal(true)} className="text-white px-2 py-1 rounded bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7] w-full">Ver</button>
            </div>
            {
                reorder ?
                    <div className="absolute -top-2 -left-2 flex gap-4 items-center">
                        <input className="outline-none border border-mono p-2 text-center w-10" type="text" value={order} onChange={e => handleOrder(e.target.value)} />
                        <button onClick={saveOrder} disabled={order === faq.order} className="disabled:bg-mono bg-[#00bb00] active:bg-[#88bb88] hover:bg-[#88bb88] px-2 py-1 h-fit text-white">Salvar</button>
                    </div>
                    : null
            }
            {
                modal ?
                    <EditFAQModal finish={finish} close={() => setModal(false)} faq={faq} />
                    : null
            }
            <AdminToast toast={badToast} setToast={setBadToast} color="#bb0000" message="Erro!" />
        </div>
    )
}