import { useEffect, useState } from "react";
import PlusIcon from "../../../../img/svg/plusIcon";
import CreateFAQModal from "./createFaqModal";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import AdminToast from "../../../core/admin/adminToast";
import FAQ from "./faq";

export interface IFAQ {
    id: string;
    question: string;
    answer: string;
    display: boolean;
    order: number;
}

export default function AdminFAQs() {

    const [faqs, setFaqs] = useState<IFAQ[]|null>(null);
    const [createModal, setCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [reorder, setReorder] = useState(false);

    useEffect(() => {
        getFaqs();
        // eslint-disable-next-line
    }, [])

    const finish = () => {
        getFaqs();
        setCreateModal(false);
        setGoodToast(true);
    }

    const refresh = async (faq: IFAQ) => {
        if(!faqs){
            return null;
        }
        setFaqs(prevFaqs => prevFaqs && prevFaqs.map(obj => obj.id === faq.id ? {...faq} : obj));
    }

    const getFaqs = async () => {
        try{
            setLoading(true);
            let docs: IFAQ[] = [];
            const q = collection(db, "faqs");
            const docsSnap = await getDocs(q);
            if(docsSnap.empty){
                setLoading(false);
                return null;
            }
            docsSnap.forEach(doc => {
                docs.push({
                    id: doc.id,
                    question: doc.data().question,
                    answer: doc.data().answer,
                    display: doc.data().display,
                    order: doc.data().order
                })
            })
            setFaqs(docs.sort((a, b) => a.order - b.order));
        }catch(error){
            console.log(error);
        }finally {
            setLoading(false);
        }
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">FAQs</h1>
            <div className="bg-white px-4 py-10 mt-2 relative">
                <button onClick={() => setCreateModal(true)} className="w-fit m-auto absolute top-2 right-2 p-2 bg-[#00bb00] flex items-center gap-2 hover:bg-[#88bb88] active:bg-[#00bb00] font-bold text-white"><PlusIcon fill="#fff" width="20px" /> Criar</button>
                {
                    loading ?
                    <ClockAnimation/>
                    : !faqs ?
                    <p className="text-center">Nenhuma FAQ encontrada</p>
                    : 
                    <div className="mt-4">
                        {
                                !reorder ?
                                    <div className="w-fit m-auto mb-4">
                                        <button onClick={() => setReorder(true)} className="bg-[#5804ab] text-white px-4 py-1 hover:bg-[#6d03d6] active:bg-[#6d03d6]">Reordenar</button>
                                    </div>
                                    :
                                    <div className="w-fit m-auto flex gap-4 mb-4">
                                        <button onClick={() => setReorder(false)} className="bg-[#000] text-white px-4 py-1 hover:bg-[#333] active:bg-[#333]">Cancelar</button>
                                    </div>
                            }
                        {
                            faqs.map((f, i) => (
                                <FAQ faq={f} goodToast={() => setGoodToast(true)} refresh={refresh} reorder={reorder} key={i}/>
                            ))
                        }
                    </div>
                }
            </div>
            {
                createModal ?
                    <CreateFAQModal close={() => setCreateModal(false)} finish={finish}
                    />
                    : null
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} color="#00bb00" message="FAQs atualizados!" />
        </main>
    )
}
