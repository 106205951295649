import { useState } from "react";
import AdminToast from "../../../core/admin/adminToast";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import AdminButton from "../../../core/admin/adminButton";
import ClockAnimation from "../../../../img/svg/clockAnimation";

interface IUser {
    id: string;
    name: string;
    phoneNumber: string;
    email: string;
    roles: string[];
}

export default function AdvertisersList() {

    const [loading, setLoading] = useState(false);
    const [badToast, setBadToast] = useState(false);
    const [advertisers, setAdvertisers] = useState<IUser[]>([]);
    const [message, setMessage] = useState("");

    const searchAdvertisers = async () => {
        try {
            let docs: any[] = []
            setLoading(true);
            const col = collection(db, "users");
            const q = query(col, where("roles", "array-contains", "advertiser"));
            const documents = await getDocs(q);
            documents.docs.map(doc => docs.push({ id: doc.id, ...doc.data() }));
            setAdvertisers(docs);
            setMessage("Busca realizada!");
            setLoading(false);
        } catch (error) {
            console.log(error)
            setMessage("");
            setLoading(false);
            setBadToast(true);
        }
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-2 md:px-10 mt-4">

            <div className="w-fit m-auto flex items-center gap-4 mb-4">
                <h3 className="text-center text-lg">Usuários anunciantes</h3>
                <AdminButton onClick={searchAdvertisers}>Listar</AdminButton>
            </div>

            {
                advertisers.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">ID</th>
                                <th className="border border-[#aaaaaa] p-2">Nome</th>
                                <th className="border border-[#aaaaaa] p-2">E-mail</th>
                                <th className="border border-[#aaaaaa] p-2">Telefone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                advertisers.map((adv, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{adv.id}</td>
                                        <td className="border border-[#aaaaaa] p-2">{adv.name}</td>
                                        <td className="border border-[#aaaaaa] p-2">{adv.email}</td>
                                        <td className="border border-[#aaaaaa] p-2">{adv.phoneNumber}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    : <p className="text-center">Nenhum usuário listado</p>
            }
            <p className="text-center text-[#00bb00] mt-4">{message}</p>
            {
                loading ?
                    <ClockAnimation />
                    : null
            }
            <AdminToast message="Erro na busca!" toast={badToast} setToast={setBadToast} color="#ff3333" />
        </div>
    )
}
