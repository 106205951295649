import StarIcon from "../../img/svg/starIcon";

export default function TemplateStars({ width="60px", rate }: { width?: string, rate: number | undefined }) {

    if(rate === undefined){
        return (
            <div className="text-mono">
                Sem avaliações
            </div>
        )
    }

    return (
        <div className="flex">
            <StarIcon width={width} stroke="#1F2429" fill={rate >= 1 ? "#F09D38" : "none"} />
            <StarIcon width={width} stroke="#1F2429" fill={rate >= 2 ? "#F09D38" : "none"} />
            <StarIcon width={width} stroke="#1F2429" fill={rate >= 3 ? "#F09D38" : "none"} />
            <StarIcon width={width} stroke="#1F2429" fill={rate >= 4 ? "#F09D38" : "none"} />
            <StarIcon width={width} stroke="#1F2429" fill={rate >= 5 ? "#F09D38" : "none"} />
        </div>
    )
}
