import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useEffect, useState } from "react";
import { watchFields } from "../../../../utils/fields";
import AdminButton from "../../../core/admin/adminButton";
import AdminToast from "../../../core/admin/adminToast";
import CandidateWatchModal from "./candidateWatchModal";
import { IWatch } from "../watches/adminWatches";
import CheckIcon from "../../../../img/svg/checkIcon";
import XIcon from "../../../../img/svg/xIcon";
import AdminModal from "../../../core/admin/adminModal";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import DisplayedBanner from "./displayedBanner";

export default function AdminBanners() {

    const [banners, setBanners] = useState<{ [key: string]: any }[]>([]);
    const [watches, setWatches] = useState<IWatch[]>([]);
    const [filterName, setFilterName] = useState("");
    const [filterValue, setFilterValue] = useState("");

    const [bannersModal, setBannersModal] = useState(false);
    const [candidateModal, setCandidateModal] = useState(false);
    const [candidateWatch, setCandidateWatch] = useState<IWatch | undefined>(undefined);

    const [loading, setLoading] = useState(false);
    const [reorder, setReorder] = useState(false);

    const [badToast, setBadToast] = useState(false);

    useEffect(() => {
        getBanners();
    }, [])

    const doRefresh = async () => {
        await getBanners();
        await getCandidateWatches(filterName, filterValue);
    }

    const handleBanner = async (watch: IWatch) => {
        try {
            const docRef = doc(db, "watches", watch.id);
            if (watch.isBanner) {
                await updateDoc(docRef, { isBanner: false, order: 0 });
                return true;
            }
            await updateDoc(docRef, { isBanner: true, order: 0 });
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const getBanners = async () => {
        try {
            setLoading(true);
            const documents: { [key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("isBanner", "==", true));
            const docs = await getDocs(q);
            if (docs.empty) {
                setBanners(documents);
                setLoading(false);
                return null;
            }
            docs.docs.forEach(b => {
                documents.push({
                    id: b.id,
                    brand: b.data().brand,
                    model: b.data().model,
                    year: b.data().year,
                    price: b.data().price,
                    slug: b.data().slug,
                    imgUrl: b.data().images?.length ? b.data().images[0].cImgUrl : undefined,
                    serial: b.data().serial,
                    order: b.data().order
                });
            })
            setBanners(documents.sort((a, b) => a.order - b.order));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const getCandidateWatches = async (filter: string = "", value: string = "") => {
        try {
            const docs: IWatch[] = [];
            let q;
            if (filter === "id") {
                const docum = doc(db, "watches", value);
                const docSnap = await getDoc(docum);
                if (!docSnap.exists()) {
                    setWatches([]);
                    setBadToast(true);
                    return null;
                }
                setWatches([{ id: docSnap.id, ...docSnap.data() }]);
                return null;
            }
            if (filter && value) {
                q = query(collection(db, "watches"), where("status", "==", "display"), where(filter, "==", filter === "year" ? parseInt(value) : filter === "serial" ? value : value.toLowerCase()));
            } else {
                q = query(collection(db, "watches"), where("status", "==", "display"));
            }
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setWatches([]);
                setBadToast(true);
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    ...document.data()
                })
            })
            setWatches(docs);
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const handleCandidateModal = (watch: IWatch) => {
        setCandidateWatch(watch);
        setCandidateModal(true);
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">Banners</h1>
            <div className="bg-white pt-2 pb-10 mt-2">
                {
                    loading ?
                        <ClockAnimation />
                        :
                        <div>
                            <div className="flex items-center justify-center gap-4 mt-4">
                                <h2 className="text-center text-lg">Em exibição: <strong>{banners.length}</strong></h2>
                                <AdminButton onClick={() => setBannersModal(true)} disabled={!banners.length}>Ver banners</AdminButton>
                            </div>
                            <h2 className="text-lg font-bold text-center mt-4">Procurar relógios</h2>
                            <div className="flex gap-2 m-auto w-fit items-center">
                                <p className="text-center text-md font-bold">Filtro</p>
                                <select value={filterName} onChange={e => setFilterName(e.target.value)} className="border border-black text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(watchFields).map((k, i) => (
                                            <option key={i} value={k}>{watchFields[k]}</option>
                                        ))
                                    }
                                </select>
                                <input type="text" value={filterValue} onChange={e => setFilterValue(e.target.value)} className="border border-black text-center" />
                                <AdminButton onClick={() => getCandidateWatches(filterName, filterValue)}>Pesquisar</AdminButton>
                            </div>
                        </div>
                }

                {
                    watches.length ?
                        <div className="mt-4">
                            <h2 className="text-center text-lg mb-2">Relógios encontrados</h2>
                            <table className="w-fit m-auto border border-[#aaaaaa]">
                                <thead>
                                    <tr>
                                        <th className="border border-[#aaaaaa] p-2"></th>
                                        <th className="border border-[#aaaaaa] p-2">Marca</th>
                                        <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                        <th className="border border-[#aaaaaa] p-2">Ano</th>
                                        <th className="border border-[#aaaaaa] p-2">Banner</th>
                                        <th className="border border-[#aaaaaa] p-2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        watches.map((w, i) => (
                                            <tr key={i}>
                                                <td className="border border-[#aaaaaa] p-2">{i + 1}</td>
                                                <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                                <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                                <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                                <td className="border border-[#aaaaaa] p-2"><div className="w-fit m-auto">{banners.map(b => b.id).includes(w.id) ? <CheckIcon fill="#008800" /> : <XIcon fill="#dd0000" />}</div></td>
                                                <td className="border border-[#aaaaaa] p-2"><button className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]" onClick={() => handleCandidateModal(w)}>Ver</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        : null
                }

                <div>

                </div>
            </div>
            {
                candidateModal && candidateWatch ?
                    <CandidateWatchModal watch={candidateWatch} close={() => setCandidateModal(false)} handleBanner={handleBanner} banners={banners.map(b => b.id)} refresh={doRefresh} />
                    : null
            }
            {
                bannersModal ?
                    <AdminModal close={() => setBannersModal(false)}>
                        <div className="mt-4">
                            <h2 className="text-center text-lg font-bold">Banners em exibição</h2>
                            {
                                !reorder ?
                                    <div className="w-fit m-auto">
                                        <button onClick={() => setReorder(true)} className="bg-[#5804ab] text-white px-4 py-1 hover:bg-[#6d03d6] active:bg-[#6d03d6]">Reordenar</button>
                                    </div>
                                    :
                                    <div className="w-fit m-auto flex gap-4">
                                        <button onClick={() => setReorder(false)} className="bg-[#000] text-white px-4 py-1 hover:bg-[#333] active:bg-[#333]">Cancelar</button>
                                    </div>
                            }
                            {
                                banners.map((b, i) => (
                                    <DisplayedBanner key={i} banner={b} reorder={reorder} refresh={getBanners}/>
                                ))
                            }
                        </div>
                    </AdminModal>
                    : null
            }
            <AdminToast toast={badToast} setToast={setBadToast} message="Nenhum relógio encontrado" color="#ff3333" />
        </main>
    )
}
