export default function GMTIcon({ width = "100px", fill = "#000" }: { width?: string, fill?: string }) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 400.000000 94.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,94.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
                <path d="M0 470 l0 -470 2000 0 2000 0 0 470 0 470 -2000 0 -2000 0 0 -470zm3930 0 l0 -400 -1930 0 -1930 0 0 400 0 400 1930 0 1930 0 0 -400z" />
                <path d="M550 687 c-48 -15 -126 -95 -139 -143 -16 -58 -13 -114 10 -164 42 -92 107 -134 209 -134 129 0 230 93 230 214 l0 40 -115 0 -115 0 0 -35 0 -35 80 0 c44 0 80 -3 80 -6 0 -21 -45 -73 -80 -93 -140 -78 -298 79 -219 218 34 59 64 76 140 76 56 0 71 -4 102 -28 29 -22 46 -27 74 -25 l38 3 -40 44 c-22 24 -58 52 -80 62 -45 21 -121 23 -175 6z" />
                <path d="M1200 470 l0 -230 35 0 34 0 3 146 3 146 81 -95 81 -95 79 95 79 94 3 -145 3 -146 34 0 35 0 0 230 c0 143 -4 230 -10 230 -5 0 -45 -41 -87 -92 -43 -51 -92 -107 -108 -126 l-30 -33 -105 125 c-58 69 -111 125 -117 126 -10 0 -13 -52 -13 -230z" />
                <path d="M1932 668 l3 -33 98 -3 97 -3 0 -194 0 -195 35 0 35 0 0 195 0 195 100 0 100 0 0 35 0 35 -236 0 -235 0 3 -32z" />
                <path d="M3242 668 l3 -33 103 -3 102 -3 -60 -72 -61 -72 58 -6 c75 -8 91 -15 108 -50 23 -43 18 -77 -14 -110 -46 -45 -117 -35 -146 21 -13 25 -22 30 -50 30 -30 0 -35 -3 -35 -22 0 -33 35 -85 72 -108 66 -40 143 -36 203 12 33 26 64 91 65 134 0 47 -41 116 -83 140 l-37 20 60 72 c33 39 60 73 60 76 0 3 -79 6 -176 6 l-175 0 3 -32z" />
                <path d="M2670 470 l0 -30 115 0 115 0 0 30 0 30 -115 0 -115 0 0 -30z" />
            </g>
        </svg>

    )
}