import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import { db } from "../firebase";

export function randomGenerator() {
    const chars = [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3',
        '4', '5', '6', '7', '8', '9'
    ];

    let randomStr = "";
    for (let i = 0; i < 6; i++) {
        const choosen = chars[Math.floor(Math.random() * (chars.length - 1))]
        randomStr += choosen;
    }

    return randomStr;
}

export async function collectionHasDoc(colName: string, field: string, value: string) {
    try {
        const col = collection(db, colName);
        const q = query(col, where(field, "==", value));
        const documents = await getDocs(q);
        return !documents.empty;
    } catch (error) {
        console.log(error);
    }
}

export function formatPhoneNumber(phoneNumber: string | undefined) {
    if (!phoneNumber) {
        return phoneNumber
    }
    if (phoneNumber?.length === 11) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7)}`;
    }
    if (phoneNumber?.length === 10) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6)}`;
    }
    else {
        return `(${phoneNumber?.slice(0, 2)}) ${phoneNumber?.slice(2)}`;
    }
}

export function parseTimestamp(time: Date) {
    if (!time) {
        return null;
    }
    const day = String(time.getDate()).padStart(2, '0');
    const month = String(time.getMonth() + 1).padStart(2, '0');
    const year = time.getFullYear();

    return `${day}/${month}/${year}`;
}

export function parseFirebaseTimestamp(time: Timestamp){
    if(!time){
        return "N/A";
    }
    const date = new Date(time.seconds*1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export function createDate(dateString: string){
    const parts = dateString.split("/");
    const month = parseInt(parts[1]) - 1;
    return new Date(parseInt(parts[2]), month, parseInt(parts[0]));
}

export function calculateRate(reviews: { rate: number }[]) {
    if (!reviews.length) {
        return undefined;
    }
    const sum = reviews.reduce((tot, item) => tot + item.rate, 0);
    return sum / reviews.length;
}

export function getURLParams(url: string) {
    const params = new URLSearchParams(new URL(url).search);
    let paramObj: { [key: string]: string } = {};

    params.forEach((value, key) => {
        paramObj[key] = value;
    });

    return paramObj;
};

export function getAbsoluteUrl(url: string) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
};
