import { useEffect, useState } from "react";
import ChevronDownIcon from "../../img/svg/chevronDownIcon";
import Banner from "./banner";
import Bannerzin from "./bannerzin";
import Bannerzito from "./bannerzito";

export default function BannersCase({ banners, isMobile }: { banners: {}[], isMobile: boolean }) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [wait, setWait] = useState(false);
    const [startX, setStartX] = useState<number | null>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            if (banners.length <= 2 && !isMobile) {
                return null;
            }
            if (wait) {
                setWait(false);
            }
            else {
                if (currentIndex >= banners.length - 1) {
                    setCurrentIndex(0);
                    return null;
                }
                setCurrentIndex(currentIndex + 1);
            }
        }, 5000);

        return () => clearInterval(timer);
        // eslint-disable-next-line 
    }, [currentIndex, wait]);

    const handleNext = () => {
        if (currentIndex >= banners.length - 1) {
            setCurrentIndex(0);
            return null;
        }
        setCurrentIndex(currentIndex + 1);
        setWait(true);
    }

    const handlePrev = () => {
        if (currentIndex <= 0) {
            setCurrentIndex(banners.length - 1);
            return null;
        }
        setCurrentIndex(currentIndex - 1);
        setWait(true);
    }

    const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        const clientX = e.type === "touchstart"
            ? (e as React.TouchEvent<HTMLDivElement>).touches[0].clientX
            : (e as React.MouseEvent<HTMLDivElement>).clientX;
        setStartX(clientX); // Armazena a posição inicial
    };

    // Função que será disparada ao finalizar o drag
    const handleDragEnd = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        const clientX = e.type === "touchend"
            ? (e as React.TouchEvent<HTMLDivElement>).changedTouches[0].clientX
            : (e as React.MouseEvent<HTMLDivElement>).clientX;
        if (startX !== null) {
            if (clientX > startX) {
                handlePrev();
            } else if (clientX < startX) {
                handleNext();
            }
        }
        setStartX(null); // Reseta a posição inicial
    };

    const handleIndex = (index: number, position: number) => {
        const maxIndex = banners.length - 1;
        const result = index + position;
        if (result > maxIndex) {
            return Math.abs(banners.length - result);
        }
        if(result < 0){
            return banners.length + result;
        }
        return result;
    }

    return (
        // <div className="w-[80%] m-auto border border-moninho relative py-4 bg-moninho flex justify-center shadow-lg">
        <div className="w-[80%] m-auto relative py-4 flex justify-center">
            <div className="h-[365px] w-full overflow-hidden"
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
            >
                {
                    banners.length > 2 ?
                        <div className="flex justify-center items-center space-x-2">
                            {
                                banners.length > 4 ?
                                <Bannerzito onClick={() => setCurrentIndex(handleIndex(currentIndex, -2))} banner={banners[handleIndex(currentIndex, -2)]}/>
                                : null
                            }
                            <Bannerzin onClick={() => setCurrentIndex(handleIndex(currentIndex, -1))} banner={banners[handleIndex(currentIndex, -1)]} />
                            <Banner banner={banners[currentIndex]} />
                            <Bannerzin onClick={() => setCurrentIndex(handleIndex(currentIndex, 1))} banner={banners[handleIndex(currentIndex, 1)]} />
                            {
                                banners.length > 3 ?
                                <Bannerzito onClick={() => setCurrentIndex(handleIndex(currentIndex, 2))} banner={banners[handleIndex(currentIndex, 2)]}/>
                                : null
                            }
                        </div>
                        : 
                        <div className="flex justify-evenly items-center">
                            <Banner banner={banners[currentIndex]} />
                            {
                                banners.length > 1 && !isMobile?
                                <Banner banner={banners[handleIndex(currentIndex, 1)]} />
                                : null
                            }
                        </div>
                }
            </div>
            {
                (!isMobile && banners.length < 3) || banners.length === 1 ?
                    null
                    :
                    <div>
                        <button onClick={handlePrev} className="transform rotate-90 absolute -left-8 top-[130px] bg-white rounded-full border border-mono p-2 z-[5]"><ChevronDownIcon /></button>
                        <button onClick={handleNext} className="transform -rotate-90 absolute -right-8 top-[130px] bg-white rounded-full border border-mono p-2 z-[5]"><ChevronDownIcon /></button>
                    </div>
            }
        </div>
    )
}
