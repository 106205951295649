import { useEffect } from "react"

export default function AdminToast({ toast, setToast, color="#3333ff", message }: { toast: boolean, setToast: (t: boolean) => void, color?: string, message: string }) {

    useEffect(() => {
        const timeout = setTimeout(() => {
            setToast(false);
        }, 3000)
        return () => clearTimeout(timeout);
        // eslint-disable-next-line 
    }, [toast])

    return (
        toast ?
        <div className="border border-[#ccc] font-bold fixed top-0 right-0 m-2 rounded animate-sprout flex items-center justify-center overflow-hidden"
            style={{backgroundColor: color}}
        >
            <h4 className="text-center text-[#fff]">{message}</h4>
        </div>
        : null
    )
}
