import { Link, useNavigate } from "react-router-dom";
import PlusIcon from "../../../img/svg/plusIcon";
import XIcon from "../../../img/svg/xIcon";
import { useAuth } from "../../../context/authProvider";
import { useWatches } from "../../../context/watchProvider";
import { useState } from "react";
import LoadingBalls from "../../core/loadingBalls";

export default function Watch({ watch, isCart }: { watch: { [key: string]: any; id: string; }; isCart: boolean | undefined }) {

    const navigate = useNavigate();
    const { userId } = useAuth();
    const { addToCart, removeFromCart } = useWatches();
    const [loading, setLoading] = useState(false);

    const handleCart = async () => {
        if (!userId) {
            navigate("/gatekeeper");
            return null;
        }
        setLoading(true);
        if (isCart) {
            await removeFromCart(userId, watch.id);
            setLoading(false);
            return null;
        }
        await addToCart(userId, watch.id);
        setLoading(false);
    }

    return (
        <div className="flex p-2 min-w-[400px] h-fit bg-white rounded items-center">
            <div className="w-[150px] h-[150px] border border-mono flex items-center justify-center">
                <Link to={`${watch.slug}`} className="h-full w-full"><img src={watch.cImgUrl} className="h-full object-cover w-full" alt="display" /></Link>
            </div>
            <div className="flex-1">
                <div>
                    <h3 className="text-lg text-center">{watch.brand.toUpperCase()}</h3>
                    <h3 className="text-xl text-center font-bold -mt-2">{watch.model.toUpperCase()}</h3>
                    <h4 className="text-md text-center -mt-2">{watch.year}</h4>
                </div>
                <p className="text-center text-md">R${watch.price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                <div className="flex justify-center items-center mt-4 gap-4">
                    <button onClick={() => navigate(watch.slug)} className="text-sm bg-secondary text-white px-4 py-1 active:opacity-70">MAIS INFORMAÇÕES</button>
                    {
                        loading ?
                            <div className="w-fit">
                                <LoadingBalls width={50} />
                            </div>
                            :
                            <button onClick={handleCart} className="text-sm text-white active:opacity-70">
                                {
                                    isCart ?
                                        <div title="Remover da minha lista" className="bg-primary px-2 py-1">
                                            <XIcon fill="white" width="20px" />
                                        </div>
                                        :
                                        <div title="Adicionar à minha lista" className="bg-secondary px-2 py-1">
                                            <PlusIcon fill="white" width="20px" />
                                        </div>
                                }
                            </button>
                    }


                </div>
            </div>
        </div>
    )
}
