import { useEffect, useState } from "react";
import { useWatches } from "../../../context/watchProvider";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import LoadingBalls from "../../core/loadingBalls";
import Bannerzin from "../../core/bannerzin";
import { useNavigate } from "react-router-dom";

export default function BrandsGrid({ isMobile }: { isMobile: boolean }) {

    const { top10 } = useWatches();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState<{ brand: string; count: number; }[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
    const [brandWatches, setBrandWatches] = useState<{ id: string, slug: string; }[]>([]);
    const [bannerBrandWatches, setBannerBrandWatches] = useState<{ id: string, slug: string; }[]>([]);

    useEffect(() => {
        getBrands();
        // eslint-disable-next-line
    }, [top10]);

    useEffect(() => {
        getBrandWatches();
        // eslint-disable-next-line
    }, [selectedBrand]);


    const getBrands = async () => {
        try {
            setLoading(true);
            if (!top10?.length) {
                setLoading(false);
                return null;
            }

            const top10Ids = top10?.map(t => t.watch);
            const watches = await getDocs(query(collection(db, "watches"), where("__name__", "in", top10Ids)));
            if (watches.empty) {
                return null;
            }
            const docs = watches.docs;
            const brandsInfo: { brand: string, count: number }[] = [];
            for (let i = 0; i < docs.length; i++) {
                const docao = brandsInfo.filter(b => b.brand === docs[i].data().brand);
                const count = top10?.filter(t => t.watch === docs[i].id)[0]?.count;
                if (!count) {
                    continue;
                }
                if (!docao.length && count) {
                    brandsInfo.push({
                        brand: docs[i].data().brand,
                        count: top10?.filter(t => t.watch === docs[i].id)[0]?.count
                    })
                } else {
                    brandsInfo[0].count += top10?.filter(t => t.watch === docs[i].id)[0]?.count
                }
            }
            const brandzadas = brandsInfo.sort((a, b) => b.count - a.count).slice(0, 4);
            setBrands(brandzadas);
            setSelectedBrand(brandzadas[0].brand);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const getBrandWatches = async () => {
        try {
            if (!selectedBrand) {
                return null;
            }
            setLoading(true);
            const watches = await getDocs(query(collection(db, "watches"), where("brand", "==", selectedBrand), where("status", "==", "display"), limit(20)));
            if (watches.empty) {
                setLoading(false);
                return null;
            }
            const brandebuks = watches.docs.map(doc => ({
                id: doc.id,
                status: doc.data().status,
                brand: doc.data().brand,
                model: doc.data().model,
                year: doc.data().year,
                price: doc.data().price,
                slug: doc.data().slug,
                images: doc.data().images,
                createdAt: doc.data().createdAt,
                isBanner: doc.data().isBanner
            }));
            setBannerBrandWatches(brandebuks.filter(b => b.isBanner))
            setBrandWatches(brandebuks.filter(b => !b.isBanner));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="m-4 border border-mono rounded bg-[#eee] overflow-hidden">
            <h3 className="border-b border-mono text-lg font-bold py-2 pl-4 text-center">MARCAS MAIS ACESSADAS</h3>
            <div className="flex flex-wrap justify-center mt-2 m-auto gap-4">
                {
                    brands.map((b, i) => (
                        <div className="text-sm md:text-base border border-mono rounded w-fit font-bold cursor-pointer px-10 py-4 text-center"
                            key={i}
                            style={{ backgroundColor: b.brand === selectedBrand ? "#ccc" : "transparent" }}
                            onClick={() => setSelectedBrand(b.brand)}
                        >
                            {b.brand.toUpperCase()}
                        </div>
                    ))
                }
            </div>
            {
                loading ?
                    <div className="h-[280px] flex items-center justify-center"><LoadingBalls /></div>
                    :
                    <div>
                        <div className="p-2 flex gap-2 overflow-x-scroll">
                            {
                                bannerBrandWatches.map((w, i) => (
                                    <Bannerzin sponsor key={i} banner={w} onClick={() => navigate(`watches/${w.slug}`)} overlay={false} />
                                ))
                            }
                            {
                                brandWatches.map((w, i) => (
                                    <Bannerzin key={i} banner={w} onClick={() => navigate(`watches/${w.slug}`)} overlay={false} />
                                ))
                            }
                        </div>
                    </div>
            }
        </div>
    )
}
