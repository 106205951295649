import { useEffect, useState } from "react";
import Watch from "./watch";
import { useWatches } from "../../../context/watchProvider";
import LoadingBalls from "../../core/loadingBalls";
import { useAuth } from "../../../context/authProvider";
import Filters from "./filters";
import FilterIcon from "../../../img/svg/filterIcon";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export default function Watches({ isMobile }: { isMobile: boolean }) {

    const { searchWatches, cartUpdated, getCartItems, cart } = useWatches();
    const { userId } = useAuth();

    const [watches, setWatches] = useState<{ [key: string]: any; id: string; }[] | [] | null>([]);
    const [total, setTotal] = useState(0);
    const [last, setLast] = useState<QueryDocumentSnapshot<DocumentData, DocumentData>>();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [query, setQuery] = useState<{ filters: any, price: any, year: any }>({ filters: [], year: null, price: null });

    useEffect(() => {
        doSearchWatches();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!cartUpdated && userId) {
            getCartItems(userId);
        }
        // eslint-disable-next-line
    }, [cartUpdated]);

    useEffect(() => {
        setModal(false);
    }, [watches])

    const doSearchWatches = async () => {
        setLoading(true);
        const foundWatches = await searchWatches([]);
        if (foundWatches?.data) {
            setWatches(foundWatches.data);
            setTotal(foundWatches.total);
            setLast(foundWatches.last);
        }
        setLoading(false);
    }

    const loadMore = async () => {
        if (!watches) {
            return null;
        }
        setLoadingMore(true);
        const foundWatches = await searchWatches(query.filters, query.price, query.year, last);
        if (foundWatches?.data) {
            setWatches([...watches, ...foundWatches.data]);
            setTotal(foundWatches.total);
            setLast(foundWatches.last);
        }
        setLoadingMore(false);
    }

    return (
        <main className="flex flex-col md:flex-row md:px-10 py-10 mb-16 gap-4 items-start">
            {
                isMobile ?
                    <div onClick={() => setModal(true)} className="px-4 py-2 bg-[#D3D3D3] w-fit m-auto rounded flex items-center gap-4 cursor-pointer">
                        <p className="">Refinar busca</p>
                        <FilterIcon fill="#333" />
                    </div>
                    :
                    <Filters loading={loading} setLoading={setLoading} setLoadingMore={setLoadingMore} setWatches={setWatches} setLast={setLast} setTotal={setTotal} setQuery={setQuery} />
            }
            <div className="flex-1 w-fit mx-auto">
                <div className="w-full h-fit grid grid-cols-1 xl:grid-cols-2 gap-4 flex-1 px-4 py-4 rounded bg-[#D3D3D3] m-auto md:m-0" style={{ display: loading || !watches?.length ? "block" : "grid" }}>
                    {
                        loading ?
                            <div className="w-fit m-auto"><LoadingBalls /></div>
                            :
                            !watches?.length ?
                                <p className="w-fit m-auto">Nenhum resultado encontrado</p>
                                :
                                watches?.map((w, i) => (
                                    <Watch key={i} watch={w} isCart={cart?.includes(w.id)} />
                                ))
                    }



                </div>
                {
                    watches?.length !== total && !query.price && !query.year ?
                        <div className="flex justify-center bg-[#ddd] border border-mono px-4 py-2 w-fit m-auto mt-4">
                            {
                                loadingMore ?
                                    <div className="py-2">
                                        <LoadingBalls />
                                    </div>
                                    :
                                    <button className="" disabled={loadingMore} onClick={loadMore}>Carregar mais</button>
                            }

                        </div>
                        : null
                }
            </div>

            {
                modal ?
                    <div onClick={() => setModal(false)} className="fixed z-10 top-0 left-0 bg-[#00000088] w-full h-full overflow-auto px-4 md:px-0">
                        <div onClick={e => e.stopPropagation()} className="w-fit m-auto mt-2 py-2 rounded relative">
                            <Filters loading={loading} setLoading={setLoading} setLoadingMore={setLoadingMore} setWatches={setWatches} setLast={setLast} setTotal={setTotal} setQuery={setQuery} />
                        </div>
                    </div>
                    : null
            }
        </main>
    )
}
