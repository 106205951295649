import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { IVideo } from "./adminHomeConfigs";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { getURLParams } from "../../../../utils/functions";

export default function ConfigVideoModal({ close, video, refresh, goodToast, badToast }: { close: () => void, video: IVideo | null, refresh: () => void, goodToast: () => void, badToast: () => void }) {

    const [link, setLink] = useState(video?.link || "");
    const [description, setDescription] = useState(video?.description || "");
    const [title, setTitle] = useState(video?.title || "");
    const [preview, setPreview] = useState(false);
    const [videoId, setVideoId] = useState("");
    const [loading, setLoading] = useState(false);

    const saveVideo = async () => {
        try {
            if (!link || !description) {
                return null;
            }
            setLoading(true);
            setPreview(false);
            if (!video) {
                await addDoc(collection(db, "videos"), {
                    link,
                    title,
                    description,
                    active: false
                })
                setLoading(false);
                refresh();
                goodToast();
                close();
                return null;
            }

            const docRef = doc(db, "videos", video.id);
            await updateDoc(docRef, { link, title, description });
            setLoading(false);
            refresh();
            goodToast();
            close();
            return true;
        } catch (error) {
            console.log(error);
            badToast();
            setLoading(false);
            return null;
        }
    }

    const previewVideo = () => {
        setVideoId(getURLParams(link)?.v);
        setPreview(true);
    }

    const isDisabled = (link === video?.link && description === video?.description && video?.title === title ) || link === "" || description === "";

    return (
        <AdminModal close={close}>
            <h2 className="text-xl text-center mb-6">Configuração do vídeo</h2>
            <div className="flex flex-col px-10">
                <h3 className="text-center">Link</h3>
                <input className="border border-mono outline-none text-center px-2 py-1" type="text" value={link} onChange={e => setLink(e.target.value)} />
                <h3 className="text-center mt-4">Título</h3>
                <input className="border border-mono outline-none text-center px-2 py-1" type="text" value={title} onChange={e => setTitle(e.target.value)} />
                <h3 className="text-center mt-4">Descrição</h3>
                <textarea className="resize-none border border-mono outline-none px-2 py-1 mb-4" value={description} onChange={e => setDescription(e.target.value)}></textarea>
                <button onClick={previewVideo} disabled={isDisabled} className="bg-black active:bg-[#333] hover:bg-[#333] disabled:bg-mono text-white px-4 py-1 w-fit m-auto">Visualizar</button>

                {
                    preview ?
                        <div>
                            <h3 className="text-center mt-4">Pré-visualização</h3>
                            <iframe
                                className="m-auto mt-4"
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${videoId}`} //?si=1vb3Or9oOKrGmx_q
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                            {
                                loading ?
                                    <ClockAnimation />
                                    :
                                    <button onClick={saveVideo} disabled={isDisabled} className="bg-[#00bb00] active:bg-[#88bb88] hover:bg-[#88bb88] disabled:bg-mono text-white px-4 py-1 w-fit m-auto mt-4">Salvar</button>
                            }
                        </div>
                        : null
                }
            </div>
        </AdminModal>
    )
}
