import { useEffect, useState } from "react";
import { useAuth } from "../../../context/authProvider";
import { useWatches } from "../../../context/watchProvider";
import { useNavigate } from "react-router-dom";
import LoadingBalls from "../../core/loadingBalls";

export default function MyList() {

    const { userId } = useAuth();
    const { cart, cartUpdated, getCartItems, getCartWatches } = useWatches();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [cartWatches, setCartWatches] = useState<{ [key: string]: string, id: string }[] | null>([]);

    useEffect(() => {
        if (!cartUpdated && userId) {
            getCartItems(userId);
        }
        handleCartWatches();
        // eslint-disable-next-line
    }, [cartUpdated])

    const handleCartWatches = async () => {
        if (!cart?.length) {
            return null;
        }
        setLoading(true);
        const watches = await getCartWatches(cart);
        if (watches?.length) {
            setCartWatches(watches);
        }
        setLoading(false);
    }

    // const doRemoveFromCart = async (watchId: string) => {
    //     if (!userId) {
    //         return null;
    //     }
    //     setLoading(true);
    //     await removeFromCart(userId, watchId);
    //     setLoading(false)
    // }

    return (
        <div className="text-[#939393] w-full border border-mono rounded">
            <h2 className="text-xl border-b border-mono text-center p-2">Minha Lista</h2>
            {
                !cart?.length ?
                    <p className="mt-4 text-center">Nenhum item adicionado</p>
                    :
                    <div className="flex gap-4 mt-2 flex-wrap p-2 justify-evenly md:justify-start">
                        {
                            loading ?
                                <div className="w-fit m-auto"><LoadingBalls /></div>
                                :
                                cartWatches?.map((w, i) => (
                                    <div key={i} className="border border-mono w-[150px] overflow-x-hidden">
                                        <div className="relative h-[150px]">
                                            <img src={w.cImgUrl} alt="thumb" className="h-full object-cover" />
                                            <div className="text-center text-black absolute bottom-0 left-0 w-full p-2">
                                                <p className="bg-moninho whitespace-nowrap rounded">R${parseFloat(w.price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </div>
                                        <div className="text-center text-black bg-moninho py-1">
                                            <p className="whitespace-nowrap my-1"><strong>{w.brand.toUpperCase()}</strong> {w.year}</p>
                                            <div className="flex gap-2 m-auto w-fit">
                                                <button onClick={() => navigate(`/watches/${w.slug}`)} className="text-sm bg-primary text-white px-4 py-1 active:opacity-70">VER</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
            }
        </div>
    )
}