import { useState } from "react";
import AdminToast from "../../../core/admin/adminToast";
import { useAuth } from "../../../../context/authProvider";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import InstagramIcon from "../../../../img/svg/instagramIcon";
import SiteIcon from "../../../../img/svg/siteIcon";
import { getAbsoluteUrl } from "../../../../utils/functions";
import WhatsappIcon from "../../../../img/svg/whatsappIcon";
import { Link } from "react-router-dom";

export default function MyContact() {

    const { user, updateUserContact } = useAuth();

    const [loading, setLoading] = useState(false);

    const [desc, setDesc] = useState(user?.description || "");
    const [editDesc, setEditDesc] = useState(false);

    const [insta, setInsta] = useState(user?.instagram || "");
    const [editInsta, setEditInsta] = useState(false);

    const [site, setSite] = useState(user?.site || "");
    const [editSite, setEditSite] = useState(false);

    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const handleField = (setField: React.Dispatch<React.SetStateAction<string>>, value: string) => {
        if (value.length < 128) {
            setField(value);
        }
    }
    const handleDesc = (value: string) => {
        if (value.length < 236) {
            setDesc(value);
        }
    }

    const doEditDesc = async () => {
        setLoading(true);
        const updateAction = await updateUserContact("description", desc);
        if (!updateAction) {
            setLoading(false);
            setBadToast(true);
            return null;
        }
        setEditDesc(false);
        setLoading(false);
        setGoodToast(true);
    }

    const doEditInsta = async () => {
        setLoading(true);
        const updateAction = await updateUserContact("instagram", insta);
        if (!updateAction) {
            setLoading(false);
            setBadToast(true);
            return null;
        }
        setEditInsta(false);
        setLoading(false);
        setGoodToast(true);
    }

    const doEditSite = async () => {
        setLoading(true);
        
        const updateAction = await updateUserContact("site", handleLink(site));
        if (!updateAction) {
            setLoading(false);
            setBadToast(true);
            return null;
        }
        setEditSite(false);
        setLoading(false);
        setGoodToast(true);
    }

    const handleLink = (link: string) => {
        if(!link.includes("https://") && !link.includes("http://")){
            return "https://"+link;
        }
        return link;
    }

    return (
        <main className="p-4">
            <h1 className="text-xl text-center font-bold">Meu contato</h1>
            {
                loading ?
                    <div className="bg-white px-4 py-10 mt-2">
                        <ClockAnimation />
                    </div>
                    :
                    <div className="bg-white px-4 py-10 mt-2">
                        {/* DESCRIÇÃO */}
                        <div className="border border-mono bg-moninho p-2 relative w-full m-auto mb-4">
                            <h3 className="text-center text-lg font-bold">Descrição</h3>
                            <p className="text-center mb-4">Esta descrição estará visível na sua <a href={`/advertisers/${user?.slug}`} target="_blank" rel="noreferrer" className="text-secondary">página do anunciante</a>.</p>
                            <textarea disabled={!editDesc} className="resize-none border border-mono w-full p-2 outline-none" value={desc} onChange={e => handleDesc(e.target.value)} />
                            {
                                editDesc ?
                                    <div className="flex w-fit gap-4 m-auto mt-4">
                                        <button onClick={() => setEditDesc(false)} className="flex-1 px-2 py-1 border border-primary bg-moninho rounded">Cancelar</button>
                                        <button disabled={desc === user?.description} onClick={doEditDesc} className="disabled:bg-moninho flex-1 px-2 py-1 bg-secondary text-white rounded">Alterar</button>
                                    </div>
                                    :
                                    <div className="block w-fit m-auto mt-4"><button onClick={() => setEditDesc(true)} className="px-2 py-1 border border-primary bg-moninho rounded">Alterar</button></div>
                            }
                        </div>
                        {/* REDES */}
                        <div className="w-fit flex flex-wrap items-center gap-4 m-auto">
                            {/* INSTAGRAM */}
                            <div className="border border-mono bg-moninho p-2 relative min-w-56 m-auto">
                                <div className="absolute top-2 right-2">
                                    <InstagramIcon fill="#333" />
                                </div>
                                <h3 className="text-center text-lg mb-4 font-bold">Instagram</h3>
                                {
                                    editInsta ?
                                        <div className="">
                                            <p className="inline">https://instagram.com/</p>

                                            <input disabled={!editInsta} className="disabled:bg-moninho border border-mono outline-none px-2 rounded text-center" placeholder="Instagram" type="text" value={insta} onChange={e => handleField(setInsta, e.target.value)} />
                                            <div className="flex w-fit gap-4 m-auto mt-4">
                                                <button onClick={() => setEditInsta(false)} className="flex-1 px-2 py-1 border border-primary bg-moninho rounded">Cancelar</button>
                                                <button disabled={insta === user?.instagram} onClick={doEditInsta} className="disabled:bg-moninho flex-1 px-2 py-1 bg-secondary text-white rounded">Alterar</button>
                                            </div>
                                        </div>
                                        :
                                        <div className="">
                                            {
                                                user?.instagram ?
                                                    <a href={`https://instagram.com/${user?.instagram}`} target="_blank" rel="noreferrer" className="text-center m-auto w-fit block text-secondary">{`https://instagram.com/${user?.instagram}`}</a>
                                                    : null
                                            }
                                            <div className="block w-fit m-auto mt-4"><button onClick={() => setEditInsta(true)} className="px-2 py-1 border border-primary bg-moninho rounded">Alterar</button></div>
                                        </div>
                                }
                            </div>
                            {/* FACEBOOK */}
                            <div className="border border-mono bg-moninho p-2 relative min-w-56 m-auto">
                                <div className="absolute top-2 right-2">
                                    <WhatsappIcon fill="#333" />
                                </div>
                                <h3 className="text-center text-lg mb-4 font-bold">WhatsApp</h3>

                                        <div className="">
                                            {
                                                user?.phoneNumber ?
                                                    <a href={`https://wa.me/55${user?.phoneNumber}`} target="_blank" rel="noreferrer" className="text-center m-auto w-fit block text-secondary">{`https://wa.me/55${user?.phoneNumber}`}</a>
                                                    : null
                                            }
                                            <div className="mt-4">
                                                <Link to="/me" className="px-2 py-1 border border-primary bg-moninho rounded block w-fit m-auto">Alterar</Link>
                                            </div>
                                        </div>

                            </div>
                            {/* SITE */}
                            <div className="border border-mono bg-moninho p-2 relative min-w-56 m-auto">
                                <div className="absolute top-2 right-2">
                                    <SiteIcon fill="#333" />
                                </div>
                                <h3 className="text-center text-lg mb-4 font-bold">Site</h3>
                                {
                                    editSite ?
                                        <div className="">
                                            <input disabled={!editSite} className="disabled:bg-moninho w-full border border-mono outline-none px-2 rounded text-center" placeholder="Site" type="text" value={site} onChange={e => handleField(setSite, e.target.value)} />
                                            <div className="flex w-fit gap-4 m-auto mt-4">
                                                <button onClick={() => setEditSite(false)} className="flex-1 px-2 py-1 border border-primary bg-moninho rounded">Cancelar</button>
                                                <button disabled={site === user?.site} onClick={doEditSite} className="disabled:bg-moninho flex-1 px-2 py-1 bg-secondary text-white rounded">Alterar</button>
                                            </div>
                                        </div>
                                        :
                                        <div className="">
                                            {
                                                user?.site ?
                                                    <a href={getAbsoluteUrl(user.site)} target="_blank" rel="noreferrer" className="text-center m-auto w-fit block text-secondary">{getAbsoluteUrl(user.site)}</a>
                                                    : null
                                            }
                                            <div className="block w-fit m-auto mt-4"><button onClick={() => setEditSite(true)} className="px-2 py-1 border border-primary bg-moninho rounded">Alterar</button></div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} color="#00bb00" message="Usuário atualizado!" />
            <AdminToast toast={badToast} setToast={setBadToast} color="#bb0000" message="Erro!" />
        </main>
    )
}
