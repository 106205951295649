import { useEffect, useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { IReview } from "./reviews";
import { useWatches } from "../../../../context/watchProvider";
import Review from "../../../core/review";
import { formatPhoneNumber } from "../../../../utils/functions";

export default function ReviewDetailsModal({ close, review, goodToast, badToast, refresh }: { close: () => void, review: IReview, refresh: () => Promise<null>, goodToast: () => void, badToast: () => void }) {

    const { getAdvertiser } = useWatches();

    const [loading, setLoading] = useState(false);
    const [advertiser, setAdvertiser] = useState<{ [key: string]: any; id: string; } | null>(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        doGetAdvertiser();
        // eslint-disable-next-line
    }, []);

    const doGetAdvertiser = async () => {
        setLoading(true);
        const adv = await getAdvertiser(review.advertiser);
        if (!adv) {
            setLoading(false);
            return null;
        }
        setAdvertiser(adv);
        setLoading(false);
    }

    const handleHideOperation = async () => {
        try {
            setLoading(true);

            const docRef = doc(db, "reviews", review.id);
            const hideValue = review.hidden ? false : true

            await updateDoc(docRef, { hidden: hideValue });
            setLoading(false);
            goodToast();
            await refresh();
        } catch (error) {
            console.log(error);
            badToast();
            setLoading(false);
        }
    }

    return (
        <AdminModal close={close}>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div className="">
                        <div className="border border-mono h-fit bg-white relative my-6">
                            <Review review={review} />
                            <div className="absolute top-2 left-2 flex gap-4">
                                <p className="font-bold text-lg" style={{ color: review.hidden ? "red" : "green" }}>{review.hidden ? "Escondida" : "Visível"}</p>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="border border-mono flex-1">
                                <h2 className="text-center text-lg bg-primary text-white">Sobre o anunciante</h2>
                                <div className="border rounded grid grid-cols-2">
                                    <p className="py-2 px-2 border-b bg-moninho font-bold">Nome</p>
                                    <p className="py-2 px-2 border-b bg-moninho">{advertiser?.name}</p>

                                    <p className="py-2 px-2 bg-white border-b font-bold">Email</p>
                                    <p className="py-2 px-2 bg-white border-b break-words">{advertiser?.email}</p>

                                    <p className="py-2 px-2 border-b bg-moninho font-bold">Telefone</p>
                                    <p className="py-2 px-2 border-b bg-moninho">{formatPhoneNumber(advertiser?.phoneNumber)}</p>
                                </div>
                            </div>
                            <div className="border border-mono flex-1 h-fit">
                                <h2 className="text-center text-lg bg-primary text-white">Sobre o relógio</h2>
                                <div className="border rounded grid grid-cols-2">
                                    <p className="py-2 px-2 border-b bg-white font-bold">ID</p>
                                    <p className="py-2 px-2 border-b bg-white">{review.watch}</p>
                                </div>
                            </div>
                        </div>
                        {
                            edit ?
                                <div>
                                    <p className="text-lg text-center font-bold my-4">{review.hidden ? "Deseja exibir essa avaliação?" : "Deseja esconder essa avaliação?"}</p>
                                    <div className="flex gap-4">
                                        <button onClick={handleHideOperation} className="flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] disabled:bg-mono py-1">Sim</button>
                                        <button onClick={() => setEdit(false)} className="flex-1 text-white bg-black hover:bg-[#333] active:bg-[#333] py-1">Cancelar</button>
                                    </div>
                                </div>
                                :
                                <button onClick={() => setEdit(true)} className="block m-auto mt-4 text-white bg-[#000] hover:bg-[#333] active:bg-[#333] px-2 py-1">{review.hidden ? "Exibir avaliação" : "Esconder avaliação"}</button>
                        }
                    </div>
            }
        </AdminModal>
    )
}
