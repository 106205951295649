import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useWatches } from "../../../context/watchProvider";
import LoadingBalls from "../../core/loadingBalls";
import { calculateRate, formatPhoneNumber, getAbsoluteUrl, parseTimestamp } from "../../../utils/functions";
import TemplateStars from "../../core/templateStars";
import Review from "../../core/review";
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";
import InstagramIcon from "../../../img/svg/instagramIcon";
import SiteIcon from "../../../img/svg/siteIcon";
import WhatsappIcon from "../../../img/svg/whatsappIcon";

export default function Advertiser() {

    const { slug } = useParams();
    const { getAdvertiserBySlug, getAdvertiserReviews, getAdvertiserWatches } = useWatches();

    const [loading, setLoading] = useState(false);
    const [advertiser, setAdvertiser] = useState<{ [key: string]: any } | null>(null);
    const [reviews, setReviews] = useState<{ rate: number, review: string, createdAt: Date }[] | null>(null);
    const [reviewIdx, setReviewIdx] = useState(0);
    const [watches, setWatches] = useState<{ [key: string]: any; id: string; }[]>([]);

    useEffect(() => {
        doGetAdvertiser();
        // eslint-disable-next-line
    }, [])

    const doGetAdvertiser = async () => {
        if (!slug) {
            return null;
        }
        setLoading(true);
        const adv = await getAdvertiserBySlug(slug);
        if (!adv) {
            setLoading(false);
            return null;
        }
        setAdvertiser(adv);
        const revs = await getAdvertiserReviews(adv.id);
        if (!revs) {
            setLoading(false);
            return null;
        }
        setReviews(revs.filter(r => !r.hidden));
        const wtcs = await getAdvertiserWatches(adv.id);
        if (!wtcs) {
            setLoading(false);
            return null;
        }
        setWatches(wtcs);
        setLoading(false);
    }

    const handleReviewPassage = (type: "prev" | "next") => {
        if (!reviews) {
            return null;
        }
        if (type === "next") {
            if (reviewIdx >= reviews.length - 1) {
                return null;
            }
            setReviewIdx(reviewIdx + 1);
        } else {
            if (reviewIdx <= 0) {
                return null;
            }
            setReviewIdx(reviewIdx - 1);
        }
    }

    if (loading) {
        return (
            <main className="h-screen">
                <div className="w-fit m-auto mt-10">
                    <LoadingBalls />
                </div>
            </main>
        )
    }

    if (!advertiser) {
        return (
            <main className="min-h-[80vh]">
                <div className="bg-primary text-white flex m-auto items-center gap-10 px-4 py-2">
                    <div className="border-b border-white w-20"></div>
                    <div className="flex-1 text-xl font-[600]"><p className="text-center">ANUNCIANTE</p></div>
                    <div className="border-b border-white w-20"></div>
                </div>
                <div>
                    <h1 className="text-center text-lg mt-4">Anunciante não encontrado!</h1>
                </div>
            </main>
        )
    }

    return (
        <main className="min-h-[70vh]">
            <div className="bg-primary text-white flex m-auto items-center gap-10 px-4 py-2">
                <div className="border-b border-white w-20"></div>
                <div className="flex-1 text-xl font-[600]"><p className="text-center">ANUNCIANTE</p></div>
                <div className="border-b border-white w-20"></div>
            </div>
            <div className="relative w-fit m-auto">
                <p className="text-center text-xl font-bold mt-4 w-full md:w-[700px] m-auto border border-b-0 p-4">{advertiser?.name}</p>
                <div className="grid grid-cols-[150px_1fr] border w-full md:w-[700px] m-auto">
                    <p className="p-2 border-b font-bold bg-moninho pl-4">Email</p>
                    <p className="p-2 border-b text-center bg-moninho">{advertiser?.email}</p>

                    <p className="p-2 border-b font-bold pl-4">Telefone</p>
                    <a className="p-2 border-b text-center w-fit m-auto text-secondary" href={`https://wa.me/55${advertiser?.phoneNumber}`} target="_blank" rel="noreferrer">{formatPhoneNumber(advertiser?.phoneNumber)}</a>

                    <p className="p-2 border-b font-bold bg-moninho pl-4">Desde</p>
                    <p className="p-2 border-b text-center bg-moninho">{parseTimestamp(advertiser?.createdAt)}</p>
                    {
                        advertiser?.description ?
                            <p className="p-2 font-bold pl-4">Descrição</p>
                            : null
                    }
                    {
                        advertiser?.description ?
                            <p className="p-2">{advertiser?.description}</p>
                            : null
                    }
                    {
                        reviews ?
                            <div className="absolute top-4 right-4"><TemplateStars width="30px" rate={calculateRate(reviews)} /></div>
                            : null
                    }
                </div>
                <div className="border border-t-0 p-4 flex justify-evenly items-center bg-moninho">
                    {
                        advertiser?.phoneNumber ?
                            <a href={`https://wa.me/55${advertiser?.phoneNumber}`} target="_blank" rel="noreferrer" className="rounded bg-white p-2">
                                <WhatsappIcon />
                            </a>
                            : null
                    }
                    {
                        advertiser?.instagram ?
                            <a href={`https://instagram.com/${advertiser?.instagram}`} target="_blank" rel="noreferrer" className="rounded bg-white p-2">
                                <InstagramIcon />
                            </a>
                            : null
                    }
                    {
                        advertiser?.site ?
                            <a href={`${getAbsoluteUrl(advertiser?.site)}`} target="_blank" rel="noreferrer" className="rounded bg-white p-2">
                                <SiteIcon />
                            </a>
                            : null
                    }
                </div>
            </div>
            {
                reviews ?
                    <div className="w-full md:w-[700px] m-auto mt-4">
                        <h2 className="text-lg text-center">Avaliações</h2>
                        <div className="flex justify-between items-center border border-moninho rounded mt-2">
                            <button onClick={() => handleReviewPassage("prev")} disabled={reviewIdx <= 0} className="transform rotate-90"><ChevronDownIcon fill={reviewIdx <= 0 ? "#b3b3b3" : "#000"} /></button>
                            <Review review={reviews[reviewIdx]} />
                            <button onClick={() => handleReviewPassage("next")} disabled={reviewIdx >= reviews.length - 1} className="transform -rotate-90"><ChevronDownIcon fill={reviewIdx >= reviews.length - 1 ? "#b3b3b3" : "#000"} /></button>
                        </div>
                    </div>
                    : null
            }
            {
                watches ?
                    <div>
                        <h2 className="text-lg text-center mt-6">Relógios do anunciante</h2>
                        <div className="flex p-1 w-fit md:max-w-[860px] m-auto gap-4 mt-2 flex-wrap">
                            {
                                watches.map((w, i) => (
                                    <div key={i} className="w-[200px] border border-mono">
                                        <Link to={`/watches/${w.slug}`} className="h-full">
                                            <div className="h-[230px] border border-mono">
                                                <img src={w.cImgUrl} className="h-full object-cover m-auto w-full" alt="display" />
                                            </div>
                                            <div className="py-2 text-primary">
                                                <p className="text-xs text-center break-words">{w.brand.toUpperCase()} / {w.year}</p>
                                                <h3 className="text-lg text-center font-bold text-primary break-words leading-none mt-1 mb-2">{w.model.toUpperCase()}</h3>
                                                <p className="text-sm text-center -mt-1">R${w.price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : null
            }
        </main>
    )
}
