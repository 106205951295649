import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { useWatches } from "../../../../context/watchProvider";
import { IWatch } from "../watches/adminWatches";
import AdminTextInput from "../../../core/admin/adminTextInput";
import AdminButton from "../../../core/admin/adminButton";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import { formatPhoneNumber } from "../../../../utils/functions";
import ClockAnimation from "../../../../img/svg/clockAnimation";

export default function AdvertisedWatches({ watches, refresh }: { watches: IWatch[], refresh: () => void }) {

    const { updateWatch } = useWatches();

    const [confirmModal, setConfirmModal] = useState(false);
    const [watch, setWatch] = useState<IWatch | null>(null);
    const [status, setStatus] = useState("");
    const [email, setEmail] = useState("");

    const [userLoading, setUserLoading] = useState(false);
    const [userFeedback, setUserFeedback] = useState("");
    const [foundUser, setFoundUser] = useState<{ id: string, name: string, email: string, phoneNumber: string } | null>(null);
    const [confirm, setConfirm] = useState(false);

    const openConfirmModal = (w: IWatch) => {
        setConfirm(false);
        setFoundUser(null);
        setUserFeedback("");
        setUserLoading(false);
        setEmail("");
        setWatch(w);
        setConfirmModal(true);
    }

    const doMoveWatch = async (watch: IWatch | null) => {
        if (!watch?.id) {
            return null;
        }
        const response = await updateWatch(watch.id, { status });
        if (response) {
            refresh();
        }
    }

    const doSellWatch = async (watch: IWatch | null, userId: string) => {
        if (!watch?.id) {
            return null;
        }
        const response = await updateWatch(watch.id, { status, buyer: userId, isBanner: false });
        if (response) {
            refresh();
        }
    }

    const searchByEmail = async () => {
        try {
            setUserLoading(true);
            setUserFeedback("");
            setFoundUser(null);
            const col = collection(db, "users");
            const q = query(col, where("email", "==", email));
            const documents = await getDocs(q);
            const document = documents.docs.find(d => d.data().email === email);
            if(!document?.exists()){
                setUserFeedback("Usuário não encontrado");
                setUserLoading(false);
                return null
            }
            const data = document?.data();
            if (data) {
                setFoundUser({ id: document.id, name: data.name, email: data.email, phoneNumber: data.phoneNumber });
            } else {
                setUserFeedback("Usuário não encontrado");
            }
            setUserLoading(false);
        } catch (error) {
            console.log(error)
            setUserLoading(false);
            setUserFeedback("Erro ao buscar usuário");
        }
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-10 mt-4">
            <h2 className="text-center font-bold text-lg mb-4">Em exibição</h2>
            {
                watches.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">Marca</th>
                                <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                <th className="border border-[#aaaaaa] p-2">Ano</th>
                                <th className="border border-[#aaaaaa] p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                watches.map((w, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                        <td className="border border-[#aaaaaa] p-2"><button className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]" onClick={() => openConfirmModal(w)}>Atualizar</button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p className="text-center">Nenhum relógio listado</p>
            }
            {
                confirmModal ?
                    <AdminModal close={() => setConfirmModal(false)}>
                        <h2 className="text-xl text-center mb-4">O que você deseja fazer com este relógio?</h2>
                        <div className="flex gap-4 justify-center text-lg">
                            <input type="checkbox" value="sold" id="sold" onChange={e => setStatus(e.target.value)} checked={status === "sold"} />
                            <label htmlFor="sold">Marcar como vendido</label>
                        </div>
                        {
                            status === "sold" ?
                                <div className="w-fit m-auto border-t border-b border-mono p-4">
                                    <p className="text-center text-md mb-2">Informe o comprador</p>
                                    <div className="flex gap-4 my-2 w-fit m-auto">
                                        <AdminTextInput placeholder="email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <AdminButton disabled={email === ""} onClick={searchByEmail}>Buscar</AdminButton>
                                    </div>
                                    <p className="text-red-500 text-center">{userFeedback}</p>
                                    {
                                        userLoading ?
                                            <ClockAnimation />
                                            : null
                                    }
                                    {
                                        foundUser ?
                                            <div>
                                                <div className="bg-moninho grid grid-cols-[100px_1fr] border w-full md:w-[700px] m-auto px-4">
                                                    <p className="p-2 border-b font-bold">Nome</p>
                                                    <p className="p-2 border-b text-center">{foundUser?.name}</p>
                                                    <p className="p-2 border-b font-bold">Email</p>
                                                    <p className="p-2 border-b text-center">{foundUser?.email}</p>
                                                    <p className="p-2 border-b font-bold">Telefone</p>
                                                    <p className="p-2 border-b text-center">{formatPhoneNumber(foundUser?.phoneNumber)}</p>
                                                </div>
                                                {
                                                    !confirm ?
                                                    <div className="w-fit m-auto mt-2">
                                                        <AdminButton onClick={() => setConfirm(true)}>Confirmar venda</AdminButton>
                                                    </div>
                                                    : 
                                                    <div className="w-fit m-auto">
                                                        <p className="text-md text-center font-bold my-2">Tem certeza?</p>
                                                        <div className="flex gap-4">
                                                            <button className="bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] p-2 text-white w-20" onClick={() => doSellWatch(watch, foundUser.id)}>Sim</button>
                                                            <button className="bg-[#ff3333] hover:bg-[#ff9999] active:bg-[#ff9999] p-2 text-white w-20" onClick={() => setConfirm(false)}>Cancelar</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                        }
                        <div className="flex gap-4 justify-center text-lg">
                            <input type="checkbox" value="hidden" id="hidden" onChange={e => setStatus(e.target.value)} checked={status === "hidden"} />
                            <label htmlFor="hidden">Esconder relógio</label>
                        </div>
                        <div className="flex gap-4 justify-center mt-10">
                            <button className="flex-1 text-white px-2 bg-[#00bb00] disabled:bg-[#ccc] hover:bg-[#88bb88] active:bg-[#00bb00] h-fit py-2" onClick={() => doMoveWatch(watch)} disabled={status !== "hidden"}>Salvar</button>
                            <button className="flex-1 text-white px-2 bg-[#ff3333] hover:bg-[#ff9999] active:bg-[#ff3333] h-fit py-2" onClick={() => setConfirmModal(false)}>Cancelar</button>
                        </div>
                    </AdminModal>
                    : null
            }
        </div>
    )
}
