import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../../firebase";
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";
import LoadingBalls from "../../core/loadingBalls";

interface IAd {
    id: string;
    until: Timestamp;
    link: string;
    imgUrl: string;
    mImgUrl: string;
    visible: boolean;
    order: number;
}

export default function Ads({ isMobile }: { isMobile: boolean }) {

    const [ads, setAds] = useState<IAd[]>([]);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [wait, setWait] = useState(false);

    useEffect(() => {
        doGetAds();
    }, []);

    useEffect(() => {
        const inter = setInterval(() => {
            if (!ads || !ads?.length) {
                return null;
            }
            if(wait){
                setWait(false);
            }
            else{
                if (current < ads.length - 1) {
                    setCurrent(c => c + 1);
                    return null;
                }
                if (current !== 0 && current === ads.length - 1) {
                    setCurrent(0);
                    return null;
                }
            }
        }, 5000);

        return () => {
            clearInterval(inter);
        }
    }, [ads, current, wait]);

    const doGetAds = async () => {
        try {
            setLoading(true);
            const docs: IAd[] = [];
            const q = query(collection(db, "ads"), where("visible", "==", true), where("until", ">=", new Date()));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setAds([]);
                setLoading(false);
                return null;
            }
            docsSnap.forEach(document => {
                const data = document.data();
                docs.push({
                    id: document.id,
                    until: data.until,
                    link: data.link,
                    imgUrl: data.imgUrl,
                    mImgUrl: data.mImgUrl,
                    visible: data.visible,
                    order: data.order
                })
            })
            setAds(docs);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const next = () => {
        if (!ads || !ads?.length) {
            return null;
        }
        if (current < ads.length - 1) {
            setCurrent(c => c + 1);
        }
        if (current !== 0 && current === ads.length - 1) {
            setCurrent(0);
        }
        setWait(true);
    }

    const previous = () => {
        if (!ads || !ads?.length) {
            return null;
        }
        if (current > 0) {
            setCurrent(c => c - 1);
        }
        if (current === 0 && ads.length > 1) {
            setCurrent(ads.length - 1);
        }
        setWait(true);
    }

    if (!loading && !ads?.length) {
        return null;
    }

    return (
        <div className="w-full h-[132px] overflow-hidden flex items-center relative bg-black">
            {
                ads.length > 1 ?
                    <div onClick={previous} className="transform rotate-90 absolute left-2 top-[35%] bg-[#ffffff44] rounded cursor-pointer">
                        <ChevronDownIcon width="40px" fill="#1F2429" />
                    </div>
                    : null
            }
            {
                ads.length > 1 ?
                    <div onClick={next} className="transform -rotate-90 absolute right-2 top-[35%] bg-[#ffffff44] rounded cursor-pointer">
                        <ChevronDownIcon width="40px" fill="#1F2429" />
                    </div>
                    : null
            }
            {
                loading ?
                    <div className="m-auto w-fit">
                        <LoadingBalls />
                    </div>
                    :
                    <a href={ads[current]?.link} target="_blank" rel="noreferrer" className="mx-auto">
                        <img src={isMobile ? ads[current]?.mImgUrl : ads[current]?.imgUrl} alt="ad" className="mx-auto select-none" />
                    </a>
            }
        </div>
    )
}
