import StarIcon from "./starIcon";

export default function ThreeStarsIcon({ width = "30px", fill = "#FFFFFF" }: { width?: string, fill?: string }) {
    return (
        <div className="relative">
            <div className="absolute top-0 -left-2">
                <StarIcon width={width} fill={fill} />
            </div>
            
            <div className="absolute top-0 -right-2">
                <StarIcon width={width} fill={fill} />
            </div>
            <div className="z-1 relative">
                <StarIcon width={width} fill={fill} />
            </div>
        </div>
    )
}
