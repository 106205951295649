import { useState } from "react";
import FileInput from "./fileInput";
import { IWatch } from "../../watches/adminWatches";
import { useWatches } from "../../../../../context/watchProvider";
import XIcon from "../../../../../img/svg/xIcon";
import { deleteObject, getStorage, ref } from "firebase/storage";
import ClockAnimation from "../../../../../img/svg/clockAnimation";

export default function ImagesUploader({ watch, update }: { watch: IWatch | null, update: () => void }) {

    const { updateWatch } = useWatches();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [deletion, setDeletion] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<number | null>(null);

    const maxImages = watch?.images?.length >= 5 || false;

    const uploadImage = async (imgUrl: string, cImgUrl: string) => {
        try {
            if (!watch?.id) {
                setUploadLoading(false);
                return null;
            }
            if (watch.images?.length >= 5) {
                setUploadLoading(false);
                return null;
            }
            let images: {}[] = [];
            if (watch?.images?.length) {
                images = [...watch.images];
            }
            images.push({
                imgUrl,
                cImgUrl,
                order: 0
            })
            await updateWatch(watch?.id, { images });
            update();
            setUploadLoading(false);
        } catch (error) {
            console.log(error);
            setUploadLoading(false);
        }
    }

    const deleteImage = async (imageIdx: number) => {
        if (!watch?.id || !watch?.images?.length) {
            return null;
        }
        setDeleteLoading(imageIdx);
        setDeletion(null);
        const storage = getStorage();
        // excluir imagens do relógio
        try {

            const url = watch.images[imageIdx].imgUrl;
            if (url) {
                const imageRef = ref(storage, url);
                await deleteObject(imageRef);
            }
        } catch (error) {
            console.log(error);
        }
        try {
            const cUrl = watch.images[imageIdx].cImgUrl;
            if (cUrl) {
                const cImageRef = ref(storage, cUrl);
                await deleteObject(cImageRef);
            }
        } catch (error) {
            console.log(error);
        }
        try {
            let newImages = watch.images.filter((w: IWatch, i: number) => i !== imageIdx);

            await updateWatch(watch.id, { images: newImages });
            update();
            setDeleteLoading(null);
        } catch (error) {
            console.log(error);
            setDeleteLoading(null);
        }

    }

    return (
        <div className="border border-mono rounded w-full p-4 flex flex-wrap gap-4">
            {
                watch?.images?.length ?
                    watch.images.map((w: IWatch, i: number) => (
                        <div key={i} className="w-32 h-32 border border-mono rounded flex items-center p-1 relative">
                            {
                                deleteLoading === i ?
                                    <ClockAnimation />
                                    : deletion === i ?
                                        <div className="flex flex-col justify-center gap-4 m-auto">
                                            <button onClick={() => deleteImage(i)} className="px-2 py-1 text-white bg-[#FF0000] active:bg-[#FF8888]">Excluir</button>
                                            <button onClick={() => setDeletion(null)} className="px-2 py-1 text-white bg-black active:bg-[#333333]">Cancelar</button>
                                        </div>
                                        :
                                        <div>
                                            <img src={w.cImgUrl} alt="relojo" />
                                            <button onClick={() => setDeletion(i)} title="Excluir" className="bg-red-500 absolute -top-2 -right-2 rounded"><XIcon width="20px" fill="#FFFFFF" /></button>
                                        </div>
                            }
                        </div>
                    ))
                    : null
            }
            {
                watch?.id && (!watch?.images?.length || watch?.images?.length < 5) ?
                    <FileInput watchId={watch.id} isDisabled={maxImages} saveImgUrls={uploadImage} loading={uploadLoading} setLoading={setUploadLoading} />
                    : null
            }

        </div>
    )
}
