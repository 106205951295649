import { useState } from "react";
import AdminButton from "../../../core/admin/adminButton";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import AdminToast from "../../../core/admin/adminToast";
import { useWatches } from "../../../../context/watchProvider";
import WatchModal from "./watchModal";
import { statusFields } from "../../../../utils/fields";

export interface IWatch {
    id: string;
    [key: string]: any;
}

export default function AdminWatches() {

    const { getWatches, getWatch } = useWatches();

    const [watches, setWatches] = useState<IWatch[] | []>([]);
    const [loading, setLoading] = useState(false);
    const [badToast, setBadToast] = useState(false);
    const [status, setStatus] = useState("draft");
    const [modal, setModal] = useState(false);
    const [editableWatch, setEditableWatch] = useState<IWatch | null>(null);
    const [searchParameter, setSearchParameter] = useState("status");
    const [serial, setSerial] = useState("");

    const doGetWatches = async () => {
        setLoading(true);
        let parameter = "";
        if (searchParameter === "id") {
            const watchResponse = await getWatch(serial);
            if (!watchResponse) {
                setLoading(false);
                setWatches([]);
                setBadToast(true);
                return null;
            }
            setWatches([watchResponse]);
            setLoading(false);
            return null;
        }
        if (searchParameter === "status") {
            parameter = status;
        }
        else if (searchParameter === "serial") {
            parameter = serial;
        }
        else {
            return null;
        }
        const watchesResponse = await getWatches(searchParameter, parameter);
        if (!watchesResponse) {
            setLoading(false);
            setWatches([]);
            setBadToast(true);
            return null;
        }
        setWatches(watchesResponse);
        setLoading(false);
    }

    const handleWatchModal = (w: IWatch) => {
        setEditableWatch(w);
        setModal(true);
    }

    return (
        <main className="p-4">
            <h1 className="text-center text-xl mb-4 font-bold">Relógios cadastrados</h1>
            <div className="bg-[#fafafa] w-full m-auto py-4 md:px-10 mt-4">
                <div className="w-fit m-auto mb-4 flex items-center gap-4">
                    <div className="flex gap-4">
                        <div className="flex gap-2 items-center">
                            <input type="radio" id="status" name="searchParameter" value="status" checked={searchParameter === "status"} onChange={e => setSearchParameter(e.target.value)} />
                            <label htmlFor="status">Status</label>
                        </div>
                        <div className="flex gap-2 items-center">
                            <input type="radio" id="serial" name="searchParameter" value="serial" checked={searchParameter === "serial"} onChange={e => setSearchParameter(e.target.value)} />
                            <label htmlFor="serial">Serial</label>
                        </div>
                        <div className="flex gap-2 items-center">
                            <input type="radio" id="id" name="searchParameter" value="id" checked={searchParameter === "id"} onChange={e => setSearchParameter(e.target.value)} />
                            <label htmlFor="id">ID</label>
                        </div>
                    </div>
                    {
                        searchParameter === "status" ?
                            <select className="border border-mono px-2 py-1 text-center outline-none" value={status} onChange={e => setStatus(e.target.value)}>
                                {
                                    Object.keys(statusFields).map((o, i) => (
                                        <option value={o} key={i}>{statusFields[o]}</option>
                                    ))
                                }
                            </select>
                            : ["serial", "id"].includes(searchParameter) ?
                                <div>
                                    <input className="border border-mono outline-none px-2 py-1 text-center" type="text" value={serial} onChange={e => setSerial(e.target.value)} />
                                </div>
                                : null
                    }
                    <div className="m-auto w-fit">
                        <AdminButton onClick={doGetWatches}>Listar</AdminButton>
                    </div>
                </div>
                {
                    watches.length ?
                        <table className="w-fit m-auto border">
                            <thead>
                                <tr>
                                    <th className="hidden md:table-cell border border-[#aaaaaa] p-2">ID</th>
                                    <th className="border border-[#aaaaaa] p-2">Marca</th>
                                    <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                    <th className="border border-[#aaaaaa] p-2">Ano</th>
                                    <th className="border border-[#aaaaaa] p-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    watches.map((wtc, idx) => (
                                        <tr key={idx}>
                                            <td className="hidden md:table-cell border border-[#aaaaaa] p-2">{wtc?.id}</td>
                                            <td className="border border-[#aaaaaa] p-2">{wtc?.brand}</td>
                                            <td className="border border-[#aaaaaa] p-2">{wtc?.model}</td>
                                            <td className="border border-[#aaaaaa] p-2">{wtc?.year}</td>
                                            <th className="border border-[#aaaaaa] p-2"><AdminButton onClick={() => handleWatchModal(wtc)}>Ver</AdminButton></th>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        :
                        <p className="text-center">Nenhum relógio listado</p>
                }
                {
                    loading ?
                        <ClockAnimation />
                        : null
                }
                <AdminToast message="Nenhum resultado encontrado" toast={badToast} setToast={setBadToast} color="#ff3333" />
                {
                    modal ?
                        <WatchModal close={() => setModal(false)} editableWatch={editableWatch} refresh={doGetWatches} />
                        : null
                }
            </div>
        </main>
    )
}
