export default function Clock2({ width, fill = "#000" }: { width?: string, fill?: string }) {
    return (
        <svg width={width} id="eOsBmzineSq1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
            <polygon fill={fill} points="0,-30 25.980762,15 -25.980762,15 0,-30" transform="matrix(-.003854-.389374 1.60278-.015864 126.130962 151.267523)" strokeWidth="0" />
            <ellipse rx="102.160216" ry="102.160216" transform="matrix(1.403743 0 0 1.403743 150 150)" fill="none" stroke={fill} strokeWidth="4" />
            <ellipse fill={fill} rx="3.904141" ry="3.904141" transform="matrix(3.037719 0 0 3.037719 150 150)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="translate(146.911563 13.083652)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(.851343 0.52461-.52461 0.851343 221.784816 32.903405)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(.00327 0.999995-.999995 0.00327 21.950859 146.870222)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(-.509925 0.860219-.860219-.509925 38.11626 88.162841)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(-.002212 0.999998-.999998-.002212 287.522246 146.922026)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="translate(146.911563 277.052569)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(.474719 0.880137-.880137 0.474719 270.233645 85.690971)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(-.544062 0.839045-.839045-.544062 271.719708 214.142395)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(-.878468 0.477801-.477801-.878468 216.825289 269.712572)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(.872918 0.487867-.487867 0.872918 84.722443 258.446734)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(.520814 0.85367-.85367 0.520814 37.212189 208.998972)" strokeWidth="0" />
            <rect fill={fill} width="6.176874" height="9.453746" rx="0" ry="0" transform="matrix(-.800746 0.599004-.599004-.800746 85.773095 38.632876)" strokeWidth="0" />
            <polygon fill={fill} points="0,-30 25.980762,15 -25.980762,15 0,-30" transform="matrix(.316356 0.073576-.564169 2.425772 159.398483 113.071649)" strokeWidth="0" />
        </svg>
    )
}