import { useState } from "react";
import XIcon from "../../../img/svg/xIcon";
import ReviewStars from "../../core/reviewStars";
import LoadingBalls from "../../core/loadingBalls";
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { useAuth } from "../../../context/authProvider";

export default function ReviewModal({ watch, close, refresh }: { watch: { [key: string]: any; id: string; }, close: () => void, refresh: () => void }) {

    const { userId } = useAuth();

    const [rate, setRate] = useState(5);
    const [review, setReview] = useState("");
    const [maxLen, setMaxLen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleReview = (value: string) => {
        if(value.length > 152){
            setMaxLen(true);
            return null;
        }
        setReview(value);
        setMaxLen(false);
    }

    const sendReview = async () => {
        try{
            setLoading(true);
            setError(false);
            const q = query(collection(db, "reviews"), where("watch", "==", watch.id));
            const docsSnap = await getDocs(q);
            if (!docsSnap.empty) {
                setError(true);
                return null;
            }
            await addDoc(collection(db, "reviews"), {
                userId,
                watch: watch.id,
                advertiser: watch.advertiser,
                rate,
                review,
                createdAt: serverTimestamp()
            })
            refresh();
            close();
            setLoading(false);
        }catch(error){
            console.log(error);
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div onClick={close} className="fixed z-10 top-0 left-0 bg-[#00000088] w-full h-full overflow-hidden px-4 md:px-0">
            <div onClick={e => e.stopPropagation()} className="w-full md:w-[700px] h-fit m-auto mt-10 pb-2 rounded bg-white relative">
                <div onClick={close} className="absolute top-1 right-1 cursor-pointer"><XIcon fill="#ffffff" /></div>
                <h1 className="bg-primary text-white text-center rounded-t py-2">Avalie sua compra</h1>
                <h2 className="text-center mt-4 text-xl text-primary">
                    {watch.brand.toUpperCase()} {watch.model.toUpperCase()} {watch.year}
                </h2>
                <div className="w-fit m-auto"><ReviewStars rate={rate} setRate={setRate}/></div>
                <p className="text-center mt-4">Escreva uma avaliação sobre a peça e o vendedor</p>
                <div className="w-fit m-auto mt-4">
                <textarea value={review} onChange={e => handleReview(e.target.value)} className="resize-none text-black px-1 border border-mono focus:outline-none w-[450px] h-[100px]"></textarea>
                </div>
                {
                    maxLen ?
                    <p className="text-red-500 text-center mb-2">Limite de caracteres atingido!</p>
                    : null
                }
                {
                    error ?
                    <p className="text-red-500 text-center mb-2">Não foi possível enviar a avaliação</p>
                    : null
                }
                {
                    loading ?
                    <div className="h-8 flex items-center justify-center">
                        <LoadingBalls/>
                    </div>
                    :
                    <div className="flex justify-center gap-4">
                    <button className="bg-primary px-2 py-1 text-white hover:opacity-70 active:opacity-70" onClick={close}>CANCELAR</button>
                    <button className="bg-secondary px-2 py-1 text-white hover:opacity-70 active:opacity-70" onClick={sendReview}>AVALIAR</button>
                </div>
                }
            </div>
        </div>
    )
}
