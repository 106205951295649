import { useState } from "react";
import LoadingBalls from "../../core/loadingBalls";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [returnMessage, setReturnMessage] = useState("");

    const doSend = async (email: string) => {
        try {
            setLoading(true);
            setReturnMessage("");
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email);
            setReturnMessage("Email enviado!");
        } catch (error) {
            console.log(error);
            setReturnMessage("O email não foi enviado.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h3 className="text-black text-center text-xl">Redefinição de senha</h3>
            <p className="text-center">Um email será enviado para o endereço abaixo com instruções para a redefinição de senha.</p>
            <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="EMAIL" className="border border-[#B3B3B3] text-center p-2 m-auto block mt-6" />
            <button disabled={email === ""} onClick={() => doSend(email)} className="border block m-auto bg-secondary text-white w-28 h-10 active:bg-[#aaaaaa] mt-6 disabled:bg-mono">{loading ? <div className="m-auto w-fit"><LoadingBalls width={80} color="#fff" /></div> : <p className="m-0">Enviar</p>}</button>
            <p className="text-center mt-6">{returnMessage}</p>
        </div>
    )
}
