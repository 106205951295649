import { parseTimestamp } from "../../utils/functions";
import TemplateStars from "./templateStars";

export default function Review({ review }: { review: { rate: number, review: string, createdAt: Date } }) {
    return (
        <div className="p-4 flex-1 relative">
            <div className="w-fit m-auto">
                <TemplateStars width="30px" rate={review?.rate} />
            </div>
            <p className="text-center mt-4 min-h-20">{review?.review}</p>
            <p className="absolute top-5 right-4">{parseTimestamp(review?.createdAt)}</p>
        </div>
    )
}
