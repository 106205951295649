export default function FAQIcon({ width="30px", fill="#000" }: { width?: string, fill?: string}) {
    return (
        <svg fill={fill} height={width} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M440.607,31.642H16.759C7.518,31.642,0,39.16,0,48.401v103.8c0,9.241,7.518,16.759,16.759,16.759h423.848 c9.241,0,16.759-7.518,16.759-16.759v-103.8C457.367,39.16,449.849,31.642,440.607,31.642z M112.45,152.741h-95.69 c-0.298,0-0.541-0.242-0.541-0.541v-103.8c0-0.298,0.242-0.541,0.541-0.541h95.69V152.741z M441.148,152.201 c0,0.298-0.242,0.541-0.541,0.541H128.668V47.86h311.939c0.297,0,0.541,0.242,0.541,0.541V152.201z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M302.208,74.892H155.159c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h147.049 c4.479,0,8.109-3.631,8.109-8.109S306.687,74.892,302.208,74.892z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M406.008,109.491h-173c-4.48,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h173c4.479,0,8.109-3.631,8.109-8.109 S410.486,109.491,406.008,109.491z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M198.411,109.491h-43.252c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h43.252 c4.479,0,8.109-3.631,8.109-8.109S202.889,109.491,198.411,109.491z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.334,57.592c-16.396,0-29.734,13.339-29.734,29.734c0,4.479,3.631,8.109,8.109,8.109s8.109-3.631,8.109-8.109 c0-7.453,6.063-13.516,13.516-13.516S77.85,79.874,77.85,87.326c0,7.452-6.063,13.516-13.516,13.516 c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109c16.396,0,29.734-13.338,29.734-29.734 C94.068,70.93,80.73,57.592,64.334,57.592z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.421,126.791h-0.086c-4.479,0-8.066,3.631-8.066,8.109s3.674,8.109,8.153,8.109s8.109-3.631,8.109-8.109 S68.899,126.791,64.421,126.791z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M302.208,230.591H155.159c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h147.049 c4.479,0,8.109-3.631,8.109-8.109S306.687,230.591,302.208,230.591z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M380.058,265.191h-147.05c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h147.05 c4.479,0,8.109-3.631,8.109-8.109S384.536,265.191,380.058,265.191z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M198.411,265.191h-43.252c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h43.252 c4.479,0,8.109-3.631,8.109-8.109S202.889,265.191,198.411,265.191z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.334,213.291c-16.396,0-29.734,13.338-29.734,29.734c0,4.479,3.631,8.109,8.109,8.109s8.109-3.631,8.109-8.109 c0-7.453,6.063-13.516,13.516-13.516s13.516,6.063,13.516,13.516c0,7.453-6.063,13.516-13.516,13.516 c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109c16.396,0,29.734-13.338,29.734-29.734 C94.068,226.629,80.73,213.291,64.334,213.291z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.421,282.491h-0.086c-4.479,0-8.066,3.631-8.066,8.109s3.674,8.109,8.153,8.109s8.109-3.631,8.109-8.109 S68.899,282.491,64.421,282.491z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M302.208,386.29H155.159c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h147.049 c4.479,0,8.109-3.631,8.109-8.109S306.687,386.29,302.208,386.29z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M388.708,420.89h-155.7c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h155.7c4.479,0,8.109-3.631,8.109-8.109 S393.186,420.89,388.708,420.89z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M198.411,420.89h-43.252c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109h43.252 c4.479,0,8.109-3.631,8.109-8.109S202.889,420.89,198.411,420.89z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.334,368.99c-16.396,0-29.734,13.338-29.734,29.734c0,4.479,3.631,8.109,8.109,8.109s8.109-3.631,8.109-8.109 c0-7.453,6.063-13.516,13.516-13.516s13.516,6.063,13.516,13.516c0,7.453-6.063,13.516-13.516,13.516 c-4.479,0-8.109,3.631-8.109,8.109s3.631,8.109,8.109,8.109c16.396,0,29.734-13.338,29.734-29.734 C94.068,382.329,80.73,368.99,64.334,368.99z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M64.421,438.19h-0.086c-4.479,0-8.066,3.631-8.066,8.109s3.674,8.109,8.153,8.109s8.109-3.631,8.109-8.109 S68.899,438.19,64.421,438.19z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M511.655,364.875c0.862-2.86,0.082-5.963-2.03-8.075l-52.259-52.259V204.1c0-9.241-7.518-16.759-16.759-16.759H16.759 C7.518,187.341,0,194.859,0,204.1V307.9c0,9.241,7.518,16.759,16.759,16.759h389.789v18.381H16.759 C7.518,343.04,0,350.558,0,359.8v103.8c0,9.241,7.518,16.759,16.759,16.759h423.848c9.241,0,16.759-7.518,16.759-16.759v-24.851 l7.564,18.262c0.823,1.987,2.401,3.566,4.389,4.389c0.994,0.411,2.049,0.617,3.103,0.617c1.054,0,2.109-0.205,3.103-0.617 l29.097-12.053c4.138-1.714,6.103-6.458,4.389-10.595l-26.363-63.644l22.85-4.625C508.428,369.89,510.793,367.735,511.655,364.875 z M112.45,308.441h-95.69c-0.298,0-0.541-0.242-0.541-0.541V204.1c0-0.298,0.242-0.541,0.541-0.541h95.69V308.441z M128.668,308.441V203.56h311.939c0.298,0,0.541,0.242,0.541,0.541v84.223l-20.757-20.757c-2.319-2.32-5.808-3.013-8.837-1.758 c-3.031,1.255-5.006,4.213-5.006,7.492v35.141H128.668z M112.45,464.14h-95.69c-0.298,0-0.541-0.242-0.541-0.541V359.8 c0-0.298,0.242-0.541,0.541-0.541h95.69V464.14z M441.148,463.599c0,0.298-0.242,0.541-0.541,0.541H128.668V359.259h277.88v40.018 c0,2.979,1.634,5.719,4.255,7.135c2.621,1.416,5.808,1.281,8.3-0.352l19.445-12.745l2.6,6.278V463.599z M469.78,361.164 c-2.375,0.481-4.411,1.999-5.55,4.138c-1.139,2.14-1.261,4.675-0.334,6.915l27.028,65.25l-14.112,5.846l-27.028-65.25 c-0.924-2.232-2.795-3.935-5.103-4.646c-0.783-0.241-1.587-0.36-2.387-0.36c-1.562,0-3.11,0.451-4.446,1.327l-15.079,9.884 v-91.389l64.706,64.706L469.78,361.164z">
                        </path>
                    </g>
                </g>
            </g>
        </svg>
    )
}
