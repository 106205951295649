import { Outlet } from "react-router-dom";
import AdminHeader from "./adminHeader";
import AdminFooter from "./adminFooter";
import SideBar from "./sideBar";

export default function AdminLayout() {
    return (
        <div className="bg-[#eeeeee] text-[#333] font-lato">
            <AdminHeader/>
            <div className="flex flex-col md:flex-row">
                <SideBar/>
                <div className="flex-1 min-h-[70vh]">
                    <Outlet/>
                </div>
            </div>
            <AdminFooter/>
        </div>
    )
}