import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import AdminModal from "../../../core/admin/adminModal";
import { db } from "../../../../firebase";
import { useEffect, useState } from "react";
import HeaderInput from "./headerInput";
import XIcon from "../../../../img/svg/xIcon";
import LoadingBalls from "../../../core/loadingBalls";
import { deleteObject, getStorage, ref } from "firebase/storage";

export default function HeaderConfigModal({ close }: { close: () => void }) {

    const [images, setImages] = useState<{ id: string, url: string, display: boolean }[]>([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [onlyOne, setOnlyOne] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [delImage, setDelImage] = useState<{id: string, url: string, display: boolean} | null>(null);

    useEffect(() => {
        getHeaderImages();
    }, [refresh])

    const getHeaderImages = async () => {
        try {
            setLoading(true);
            const q = collection(db, "headerimages");
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setLoading(false);
                return null;
            }
            let docs: { id: string, url: string, display: boolean }[] = [];
            docsSnap.docs.forEach(d => {
                docs.push({
                    id: d.id,
                    url: d.data().url,
                    display: d.data().display
                })
            })

            setImages(docs.filter(d => d?.url));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            return null;
        }
    }

    const saveImage = async (url: string) => {
        try {
            await addDoc(collection(db, "headerimages"), {
                url,
                display: false
            })

        } catch (error) {
            console.log(error);
        }
    }

    const activateImage = async (imgId: string, state: boolean) => {
        try {
            if (images.filter(i => i.display).length && state) {
                setOnlyOne(true);
                return null;
            }
            setLoading(true);
            const docRef = doc(db, "headerimages", imgId);
            await updateDoc(docRef, { display: state });
            setRefresh(!refresh);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const deleteImage = async (img: { id: string, display: boolean, url: string }) => {
        setLoading(true);
        const storage = getStorage();
        try {
            const imageRef = ref(storage, img.url);
            await deleteObject(imageRef);
            const docRef = doc(db, "headerimages", img.id);

            await deleteDoc(docRef);
            setRefresh(!refresh);
        } catch (error) {
            console.log(error);
        } finally {
            setDelModal(false);
            setLoading(false);
        }
    }

    const handleDelImage = (img: { id: string, display: boolean, url: string }) => {
        setDelImage(img);
        setDelModal(true);
    }

    return (
        <AdminModal close={close}>
            <h2 className="text-center text-xl font-bold mb-4">Imagem do Header</h2>
            <div>
                <HeaderInput saveImgUrl={saveImage} loading={loading} setLoading={setLoading} refresh={() => setRefresh(!refresh)} />
            </div>
            <div className="mt-4 border-t border-mono pt-4">
                {
                    loading ?
                        <div className="w-fit m-auto my-10">
                            <LoadingBalls />
                        </div>
                        : images.length ?
                            images.map((im, idx) => (
                                <div key={idx} className="relative">
                                    {
                                        im.display ?
                                            <div className="absolute top-2 right-2 z-10">
                                                <p className="px-2 py-1 bg-green-500 text-white mb-2 font-bold">ATIVA</p>
                                                <button onClick={() => activateImage(im.id, false)} className="bg-[#ccc] border border-mono py-1 px-2">Desativar</button>
                                            </div>
                                            :
                                            <div className="absolute top-2 right-2 z-10">
                                                <button onClick={() => activateImage(im.id, true)} className="bg-[#ccc] border border-mono py-1 px-2">Ativar</button>
                                                <button onClick={() => handleDelImage(im)} className="bg-red-500 block m-auto mt-4"><XIcon /></button>
                                            </div>
                                    }
                                    <img src={im.url} alt="alt" className="h-[100px] w-full object-cover my-4 border border-mono" />
                                    <div className="absolute top-0 bg-gradient-to-r from-[#00000077] via-[#000000ee] to-[#00000077] w-full h-full"></div>
                                </div>
                            ))
                            :
                            <p>Nenhuma imagem adicionada</p>
                }
            </div>
            {
                onlyOne ?
                    <div className="h-full w-full absolute top-0 left-0 bg-[#000000aa] z-[999] p-10">
                        <div className="bg-white py-10 px-4">
                            <p className="text-center mb-10">Apenas uma imagem pode estar ativa por vez!</p>
                            <button className="px-10 py-1 bg-[#ccc] border border-mono" onClick={() => setOnlyOne(false)}>OK</button>
                        </div>
                    </div>
                    : null
            }
            {
                delModal && delImage ?
                    <div className="h-full w-full absolute top-0 left-0 bg-[#000000aa] z-[999] p-10">
                        <div className="bg-white py-10 px-4">
                            <p className="text-center mb-10">Deseja realmente excluir esta imagem?</p>
                            <div className="flex gap-4 justify-center">
                                <button className="bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] p-2 text-white w-20" onClick={() => deleteImage(delImage)}>Sim</button>
                                <button className="bg-[#ff3333] hover:bg-[#ff9999] active:bg-[#ff9999] p-2 text-white w-20" onClick={() => setDelModal(false)}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </AdminModal>
    )
}