import { useState } from "react";
import { IAd } from "./adminAds";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import AdFileInput from "./adFileInput";

export default function AdImagesUploader({ ad, update, mobile=false, refresh }: { ad: IAd | null, update: (fields: {}) => void, mobile?: boolean, refresh: () => void }) {

    const [uploadLoading, setUploadLoading] = useState(false);

    const uploadImage = async (field: string, imgUrl: string) => {
        try {
            if (!ad?.id) {
                setUploadLoading(false);
                return null;
            }
            const docRef = doc(db, "ads", ad.id);
            let fields: {[key: string]: string} = {};
            fields[field] = imgUrl;
            await updateDoc(docRef, fields);
            update(fields);
            refresh();
            setUploadLoading(false);
        } catch (error) {
            console.log(error);
            setUploadLoading(false);
        }
    }

    if(!ad?.id){
        return (
            <div>
                Anúncio inválido
            </div>
        )
    }

    return (
        <div className="w-fit m-auto">
            <AdFileInput adId={ad?.id} loading={uploadLoading} saveImgUrl={uploadImage} mobile={mobile} setLoading={setUploadLoading} isDisabled={false}/>
        </div>
    )
}
