export const itemsPerPage = 10;

export const statusFields: { [key: string]: string } = {
    draft: "Rascunho",
    approval: "Aguardando aprovação",
    display: "Em exibição",
    sold: "Vendido",
    hidden: "Escondido",
}

export const statusColors: { [key: string]: string } = {
    draft: "#000",
    approval: "#DAA53F",
    display: "#00bb00",
    sold: "#46B1F7",
    hidden: "#FF0000",
}

export const stateFields: { [key: string]: string } = {
    new: "Novo",
    // pre_owned: "Seminovo",
    used: "Usado"
}

export const movementFields: { [key: string]: string } = {
    // mec_manual: "Movimento Mecânico manual",
    // mec_auto: "Movimento Mecânico automático",
    // quartz: "Movimento de Quartzo",
    // hibrid: "Movimento Híbrido",
    // solar: "Movimento Solar",
    // high_freq: "Movimento de Alta Frequência"
    mec: "Mecânico",
    auto: "Automático",
    quartz: "Quartz",
    spring: "Spring Drive",
    other: "Outros"
}

export const braceletMaterialFields: { [key: string]: string } = {
    metal: "Metal",
    leather: "Couro",
    fabric: "Tecido",
    other: "Outros"
    // steel: "Aço Inox",
    // aluminum: "Alumínio",
    // rubber: "Borracha/Silicone",
    // bronze: "Bronze",
    // ceramic: "Cerâmica",
    // liquid_metal: "Cerâmica Líquida",
    // carbon: "Fibra de Carbono",
    // kevlar: "Kevlar",
    // brass: "Latão",
    // wood: "Madeira",
    // nylon: "Nylon",
    // gold: "Ouro",
    // platinum: "Platina",
    // pvd: "PVD",
    // plastic: "Resina/Plástico",
    // titanium: "Titânio",
    
}

export const caseMaterialFields: { [key: string]: string } = {
    steel: "Aço",
    bronze: "Bronze",
    gold: "Ouro",
    other: "Outros"
    // aluminum: "Alumínio",
    // rubber: "Borracha/Silicone",
    // ceramic: "Cerâmica",
    // liquid_metal: "Cerâmica Líquida",
    // carbon: "Fibra de Carbono",
    // fiberglass: "Fibra de Vidro",
    // brass: "Latão",
    // wood: "Madeira",
    // gold_plated: "Ouro Laminado",
    // platinum: "Platina",
    // pvd: "PVD",
    // plastic: "Resina/Plástico",
    // titanium: "Titânio",
}

export const glassFields: { [key: string]: string } = {
    crystal: "Cristal",
    acrylic: "Acrílico",
    sapphire: "Safira",
    mineral_glass: "Vidro Mineral",
    other: "Outros"
    // sapphire_crystal: "Cristal de Safira",
    // coated_sapphire_crystal: "Cristal de Safira Revestido",
    // hardlex: "Hardlex",
    // hesalite: "Hesalite",
}

export const watchFields: { [key: string]: string | number } = {
    brand: "Marca",
    model: "Modelo",
    year: "Ano",
    id: "ID",
    serial: "Serial"
}
