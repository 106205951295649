import { useEffect, useRef, useState } from "react";

export default function ImageViewer({ imgSrc }: { imgSrc: string }) {

    const imgRef = useRef<HTMLImageElement | null>(null);
    const [naturalDimensions, setNaturalDimensions] = useState({ width: 0, height: 0 });

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [positionPercentage, setPositionPercentage] = useState({ x: 0, y: 0 });
    const [isOver, setIsOver] = useState(false);

    // tamanho da imagem
    const fixedDimensions = { w: 500, h: 350 };
    // tamanho do quadradinho do zoom
    const zoomDimensions = { w: 200, h: 150 };

    let imageDimensions = { w: fixedDimensions.w, h: fixedDimensions.h};

    if(naturalDimensions.width > naturalDimensions.height){
        imageDimensions = { w: fixedDimensions.w, h: (naturalDimensions.height*fixedDimensions.w)/naturalDimensions.width };
    }else if(naturalDimensions.width < naturalDimensions.height){
        imageDimensions = { w: (naturalDimensions.width*fixedDimensions.h)/naturalDimensions.height, h: fixedDimensions.h };
    }else{
        imageDimensions = { w: 500, h: 500 };
    }
    

    const handleMouseMove = (e: any) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPosition({ x, y });
        setPositionPercentage({ x: (x / imageDimensions.w), y: (y / imageDimensions.h) });
    }

    useEffect(() => {
        const imgElement = imgRef.current;
        if (imgElement) {
            const updateDimensions = () => {

                setNaturalDimensions({
                    width: imgElement.naturalWidth,
                    height: imgElement.naturalHeight,
                })
            }
            // Adiciona um event listener para garantir que a imagem foi carregada
            imgElement.addEventListener('load', updateDimensions);

            // Atualiza as dimensões se a imagem já foi carregada
            if (imgElement.complete) {
                updateDimensions();
            }

            // Remove o event listener na desmontagem do componente
            return () => {
                imgElement.removeEventListener('load', updateDimensions);
            };
        }
    }, []);

    return (
        <div className="overflow-hidden flex items-center justify-center" style={
            {
                width: fixedDimensions.w+30,
                height: fixedDimensions.h+30,
            }
        }>
            <div className="bg-moninho relative flex items-center" style={{ width: imageDimensions.w, height: imageDimensions.h}}
                 onMouseMove={handleMouseMove} onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)} >
                <img ref={imgRef} src={imgSrc} alt="regular" className="max-h-full max-w-full m-auto object-cover"
                />
                {
                    isOver ?
                        <div className="overflow-hidden absolute border border-black" style={{ width: zoomDimensions.w, height: zoomDimensions.h, top: position.y - 75, left: position.x - 100 }}>
                            <div className="relative" style={
                                {
                                   width: imageDimensions.w*2,
                                   height: imageDimensions.h*2
                                }
                            }>
                                <img src={imgSrc} alt="zoomed" className="object-cover absolute" style={
                                    {
                                        width: imageDimensions.w*2,
                                        height: imageDimensions.h*2,
                                        top: (-positionPercentage.y*imageDimensions.h*2)+zoomDimensions.h/2,
                                        right: (positionPercentage.x*imageDimensions.w*2)-zoomDimensions.w/2
                                    }
                                } />
                            </div>
                        </div>
                        : null
                }
            </div>

            {/* <p>X: {position.x}</p>
            <p>Y: {position.y}</p>
            <p>X%: {positionPercentage.x}</p>
            <p>Y%: {positionPercentage.y}</p>
            <p>Natural Width: {naturalDimensions.width}</p>
            <p>Natural Height: {naturalDimensions.height}</p> */}

        </div>
    )
}
