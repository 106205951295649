import { useEffect, useState } from "react";
import GMTIcon from "../../../img/svg/gmtIcon";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { IVideo } from "../admin/home/adminHomeConfigs";
import { getURLParams } from "../../../utils/functions";
import BannersCase from "../../core/bannersCase";
import LoadingBalls from "../../core/loadingBalls";
import { Link } from "react-router-dom";
import WatchesGrid from "./watchesGrid";
import Ads from "./ads";
import BrandsGrid from "./brandsGrid";
import { useWatches } from "../../../context/watchProvider";


export default function Home({ isMobile, currentImage }: { isMobile: boolean, currentImage: string }) {

    const { getTop10Watches } = useWatches();

    const [banners, setBanners] = useState<{ [key: string]: any }[]>([]);
    const [video, setVideo] = useState<IVideo | null>(null);
    const [loading, setLoading] = useState(false);
    // const [currentImage, setCurrentImage] = useState("");

    useEffect(() => {
        // getCurrentImage();
        getBanners();
        getVideo();
        getTop10Watches();
        // eslint-disable-next-line
    }, [])

    const getVideo = async () => {
        try {
            const docSnap = await getDocs(collection(db, "videos"));
            if (docSnap.empty) {
                setVideo(null);
                return null;
            }
            const docs = docSnap.docs.map(d => {
                return {
                    id: d.id,
                    link: d.data()?.link,
                    title: d.data()?.title,
                    description: d.data()?.description,
                    active: d.data()?.active,
                }
            })
            setVideo(docs.filter(d => d?.link)[0]);
        } catch (error) {
            console.log(error);
        }
    }

    // const getCurrentImage = async () => {
    //     try {
    //         const q = query(collection(db, "headerimages"), where("display", "==", true));
    //         const docsSnap = await getDocs(q);
    //         if (docsSnap.empty) {
    //             return null;
    //         }
    //         setCurrentImage(docsSnap.docs[0].data().url);
    //     } catch (error) {
    //         console.log(error);
    //         return null;
    //     }
    // }

    const getBanners = async () => {
        try {
            setLoading(true);
            const documents: { [key: string]: any }[] = [];
            const q = query(collection(db, "watches"), where("status", "==", "display"), where("isBanner", "==", true));
            const docs = await getDocs(q);
            if (docs.empty) {
                setBanners(documents);
                setLoading(false);
                return null;
            }
            docs.docs.forEach(b => {
                documents.push({
                    brand: b.data().brand,
                    model: b.data().model,
                    year: b.data().year,
                    price: b.data().price,
                    slug: b.data().slug,
                    imgUrl: b.data().images?.length ? b.data().images[0].cImgUrl : undefined,
                    order: b.data().order
                });
            })
            setBanners(documents.sort((a, b) => a.order - b.order));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <main className="mb-20">
            <div className="absolute top-0 h-[220px] w-full bg-primary z-[1] box-shadow shadow-md shadow-black">
                {
                    currentImage ?
                        <img src={currentImage} alt="internet" className="w-full h-full object-cover" />
                        : null
                }
                <div className="absolute top-0 bg-gradient-to-r from-[#00000077] via-[#000000ee] to-[#00000077] w-full h-full"></div>
            </div>
            <div className="m-auto w-full z-10 relative">
                <div className="w-fit m-auto pt-0 mt-2 md:-mt-4"
                    style={{ textShadow: "2px 2px 12px rgba(0, 0, 0, 1)" }}
                >
                    <h1 className="tracking-[7px] text-white text-xl md:text-3xl text-center">CLASSIFICADOS</h1>
                    <div className="text-3xl m-auto w-fit mt-4 md:mt-6">
                        <GMTIcon width={isMobile ? "160px" : "200px"} fill="#fff" />
                    </div>
                </div>
                {/* <div>
                    <h2 className="mb-[120px] tracking-[4px] text-white border-b border-white w-fit text-center m-auto text-xl px-10">O <strong>tempo</strong> em suas mãos</h2>
                </div> */}
            </div>
            {
                loading ?
                    <div className="h-[365px] flex items-center justify-center">
                        <LoadingBalls />
                    </div>
                    : banners.length ?
                        <section className="bg-granners py-20 relative">
                            <h2 className="text-center text-xl font-[500] mb-4 border-b w-fit m-auto border-mono px-10"><strong>PEÇAS EM DESTAQUE</strong></h2>
                            <BannersCase banners={banners} isMobile={isMobile} />
                            <Link to={"/watches"} className="p-4 bg-primary text-white m-auto block w-fit border border-secondary mt-6">VER TODAS AS PEÇAS</Link>
                        </section>
                        : <div className="mb-12"></div>
            }
            <Ads isMobile={isMobile} />
            <div className="bg-primary h-10 w-full"></div>
            {
                video && video.active ?
                    <section className="flex flex-col md:flex-row bg-primary text-white gap-4 p-10 md:pb-20 md:pt-10">
                        <div className="flex flex-col justify-center md:flex-1 h-[200px] md:h-[400px]">
                            <p className="text-center px-4 text-2xl mb-8">{video?.title}</p>
                            <p className="text-center px-4 text-lg">{video?.description}</p>
                        </div>
                        <div className="md:flex-1 flex items-center justify-center h-fit md:h-[400px]">
                            <iframe
                                className="m-auto mt-4"
                                width="100%"
                                height="360"
                                src={`https://www.youtube.com/embed/${getURLParams(video.link).v}`} //?si=1vb3Or9oOKrGmx_q
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </section>
                    : null
            }
            <BrandsGrid isMobile={isMobile} />
            {/* <WatchesGrid criter="views" /> */}
            <WatchesGrid criter="date" />
            <Link to={"/watches"} className="p-4 bg-primary text-white m-auto block w-fit border border-secondary mt-6">VER TODAS AS PEÇAS</Link>
            <div className="bg-gradient-to-b from-white via-primary to-white h-4 w-full my-10 opacity-20"></div>

        </main>
    )
}
