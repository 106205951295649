import { useNavigate } from "react-router-dom"

export default function Banner({ banner }: { banner: { [key: string]: any } }) {

    const navigate = useNavigate();

    return (
        <div className="w-[250px] flex-none select-none cursor-pointer bg-white p-1 rounded border border-mono relative z-[5]" onClick={() => navigate(`/watches/${banner.slug}`)}>
            <div className="h-[250px] w-full relative">
                <img src={banner.imgUrl} alt="banner" className="object-cover h-full w-full" />
                <div className="absolute top-0 w-full h-full"></div>
            </div>
            <div className="py-2 text-primary">
                <p className="text-xs text-center">{banner.brand.toUpperCase()} / {banner.year}</p>
                <h3 className="text-lg text-center font-bold -mt-1 text-primary">{banner.model.toUpperCase()}</h3>
                <p className="text-sm text-center -mt-1">R${banner.price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            {/* <div className="m-auto w-fit">
                <button className="pointer-events-auto text-md bg-primary text-white px-4 py-1 active:opacity-70">CONHECER</button>
            </div> */}
        </div>
    )
}
