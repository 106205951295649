import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ scrollY, isMobile }: { scrollY: number, isMobile: boolean}) {
    return (
        <div className="font-poppins text-primary">

            <Header scrollY={scrollY} isMobile={isMobile}/>
            <Outlet />
            <Footer/>
        </div>
    )
}
