import UsersIcon from "../../../img/svg/usersIcon";
import SideField from "./sideField";
import SettingsIcon from "../../../img/svg/settingsIcon";
import { useLocation } from "react-router-dom";
import LeftArrow from "../../../img/svg/leftArrow";
import HouseIcon from "../../../img/svg/houseIcon";
import WatchIcon from "../../../img/svg/watchIcon";
import { useAuth } from "../../../context/authProvider";
import BannerIcon from "../../../img/svg/bannerIcon";
import ThreeStarsIcon from "../../../img/svg/threeStarsIcon";
import ContactIcon from "../../../img/svg/contactIcon";
import FAQIcon from "../../../img/svg/faqIcon";
import AdIcon from "../../../img/svg/adIcon";
import SiteIcon from "../../../img/svg/siteIcon";

export default function SideBar({ isMobile=false }: { isMobile?: boolean }) {

    const { user } = useAuth();
    const pathname = useLocation().pathname.split("/")[2];

    return (
        <div className="hidden md:block bg-[#fafafa] h-fit pt-10 shadow-md bg-[#eeeeee] px-2 pb-2" style={{display: isMobile ? "block" : undefined}}>
            <div>
                <SideField selected={[undefined, ""].includes(pathname)} to="" icon={<SiteIcon fill="#333333" />} label="Início" />
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "general"} to="general" icon={<SettingsIcon fill="#333333" />} label="Geral" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "home"} to="home" icon={<HouseIcon fill="#333333" width="30px" />} label="Home" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "faqs"} to="faqs" icon={<FAQIcon fill="#333333" width="30px" />} label="FAQs" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "ads"} to="ads" icon={<AdIcon fill="#333333" width="30px" />} label="Anúncios" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "users"} to="users" icon={<UsersIcon fill="#333333" />} label="Usuários" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "banners"} to="banners" icon={<BannerIcon/>} label="Banners" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "watches"} to="watches" icon={<WatchIcon width="30px" fill="#333333" />} label="Relógios" />
                        : null
                }
                {
                    user?.roles.includes("admin") ?
                        <SideField selected={pathname === "reviews"} to="reviews" icon={<ThreeStarsIcon width="30px" fill="#FFF" />} label="Avaliações" />
                        : null
                }
                {
                    user?.roles.includes("advertiser") ?
                        <SideField selected={pathname === "my-contact"} to="my-contact" icon={<ContactIcon width="30px" fill="#333333" />} label="Meu Contato" />
                        : null
                }
                {
                    user?.roles.includes("advertiser") ?
                        <SideField selected={pathname === "my-watches"} to="my-watches" icon={<WatchIcon width="30px" fill="#333333" />} label="Meus Relógios" />
                        : null
                }
                {
                    user?.roles.includes("advertiser") ?
                        <SideField selected={pathname === "my-reviews"} to="my-reviews" icon={<ThreeStarsIcon width="30px" fill="#FFF" />} label="Minhas avaliações" />
                        : null
                }
            </div>
            <div className="mt-4">
                <SideField to="/" icon={<LeftArrow fill="#333333" />} label="Voltar para o site" />
            </div>
        </div>
    )
}
