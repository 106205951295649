import { useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import AdminToast from "../../../core/admin/adminToast";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function CreateFAQModal({ close, finish }: { close: () => void, finish: () => void }) {

    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const createFaq = async () => {
        try {
            if (!question || !answer) {
                return null;
            }
            setLoading(true);
            await addDoc(collection(db, "faqs"), {
                question,
                answer,
                display: false,
                order: 0
            });
            finish();
        } catch (error) {
            console.log(error);
            setBadToast(true);
        } finally {
            setLoading(false);
        }
    }

    const handleQuestion = (e: string) => {
        if (e.length < 152) {
            setQuestion(e);
        }
    }

    const handleAnswer = (e: string) => {
        if (e.length < 500) {
            setAnswer(e);
        }
    }

    return (
        <AdminModal close={close}>
            {
                loading ?
                    <div className="h-[200px] flex items-center"><ClockAnimation /></div>
                    :
                    <div>
                        <h3 className="text-center font-lg font-bold">Pergunta</h3>
                        <input type="text" className="outline-none border border-mono w-full p-2 mb-4" value={question} onChange={e => handleQuestion(e.target.value)} />
                        <h3 className="text-center font-lg font-bold">Resposta</h3>
                        <textarea className="outline-none resize-none border border-mono w-full p-2" value={answer} onChange={e => handleAnswer(e.target.value)} />
                        <button disabled={!question || !answer} onClick={createFaq} className="w-fit m-auto mt-4 block px-4 py-2 bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#00bb00] disabled:bg-[#999] font-bold text-white">Criar</button>
                    </div>
            }
            <AdminToast toast={badToast} setToast={setBadToast} color="#bb0000" message="Erro!" />
        </AdminModal>
    )
}