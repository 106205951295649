import { useState } from "react"
import AdminButton from "../../../core/admin/adminButton";
import AdminTextInput from "../../../core/admin/adminTextInput";
import AdminModal from "../../../core/admin/adminModal";
import { collection, getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import CheckIcon from "../../../../img/svg/checkIcon";
import XIcon from "../../../../img/svg/xIcon";
import AdminToast from "../../../core/admin/adminToast";
import { collectionHasDoc, randomGenerator } from "../../../../utils/functions";

interface IUser {
    id: string | undefined;
    name: string;
    email: string;
    slug?: string;
    roles: string[];
    phoneNumber: string;
}

export default function FindUser() {

    const [email, setEmail] = useState("");
    const [modalUser, setModalUser] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [operationLoading, setOperationLoading] = useState(false);
    const [foundUser, setFoundUser] = useState<IUser | null>(null);
    const [userFeedback, setUserFeedback] = useState("");
    const [handleAdmin, setHandleAdmin] = useState(false);
    const [handleAdvertiser, setHandleAdvertiser] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const searchByEmail = async () => {
        try {
            setUserLoading(true);
            setUserFeedback("");
            const col = collection(db, "users");
            const q = query(col, where("email", "==", email));
            const documents = await getDocs(q);
            const document = documents.docs.find(d => d.data().email === email);
            const data = document?.data();
            if (data) {
                setFoundUser({ id: document?.id, name: data.name, email: data.email, roles: data.roles, phoneNumber: data.phoneNumber, slug: data.slug });
                setModalUser(true);
            } else {
                setUserFeedback("Usuário não encontrado");
            }
            setUserLoading(false);
        } catch (error) {
            console.log(error)
            setUserLoading(false);
            setBadToast(true);
        }
    }

    const updateAdmin = async () => {
        if (foundUser?.id) {
            try {
                setOperationLoading(true);
                if(foundUser.roles.includes("master")){
                    setBadToast(true);
                    setOperationLoading(false);
                    setHandleAdmin(false);
                    return null;
                }
                let newRoles;
                if (!foundUser.roles.includes("admin")) {
                    newRoles = [...foundUser.roles, "admin"];
                } else {
                    newRoles = foundUser.roles.filter(role => role !== "admin");
                }
                const userDoc = doc(db, "users", foundUser.id);
                await updateDoc(userDoc, {
                    roles: newRoles
                })
                setFoundUser({ ...foundUser, roles: newRoles });
                setHandleAdmin(false);
                setGoodToast(true);
                setOperationLoading(false);
            } catch (error) {
                console.log(error);
                setOperationLoading(false);
                setBadToast(true);
            }
        }
    }

    const updateAdvertiser = async () => {
        if (foundUser?.id) {
            try {
                setOperationLoading(true);
                let fields: { [key: string]: string | string[] } = {};
                if (!foundUser.slug) {
                    // criar um slug não utilizado ainda
                    let randomStr = "";
                    while (true) {
                        randomStr = randomGenerator();
                        const hasDoc = await collectionHasDoc("users", "slug", randomStr);
                        if (!hasDoc) {
                            break;
                        }
                    }
                    fields["slug"] = randomStr;
                }
                let newRoles;
                if (!foundUser.roles.includes("advertiser")) {
                    newRoles = [...foundUser.roles, "advertiser"];
                } else {
                    newRoles = foundUser.roles.filter(role => role !== "advertiser");
                }
                fields["roles"] = newRoles;
                const userDoc = doc(db, "users", foundUser.id);
                await updateDoc(userDoc, fields);
                setFoundUser({ ...foundUser, roles: newRoles });
                setHandleAdvertiser(false);
                setGoodToast(true);
                setOperationLoading(false);
            } catch (error) {
                console.log(error);
                setOperationLoading(false);
                setBadToast(true);
            }
        }
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-2 md:px-10">
            <h3 className="text-center text-lg">Pesquisar usuário por email</h3>
            <div className="flex gap-4 my-2 w-fit m-auto">
                <AdminTextInput value={email} onChange={e => setEmail(e.target.value)} placeholder="email" />
                <AdminButton onClick={searchByEmail}>Pesquisar</AdminButton>
            </div>
            <p className="text-red-500 text-center">{userFeedback}</p>
            {
                userLoading ?
                    <ClockAnimation width="50px" /> : null
            }
            {
                modalUser ?
                    <AdminModal close={() => setModalUser(false)}>
                        <h2 className="text-center text-xl font-bold mb-4">Usuário</h2>
                        <p className="border border-[#D1B57A] text-center w-fit m-auto p-2"><strong>ID:</strong> {foundUser?.id}</p>
                        {
                            foundUser?.slug ?
                                <p className="border border-[#D1B57A] text-center w-fit m-auto p-2 mt-2"><strong>Slug:</strong> {foundUser?.slug}</p>
                                : null
                        }
                        <div className="text-center w-[70%] m-auto mb-4">
                            <h4 className="font-bold">Nome</h4>
                            <h4 className="border border-[#333] rounded text-lg">{foundUser?.name}</h4>
                        </div>
                        <div className="text-center w-[70%] m-auto mb-4">
                            <h4 className="font-bold">E-mail</h4>
                            <h4 className="border border-[#333] rounded text-lg">{foundUser?.email}</h4>
                        </div>
                        <div className="text-center w-[70%] m-auto mb-4">
                            <h4 className="font-bold">Telefone</h4>
                            <h4 className="border border-[#333] rounded text-lg">{foundUser?.phoneNumber}</h4>
                        </div>

                        <div className="flex w-[50%] m-auto items-center justify-start gap-8 mb-4">
                            <div className="border border-[#333] rounded">
                                {
                                    foundUser?.roles.includes("admin") ?
                                        <CheckIcon fill="green" />
                                        : <XIcon fill="red" />
                                }
                            </div>
                            <h4 className="flex-1">Admin</h4>
                            {
                                handleAdmin ?
                                    <div className="flex flex-col md:flex-row items-center gap-4">
                                        <h5>Tem certeza?</h5>
                                        <button onClick={() => setHandleAdmin(false)} className="bg-[#ff3333] text-[#cccccc] p-2 active:bg-[#ff9999]">Não</button>
                                        <button onClick={updateAdmin} className="bg-[#00bb00] text-[#cccccc] p-2 active:bg-[#88bb88]">Sim</button>
                                    </div>
                                    :
                                    <AdminButton onClick={() => setHandleAdmin(true)}>{foundUser?.roles.includes("admin") ? "Destituir admin" : "Tornar admin"}</AdminButton>
                            }
                        </div>
                        <div className="flex w-[50%] m-auto items-center justify-start gap-4">
                            <div className="border border-[#333] rounded">
                                {
                                    foundUser?.roles.includes("advertiser") ?
                                        <CheckIcon fill="green" />
                                        : <XIcon fill="red" />
                                }
                            </div>
                            <h4 className="flex-1">Anunciante</h4>
                            {
                                handleAdvertiser ?
                                    <div className="flex flex-col md:flex-row items-center gap-4">
                                        <h5>Tem certeza?</h5>
                                        <button onClick={() => setHandleAdvertiser(false)} className="bg-[#ff3333] text-[#cccccc] p-2 active:bg-[#ff9999]">Não</button>
                                        <button onClick={updateAdvertiser} className="bg-[#00bb00] text-[#cccccc] p-2 active:bg-[#88bb88]">Sim</button>
                                    </div>
                                    :
                                    <AdminButton onClick={() => setHandleAdvertiser(true)}>{foundUser?.roles.includes("advertiser") ? "Destituir anunciante" : "Tornar anunciante"}</AdminButton>
                            }
                        </div>
                        {
                            operationLoading ?
                                <div className="absolute bottom-0 right-0 m-4">
                                    <ClockAnimation width="50px" />
                                </div>
                                : null
                        }
                        <AdminToast toast={goodToast} setToast={setGoodToast} color="#00bb00" message="Usuário atualizado!" />
                        <AdminToast toast={badToast} setToast={setBadToast} color="#ff3333" message="Erro na operação!" />
                    </AdminModal>
                    : null
            }
        </div>

    )
}