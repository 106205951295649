export default function AdminFooter() {

    const year = new Date().getFullYear();

    return (
        <footer className="text-center mt-32 flex gap-4 justify-center">
            <h5 className="font-bold">GMT-3</h5>
            <h5>{year}</h5>
        </footer>
    )
}
