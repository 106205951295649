import { useState } from "react";
import { useAuth } from "../../../context/authProvider";
import EyeSlashIcon from "../../../img/svg/eyeSlashIcon";
import EyeIcon from "../../../img/svg/eyeIcon";
import GoogleIcon from "../../../img/svg/googleIcon";
import LoadingBalls from "../../core/loadingBalls";

export default function Register() {

    const { signUp, googleLogIn } = useAuth();

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSignUp = async () => {
        if(password !== confirmPassword || name === "" || !email.includes("@") || phoneNumber === "" || !email.includes(".")){
            return null;
        }
        setLoading(true);
        setError(false);
        const doSignUp = await signUp(name, phoneNumber, email, password);
        if(!doSignUp){
            setLoading(false);
            setError(true);
            return null;
        }
        setLoading(false);
    }

    const handlePhone = (value: string) => {
        if(value === ""){
            setPhoneNumber("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setPhoneNumber(value);
    }

    return (
        <div className="flex flex-col w-[300px] m-auto gap-4">
            <button className="border border-[#939393] hover:bg-[#eee] rounded flex items-center text-primary px-4 py-2" onClick={googleLogIn}><GoogleIcon/><p className="text-center flex-1">Entrar com Google</p></button>
            <div className="h-1 w-[200px] m-auto border-t border-primary"></div>
            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Nome" className="border border-[#B3B3B3] text-center p-2" />
            <input type="text" value={phoneNumber} onChange={e => handlePhone(e.target.value)} placeholder="Telefone" className="border border-[#B3B3B3] text-center p-2" />
            <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" className="border border-[#B3B3B3] text-center p-2" />
            <div className="relative">
                <div onClick={() => setShowPass(!showPass)} className="absolute top-[6px] right-2 cursor-pointer">
                    {
                        showPass ?
                            <EyeSlashIcon fill="#b3b3b3" />
                            : <EyeIcon fill="#b3b3b3" />
                    }
                </div>
                <input type={showPass ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} placeholder="Senha" className="w-full border border-[#B3B3B3] text-center p-2" />
            </div>
            <div className="relative">
                <div onClick={() => setShowPass(!showPass)} className="absolute top-[6px] right-2 cursor-pointer">
                    {
                        showPass ?
                            <EyeSlashIcon fill="#b3b3b3" />
                            : <EyeIcon fill="#b3b3b3" />
                    }
                </div>
                <input type={showPass ? "text" : "password"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirmação de senha" className="w-full border border-[#B3B3B3] text-center p-2" />
            </div>
            <button onClick={handleSignUp} className="border w-full m-auto bg-secondary text-white p-1 active:bg-[#aaaaaa] mt-4 h-10">{loading ? <div className="m-auto w-fit"><LoadingBalls width={80} color="#fff"/></div> : <p className="m-0">Registrar</p>}</button>
            {
                error ?
                <p className="text-center text-red-500">Ops, algo deu errado!</p>
                : null
            }
        </div>
    )
}
