import React from "react";
import XIcon from "../../../img/svg/xIcon";

export default function AdminModal({ close, children }: { close: () => void, children: React.ReactNode }) {
    return (
        <div onClick={close} className="fixed top-0 left-0 h-screen w-screen bg-[#00000099] flex justify-center overflow-auto py-4 z-10">
            <div onClick={e => e.stopPropagation()} className="w-[90%] md:w-[70%] h-fit p-4 bg-[#e0e0e0] relative">
                <button onClick={close} className="absolute right-0 top-0 p-2 text-2xl font-bold"><XIcon width="30px" fill="#333" /></button>
                {children}
            </div>
        </div>
    )
}