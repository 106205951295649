import { Route, Routes } from "react-router-dom";
import Layout from "./components/core/layout/layout";
import Home from "./components/pages/home/home";
import NotFound from "./components/pages/notFound";
import Watches from "./components/pages/watches/watches";
import Gatekeeper from "./components/pages/gatekeeper/gatekeeper";
import RequireAuth from "./hooks/requireAuth";
import AdminLayout from "./components/core/admin/adminLayout";
import { useAuth } from "./context/authProvider";
import AdminHome from "./components/pages/admin/adminHome";
import AdminUsers from "./components/pages/admin/users/adminUsers";
import AdminWatches from "./components/pages/admin/watches/adminWatches";
import MyArea from "./components/pages/me/myArea";
import AdminGeneral from "./components/pages/admin/general/adminGeneral";
import MyWatches from "./components/pages/admin/myWatches/myWatches";
import AdminBanners from "./components/pages/admin/banners/adminBanners";
import { useEffect, useState } from "react";
import WatchPage from "./components/pages/watch/watchPage";
import Advertiser from "./components/pages/advertiser/advertiser";
import MyReviews from "./components/pages/admin/myReviews/myReviews";
import AdminReviews from "./components/pages/admin/reviews/adminReviews";
import About from "./components/pages/about/about";
import MyContact from "./components/pages/admin/myContact/myContact";
import AdminFAQs from "./components/pages/admin/faqs/adminFaqs";
import FAQPage from "./components/pages/faq/faqPage";
// import DBTester from "./components/pages/dbTestes";
import AdminAds from "./components/pages/admin/ads/adminAds";
import AdminHomeConfigs from "./components/pages/admin/home/adminHomeConfigs";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import GMTIcon from "./img/svg/gmtIcon";

export default function App() {

    const { user } = useAuth();

    const [passed, setPassed] = useState("");
    const [currentImage, setCurrentImage] = useState("");

    const [scrollY, setScrollY] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    const handleScroll = () => {
        setScrollY(window.scrollY);
    }

    const handleWidth = () => {
        setIsMobile(window.innerWidth < 768);
    }

    useEffect(() => {
        getCurrentImage();
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleWidth);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleWidth);
        }
    }, []);

    const getCurrentImage = async () => {
        try {
            const q = query(collection(db, "headerimages"), where("display", "==", true));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            setCurrentImage(docsSnap.docs[0].data().url);
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    return (
        passed === "gioiagmt" ?
            <Routes>
                <Route path="/" element={<Layout scrollY={scrollY} isMobile={isMobile} />}>
                    <Route index element={<Home isMobile={isMobile} currentImage={currentImage} />} />
                    <Route path="/watches" element={<Watches isMobile={isMobile} />} />
                    <Route path="/watches/:slug" element={<WatchPage isMobile={isMobile} />} />
                    <Route path="/advertisers/:slug" element={<Advertiser />} />
                    <Route path="/about" element={<About isMobile={isMobile} currentImage={currentImage} />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/gatekeeper" element={<Gatekeeper />} />
                    <Route path="/me" element={<RequireAuth user={user} />}>
                        <Route index element={<MyArea />} />
                    </Route>
                    {/* <Route path="/dbtester" element={<DBTester/>} /> */}
                </Route>
                <Route path="/admin" element={<RequireAuth user={user} roles={["admin", "advertiser"]} />}>
                    <Route path="" element={<AdminLayout />}>
                        <Route index element={<AdminHome />} />
                        <Route path="" element={<RequireAuth user={user} roles={["admin"]} />}>
                            <Route path="home" element={<AdminHomeConfigs />} />
                            <Route path="general" element={<AdminGeneral />} />
                            <Route path="faqs" element={<AdminFAQs />} />
                            <Route path="ads" element={<AdminAds />} />
                            <Route path="banners" element={<AdminBanners />} />
                            <Route path="users" element={<AdminUsers />} />
                            <Route path="watches" element={<AdminWatches />} />
                            <Route path="reviews" element={<AdminReviews />} />
                        </Route>
                        <Route path="" element={<RequireAuth user={user} roles={["advertiser"]} />}>
                            <Route path="my-contact" element={<MyContact />} />
                            <Route path="my-watches" element={<MyWatches />} />
                            <Route path="my-reviews" element={<MyReviews />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
            :
            <main>
                <h1 className="tracking-[7px] text-black font-bold text-xl md:text-3xl text-center mt-20">CLASSIFICADOS</h1>
                <div className="m-auto w-fit">

                    <GMTIcon width="270px" />
                    <h2 className="text-center text-2xl mt-4">EM CONSTRUÇÃO</h2>
                </div>
                <div className="w-fit m-auto mt-10">
                    <p className="text-center">SENHA</p>
                    <input className="border border-black text-center" type="password" value={passed} onChange={e => setPassed(e.target.value)} />
                </div>
                <p className="text-center text-xl mt-10">Ir para <a  className="text-secondary cursor-pointer" target="_blank" rel="noreferrer" href="http://lojagmt.com.br/">lojagmt.com.br</a></p>
            </main>
    )
}
