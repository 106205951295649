import { useState } from "react"
import ChevronDownIcon from "../../../img/svg/chevronDownIcon";

export default function ExpandableFilter({ name, children }: { name: string, children: React.ReactNode }) {

    const [expanded, setExpanded] = useState(false);

    return (
        <div className="bg-white rounded px-4 py-2">
            <div onClick={() => setExpanded(!expanded)} className="flex justify-between items-center cursor-pointer">
                <p>{name}</p>
                {
                    expanded ?
                        <div className="transform rotate-180">
                            <ChevronDownIcon />
                        </div>
                        :
                        <div className="">
                            <ChevronDownIcon />
                        </div>
                }
            </div>
            {
                expanded ?
                children
                : null
            }
        </div>
    )
}