import { Link } from "react-router-dom";
import GMTIcon from "../../../img/svg/gmtIcon";

export default function About({ isMobile, currentImage}: { isMobile: boolean, currentImage: string }) {

    return (
        <main className="px-8 md:px-20 bg-primary text-white pb-32 border-b border-mono">
            <div className="absolute top-0 left-0 h-[220px] w-full bg-primary z-[1] box-shadow shadow-md shadow-black">
                {
                    currentImage ?
                        <img src={currentImage} alt="internet" className="w-full h-full object-cover" />
                        : null
                }
                <div className="absolute top-0 bg-gradient-to-r from-[#00000077] via-[#000000ee] to-[#00000077] w-full h-full"></div>
            </div>
            <div className="m-auto w-full z-10 relative">
                <div className="w-fit m-auto pt-0 mt-2 md:-mt-4"
                    style={{ textShadow: "2px 2px 12px rgba(0, 0, 0, 1)" }}
                >
                    <h1 className="tracking-[7px] text-white text-xl md:text-3xl text-center">CLASSIFICADOS</h1>
                    <div className="text-3xl m-auto w-fit mt-4 md:mt-6">
                        <Link to={"/"}><GMTIcon width={isMobile ? "160px" : "200px"} fill="#fff" /></Link>
                    </div>
                </div>
                {/* <div>
                    <h2 className="mb-[120px] tracking-[4px] text-white border-b border-white w-fit text-center m-auto text-xl px-10">O <strong>tempo</strong> em suas mãos</h2>
                </div> */}
            </div>


            <h1 className="text-3xl font-bold text-center py-10 mt-20">Desvendando o Universo da Relojoaria</h1>
            <div className="flex items-center gap-10 mt-10 flex-col md:flex-row">
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">A Jornada do GMT-3</h2>
                    <p className="text-center text-lg">O GMT-3 é uma referência no mundo da relojoaria, trazendo conhecimento, curiosidades e orientações para os amantes de relógios. Fundada em 2016, a marca surgiu da paixão de Leandro por relógios e da necessidade de compartilhar informações em português em um mercado onde o conteúdo disponível era majoritariamente em inglês.</p>
                </div>
                <div className="h-[300px] w-[400px] border border-mono max-w-full">

                </div>
            </div>

            <div className="flex items-center gap-10 mt-20 flex-col md:flex-row">
                <div className="h-[300px] w-[400px] border border-mono order-last md:order-first max-w-full">

                </div>
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">A Paixão que se Tornou Profissão</h2>
                    <p className="text-center text-lg">Leandro iniciou sua jornada no universo dos relógios como um hobby, produzindo conteúdo para o YouTube enquanto trabalhava em outro segmento. No entanto, com a chegada da pandemia em 2020, viu a oportunidade de transformar sua paixão em sua profissão principal. Foi a partir desse momento que o GMT-3 decolou, conquistando um público fiel e ávido por conhecimento.</p>
                </div>
            </div>

            <div className="mt-20 max-w-[700px] m-auto">
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">O Poder da Informação e da Imparcialidade</h2>
                    <p className="text-center text-lg">Um dos pilares do GMT-3 é a imparcialidade e a busca pela verdade. Leandro sempre priorizou a transparência em suas análises e reviews, mesmo que isso tenha gerado atritos com algumas marcas. Sua dedicação em fornecer informações precisas e confiáveis conquistou a confiança de milhares de seguidores, tornando o GMT-3 o maior canal de relógios em língua portuguesa.</p>
                </div>
            </div>

            <div className="flex items-center gap-10 mt-20 flex-col md:flex-row">
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">Os Classificados do GMT-3:<br /> Uma Nova Fronteira</h2>
                    <p className="text-center text-lg">Além do canal no YouTube e do site de e-commerce, o GMT-3 decidiu expandir seus horizontes com os Classificados, uma plataforma que visa unir compradores e vendedores de relógios de forma segura e confiável. O objetivo é criar um ambiente onde a credibilidade e a qualidade sejam prioridades, proporcionando negociações transparentes e satisfatórias para ambas as partes.</p>
                </div>
                <div className="h-[300px] w-[400px] border border-mono max-w-full">

                </div>
            </div>

            <div className="mt-20 mb-20">
                <div className="">
                    <h2 className="text-2xl font-bold text-center mb-4">A Responsabilidade e a Segurança dos Usuários</h2>
                    <p className="text-center text-lg">Com o lançamento dos Classificados, o GMT-3 oferece suporte para garantir um ambiente seguro e autêntico, selecionando vendedores confiáveis e intermediando negociações sempre que necessário.</p>
                </div>
                <div className="h-[300px] w-[400px] border border-mono m-auto mt-10 max-w-full">

                </div>
            </div>

            <div className="mt-20">
                <div className="">
                    <h2 className="text-2xl font-bold text-center mb-4">O Impacto Positivo na Comunidade</h2>
                    <p className="text-center text-lg">Além de ser uma fonte de informação e entretenimento para os entusiastas de relógios, o GMT-3 tem impactado positivamente a vida de muitas pessoas. Através de consultorias online, Leandro e sua equipe têm ajudado indivíduos a realizar seus sonhos de adquirir o relógio perfeito, evitando armadilhas e falsificações no mercado.</p>
                </div>
            </div>

            <div className="flex items-center gap-10 mt-20 flex-col md:flex-row">
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">O Reconhecimento e a Gratidão dos Seguidores</h2>
                    <p className="text-center text-lg">Uma das maiores recompensas para o GMT-3 é o reconhecimento e a gratidão dos seguidores. Centenas de mensagens de agradecimento e feedback positivo demonstram o impacto que o trabalho da marca tem na vida das pessoas. Esses testemunhos são a prova do comprometimento e da qualidade do serviço oferecido pelo GMT-3.</p>
                </div>
                <div className="h-[300px] w-[400px] border border-mono max-w-full">

                </div>
            </div>

            <div className="flex items-center gap-10 mt-20 flex-col md:flex-row">
                <div className="flex-1 max-w-[700px] m-auto">
                    <h2 className="text-2xl font-bold text-center mb-4">O Futuro do GMT-3: Inovação e Expansão</h2>
                    <p className="text-center text-lg">Com uma base sólida de seguidores e uma reputação impecável, o GMT-3 está pronta para enfrentar novos desafios e expandir suas operações. O foco em oferecer um serviço de excelência e em garantir a satisfação dos clientes continuará sendo a prioridade da marca, que busca sempre inovar e se adaptar às demandas do mercado.</p>
                </div>
            </div>

            <p className="text-lg text-center my-10 max-w-[700px] m-auto">Em resumo, o GMT-3 é muito mais do que um canal de relógios. É uma comunidade de apaixonados por horologia, guiada pela paixão, pela transparência e pela dedicação em oferecer o melhor para seus seguidores. Com os Classificados, a marca se consolida como uma referência no mercado, proporcionando segurança e confiança para os amantes de relógios em todo o Brasil.</p>
        </main>
    )
}
