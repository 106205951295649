import { useEffect, useState } from "react";
import { IWatch } from "../watches/adminWatches";
import AdminModal from "../../../core/admin/adminModal";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function SoldWatches({ watches }: { watches: IWatch[] }) {

    const [infosModal, setInfosModal] = useState(false);
    const [watch, setWatch] = useState<IWatch | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [buyerEmail, setBuyerEmail] = useState("");


    useEffect(() => {
        if(infosModal){
            getBuyer();
        }
        // eslint-disable-next-line
    }, [infosModal])

    const getBuyer = async () => {
        try {
            if (!watch) {
                return null;
            }
            setLoading(true);

            const docRef = doc(db, "users", watch.buyer);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                setLoading(false);
                setError(true);
                return null;
            }
            const data = docSnap.data();
            if (!data) {
                setLoading(false);
                setError(true);
                return null;
            }
            setBuyerEmail(data.email);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
            setError(true);
        }
    }

    const handleModal = (w: IWatch) => {
        setWatch(w);
        setInfosModal(true);
        setBuyerEmail("");
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-10 mt-4">
            <h2 className="text-center font-bold text-lg mb-4">Vendidos</h2>
            {
                watches.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">Marca</th>
                                <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                <th className="border border-[#aaaaaa] p-2">Ano</th>
                                <th className="border border-[#aaaaaa] p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                watches.map((w, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                        <td className="border border-[#aaaaaa] p-2"><button onClick={() => handleModal(w)} className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]">Ver</button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p className="text-center">Nenhum relógio listado</p>
            }
            {
                infosModal ?
                    <AdminModal close={() => setInfosModal(false)}>
                        <div className="bg-moninho grid grid-cols-[100px_1fr] border w-full md:w-[700px] m-auto px-4">
                            <p className="p-2 border-b font-bold">Serial</p>
                            <p className="p-2 border-b text-center">{watch?.serial.toUpperCase()}</p>
                            <p className="p-2 border-b font-bold">Marca</p>
                            <p className="p-2 border-b text-center">{watch?.brand}</p>
                            <p className="p-2 border-b font-bold">Modelo</p>
                            <p className="p-2 border-b text-center">{watch?.model}</p>
                            <p className="p-2 border-b font-bold">Ano</p>
                            <p className="p-2 border-b text-center">{watch?.year}</p>
                        </div>
                        {
                            loading ?
                                <div className="w-fit m-auto">
                                    <ClockAnimation />
                                </div>
                                : error ?
                                    <p className="text-center text-red-500">Erro ao obter usuário comprador</p>
                                    :
                                    <div className="w-fit m-auto">
                                        <p className="text-center text-lg font-bold mt-4 mb-2">Comprador</p>
                                        <p className="bg-white border border-mono text-lg text-center px-4 py-2">{buyerEmail}</p>
                                    </div>
                        }
                    </AdminModal>
                    : null
            }
        </div>
    )
}
