import imageCompression from "browser-image-compression";

export async function compressImage(image: File){

    const compressOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 500,
        useWebWorker: true
    };

    const compressedImage = await imageCompression(image, compressOptions);
    return compressedImage;
}