import { Dispatch, SetStateAction, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebase";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import PlusIcon from "../../../../img/svg/plusIcon";


export default function AdFileInput({ adId, isDisabled = true, saveImgUrl, loading, setLoading, mobile }: { adId: string, isDisabled?: boolean, saveImgUrl: (imgField: string, imgUrl: string, nextNumber?: number) => Promise<undefined | null>, loading: boolean, setLoading: Dispatch<SetStateAction<boolean>>, mobile: boolean }) {

    const [picText, setPicText] = useState("");
    const [picValue, setPicValue] = useState<File | null>(null);
    const [picSize, setPicSize] = useState(0);
    // const [prog, setProg] = useState(0);
    // const [cProg, setCProg] = useState(0);
    const [hasError, setHasError] = useState(false);

    const sendImage = async (e: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            setHasError(false);
            let imgUrl = "";
            if (!picValue) {
                setLoading(false);
                return null;
            }
            if (picSize > 5) {
                setLoading(false);
                return null;
            }
            const storageRef = ref(storage, `ads/${adId}/${picText}`);
            const uploadTask = await uploadBytesResumable(storageRef, picValue);
            imgUrl = await getDownloadURL(uploadTask.ref);
            // uploadTask.on("state_changed", (snapshot) => {
            //     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //     setProg(progress);
            // }, (error) => {
            //     console.log(error);
            //     setHasError(true);
            //     setLoading(false);
            // }, () => {
            //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //         imgUrl = downloadURL
            //     })
            // });
            saveImgUrl(mobile ? "mImgUrl" : "imgUrl", imgUrl);
            onCancel();
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    const onChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const files = e.target.files;
            if (!files) {
                return null;
            }
            const file = files[0];
            setPicSize(file.size / 1024000);
            setPicValue(file);
            setPicText(file.name);
        } catch (error) {
            console.log(error);
        }
    }

    const onCancel = () => {
        setPicValue(null);
        setPicText("");
        setPicSize(0);
    }

    return (
        <div>
            <div className="flex w-32 h-32 border border-mono rounded items-center m-auto">
                {
                    loading ? 
                    <ClockAnimation/>
                    : !picValue ?
                        <div className="w-full h-full m-auto relative">
                            <label title="Adicionar" htmlFor="file-input" className="rounded py-1 px-2 absolute w-full h-full top-0 text-center flex justify-center cursor-pointer"><PlusIcon fill="#B3B3B3"/></label>
                            <input id="file-input" type="file" disabled={isDisabled} className="hidden" onChange={e => onChoose(e)} />
                        </div>
                        :
                        <div className="flex flex-col justify-center gap-4 m-auto">
                            <button onClick={onCancel} className="bg-red-500 text-white m-auto px-2 py-1">Cancelar</button>
                            <button className="bg-black text-white m-auto w-fit px-2 py-1 disabled:bg-[#ccc] hover:bg-[#333333] active:bg-black" disabled={isDisabled || loading || !picValue} onClick={sendImage}>Enviar</button>
                        </div>
                }
            </div>
            <p className={`${isDisabled ? "text-[#ccc]" : picSize > 5 ? "text-red-500" : "text-black"} text-center`}>{picText} {picSize ? `(${picSize.toFixed(2)}MB)` : null}</p>
            {
                hasError ?
                    <p className="text-red-500 text-center">Erro no envio!</p>
                    : null
            }

        </div>
    )
}