import { Link } from "react-router-dom";
import { useAuth } from "../../../context/authProvider"
import SettingsIcon from "../../../img/svg/settingsIcon";
import { formatPhoneNumber } from "../../../utils/functions";
import MyList from "./myList";
import MyPurchases from "./myPurchases";
import { useState } from "react";
import LoadingBalls from "../../core/loadingBalls";

export default function MyArea() {

    const { user, logOut, updateUserPhone } = useAuth();

    const [phone, setPhone] = useState(user?.phoneNumber || "");
    const [editPhone, setEditPhone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);

    const handleCancel = () => {
        setPhone(user?.phoneNumber || "");
        setEditPhone(false);
    }

    const handlePhone = (value: string) => {
        if (value.length > 14) {
            return null;
        }
        if (value === "") {
            setPhone("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setPhone(value);
    }

    const doEditPhone = async () => {
        if (phone === user?.phoneNumber) {
            return null;
        }
        setLoading(true);
        const update = await updateUserPhone(phone);
        if (!update) {
            setLoading(false);
            return null;
        }
        setEditPhone(false);
        setLoading(false);
    }

    return (
        <main className="px-4 pt-10 pb-20 mb-20 md:px-20 relative min-h-[80vh]">
            {
                user?.roles.includes("admin") || user?.roles.includes("advertiser") ?
                    <Link to="/admin" className="flex gap-2 items-center absolute top-4 right-4 bg-primary px-4 py-2 rounded">
                        <SettingsIcon fill="#fff" />
                        <p className="text-sm text-white hidden md:block">Administração</p>
                    </Link>
                    : null
            }
            <div className="mb-10">
                <div className="flex justify-center items-center gap-4">
                    <h1 className="text-2xl text-center font-[500]">Minha Conta</h1>
                    <button className="px-2 bg-primary text-secondary rounded" onClick={logOut}>Sair</button>
                </div>
                <p className="text-center">Bem vindo de volta, <strong>{user?.name}</strong>!</p>
            </div>
            <div className="flex flex-col md:flex-row mt-4 gap-4">
                <div className="flex flex-row md:flex-col border border-mono rounded h-fit text-nowrap">
                        <p onClick={() => setTab(0)} className="flex-1 p-2 text-center border-r md:border-r-0 md:border-b border-mono cursor-pointer" style={{backgroundColor: tab === 0 ? "#DDDDDD" : "#fff"}}>Informações</p>
                        <p onClick={() => setTab(1)} className="flex-1 p-2 text-center border-r md:border-r-0 md:border-b border-mono cursor-pointer" style={{backgroundColor: tab === 1 ? "#DDDDDD" : "#fff"}}>Minha lista</p>
                        <p onClick={() => setTab(2)} className="flex-1 p-2 text-center cursor-pointer" style={{backgroundColor: tab === 2 ? "#DDDDDD" : "#fff"}}>Minhas compras</p>
                </div>
                {
                    tab === 0 ?
                        <div className="flex flex-col items-center w-full m-auto m-0 border border-mono rounded">
                            <h2 className="text-[#939393] text-xl border-b border-mono text-center p-2 w-full">Informações da conta</h2>
                            <div className="py-4">
                                
                                <div className="flex w-full">
                                    <p>Email:</p>
                                    <p className="flex-1 text-center">{user?.email}</p>
                                </div>

                                <div className="flex items-center gap-4 mt-4">
                                    <p className="">Telefone: </p>
                                    <input disabled={!editPhone} className="disabled:bg-moninho border border-primary px-2 py-1 rounded text-center" type="text" placeholder="Não definido" value={!editPhone ? formatPhoneNumber(phone?.toString()) : phone} onChange={e => handlePhone(e.target.value)} />
                                    {
                                        loading ?
                                            <div>
                                                <LoadingBalls width={70} />
                                            </div>
                                            :
                                            editPhone ?
                                                <div className="flex w-fit gap-4">
                                                    <button onClick={handleCancel} className="flex-1 px-2 py-1 border border-primary bg-moninho rounded">Cancelar</button>
                                                    <button onClick={doEditPhone} className="flex-1 px-2 py-1 bg-secondary text-white rounded">Alterar</button>
                                                </div>
                                                :
                                                <button onClick={() => setEditPhone(true)} className="px-2 py-1 border border-primary bg-moninho rounded">Alterar</button>
                                    }
                                </div>
                            </div>
                        </div>
                        : tab === 1 ?
                            <MyList />
                            : tab === 2 ?
                                <MyPurchases />
                                : null
                }
            </div>
        </main>
    )
}
