import { useEffect, useState } from "react"
import ConfigVideoModal from "./configVideoModal";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import AdminToast from "../../../core/admin/adminToast";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import Switch from "../../../core/switch";
import AdminModal from "../../../core/admin/adminModal";
import HeaderHome from "./headerHome";

export interface IVideo {
    id: string;
    link: string;
    title: string;
    description: string;
    active: boolean;
}

export default function AdminHomeConfigs() {

    const [config, setConfig] = useState(false);
    const [video, setVideo] = useState<IVideo | null>(null);
    const [loading, setLoading] = useState(false);
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);
    const [activateModal, setActivateModal] = useState(false);

    useEffect(() => {
        getVideo();
    }, [])

    const getVideo = async () => {
        try {
            setLoading(true);
            const docSnap = await getDocs(collection(db, "videos"));
            if (docSnap.empty) {
                setVideo(null);
                setLoading(false);
                return null;
            }
            const docs = docSnap.docs.map(d => {
                return {
                    id: d.id,
                    link: d.data()?.link,
                    title: d.data()?.title,
                    description: d.data()?.description,
                    active: d.data()?.active,
                }
            })
            setVideo(docs.filter(d => d?.link)[0]);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setBadToast(true);
        }
    }

    const handleActivation = async () => {
        try{
            if(!video){
                return null;
            }
            setLoading(true);
            const docRef = doc(db, "videos", video.id);
            await updateDoc(docRef, { active: !video.active });
            setLoading(false);
            setGoodToast(true);
            setActivateModal(false);
            getVideo();
        }catch(error){
            console.log(error);
            setLoading(false);
            setBadToast(true);
        }
    }

    return (
        <main className="text-center px-4 pt-4">
            <HeaderHome/>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <div className="bg-[#fafafa] px-4 pt-4 pb-10 relative">
                        <h1 className="text-center font-bold text-xl">Vídeo</h1>
                        <div className="absolute top-4 right-4 border border-mono p-2">
                            <button onClick={() => setActivateModal(true)} className=""><Switch on={video?.active} /></button>
                            <p>{video?.active ? "Visível na home" : "Invisível na home"}</p>
                        </div>
                        <p>Informações sobre o vídeo disponível na página inicial do site</p>
                        <div className="flex gap-4 mt-10">
                            <p className="flex-1 border border-mono px-2 py-1 text-center">{video?.link || "Nenhum link configurado"}</p>
                            <button onClick={() => setConfig(true)} className="bg-[#00bb00] active:bg-[#88bb88] hover:bg-[#88bb88] text-white px-2 py-1">Configurar</button>
                        </div>
                        <p className="text-left border border-mono px-1 py-1 mt-4">{video?.title || "Nenhum título configurado"}</p>
                        <p className="text-left border border-mono px-1 py-1 mt-4">{video?.description || "Nenhuma descrição configurada"}</p>
                    </div>
            }
            {
                config ?
                    <ConfigVideoModal video={video} close={() => setConfig(false)} refresh={getVideo} goodToast={() => setGoodToast(true)} badToast={() => setBadToast(true)} />
                    : null
            }
            {
                activateModal ?
                    <AdminModal close={() => setActivateModal(false)}>
                        <h2 className="text-center text-xl font-bold">{video?.active ? "Deseja desativar o vídeo?" : "Deseja ativar o vídeo?"}</h2>
                        <p className="mb-8">{video?.active ? "A seção do vídeo na página inicial do site deixará de ficar visível" : "A seção do vídeo na página inicial do site ficará visível"}</p>
                        {
                            loading ?
                                <ClockAnimation />
                                :
                                <div className="flex gap-4">
                                    <button onClick={handleActivation} className="flex-1 text-white bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] py-1">Sim</button>
                                    <button onClick={() => setActivateModal(false)} className="flex-1 text-white bg-black hover:bg-[#333] active:bg-[#333] py-1">Cancelar</button>
                                </div>
                        }
                    </AdminModal>
                    : null
            }
            <AdminToast message="Vídeo atualizado!" toast={goodToast} setToast={setGoodToast} color="#00bb00" />
            <AdminToast message="Erro!" toast={badToast} setToast={setBadToast} color="#ff0000" />
        </main>
    )
}
