import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, signOut, signInWithPopup } from 'firebase/auth';
import { setDoc, doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { auth, db, googleProvider } from "../firebase";
import GMTLoading from "../components/core/gmtLoading";

interface AuthContextType {
    user: IUser | null;
    userId: string | null;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    signUp: (name: string, phoneNumber: string, email: string, password: string) => Promise<boolean>;
    logIn: (email: string, password: string) => Promise<boolean>;
    logOut: () => Promise<void>;
    googleLogIn: () => Promise<null|undefined>;
    updateUserPhone: (phone: string) => Promise<boolean | null>;
    updateUserContact: (field: string, value: string) => Promise<boolean | null>;
}

export interface IUser {
    name: string;
    phoneNumber: string | null;
    email: string;
    description?: string;
    instagram?: string;
    facebook?: string;
    site?: string;
    slug?: string;
    roles: [string];
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {

    const [user, setUser] = useState<IUser | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged((u) => {
            const uid = u?.uid;
            if (uid) {
                setUserId(uid);
                getUserAdditionalData(uid);
            }else{
                setPending(false);
            }
        })
    }, [])

    async function getUserAdditionalData(uid: string) {
        // const usersCollection = collection(db, "users");
        // const q = query(usersCollection, where("userId", "==", uid))
        // const usersDocs = await getDocs(q);
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            setUser({
                name: userData.name,
                email: userData.email,
                phoneNumber: userData.phoneNumber,
                roles: userData.roles,
                slug: userData.slug,
                instagram: userData.instagram,
                facebook: userData.facebook,
                site: userData.site,
                description: userData.description
            })
        }
        setPending(false);
    }

    async function updateUserPhone(phone: string) {
        try {
            if(!userId || !user){
                return null;
            }
            const docRef = doc(db, "users", userId);
            await updateDoc(docRef, {phoneNumber: phone});
            setUser({...user, phoneNumber: phone});
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function updateUserContact(field: string, value: string) {
        try {
            if(!userId || !user){
                return null;
            }
            if(field !== "instagram" && field !== "facebook" && field !== "site" && field !== "description"){
                return null
            }
            const docRef = doc(db, "users", userId);
            let updateFields: {[key: string]: string} = {};
            updateFields[field] = value;
            await updateDoc(docRef, updateFields);
            setUser({...user, ...updateFields});
            return true;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function signUp(name: string, phoneNumber: string, email: string, password: string) {
        try {
            // criar usuario
            const user = await createUserWithEmailAndPassword(auth, email, password);
            const userData = user.user;

            await updateProfile(userData, {
                displayName: name
            })

            // salvar dados adicionais
            await setDoc(doc(db, "users", userData.uid), {
                name: name,
                email: email,
                phoneNumber: phoneNumber,
                roles: ["customer"],
                createdAt: serverTimestamp()
            });
            // colocar user no context
            setUser({ name, phoneNumber, email, roles: ["customer"] });
            setUserId(userData.uid);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function logIn(email: string, password: string) {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function logOut() {
        try {
            await signOut(auth);
            setUser(null);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    async function googleLogIn() {
        try{
            const user = await signInWithPopup(auth, googleProvider);
            const userData = user.user;

            const existantUser = await getDoc(doc(db, "users", userData.uid));
            if(existantUser.exists()){
                return null;
            }
            // salvar dados adicionais
            await setDoc(doc(db, "users", userData.uid), {
                name: userData.displayName,
                email: userData.email,
                phoneNumber: userData.phoneNumber,
                roles: ["customer"],
                createdAt: serverTimestamp()
            });
            // // colocar user no context
            if(userData.displayName && userData.email){
                setUser({ name: userData.displayName, phoneNumber: userData.phoneNumber, email: userData.email, roles: ["customer"] });
                setUserId(userData.uid);
            }
        }catch(error){
            console.log(error);
        }
    }

    return (
        <AuthContext.Provider value={
            {
                user, setUser, userId,
                signUp, logIn, logOut,
                googleLogIn, updateUserPhone,
                updateUserContact
            }
        }>
            {
                pending ?
                <GMTLoading/>
                : children
            }
        </AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;
