import { Dispatch, SetStateAction, useState } from "react";
import AdminModal from "../../../core/admin/adminModal";
import { IAd } from "./adminAds";
import AdImagesUploader from "./adImagesUploader";
import XIcon from "../../../../img/svg/xIcon";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { db } from "../../../../firebase";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import ClockAnimation from "../../../../img/svg/clockAnimation";
import { createDate, parseFirebaseTimestamp } from "../../../../utils/functions";
import Switch from "../../../core/switch";


export default function CompleteAdModal({ close, editableAd, setEditableAd, refresh }: { close: () => void, editableAd: IAd, setEditableAd: Dispatch<SetStateAction<IAd | null>>, refresh: () => void }) {

    const [loading, setLoading] = useState(false);
    const [deletion, setDeletion] = useState(false);
    const [mobileDeletion, setMobileDeletion] = useState(false);
    const [name, setName] = useState(editableAd?.name || "");
    const [link, setLink] = useState(editableAd?.link || "");
    const [until, setUntil] = useState(parseFirebaseTimestamp(editableAd?.until) || "");
    const [alter, setAlter] = useState(false);
    const [feedback, setFeedback] = useState("");

    const deleteImage = async (imageUrl: string, field: string) => {
        if (!editableAd?.id) {
            return null;
        }
        // setDeleteLoading(imageIdx);
        setLoading(true);
        const storage = getStorage();
        try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);

        } catch (error) {
            console.log(error);
        }
        try {
            const docRef = doc(db, "ads", editableAd.id);
            let fields: { [key: string]: any } = {};
            fields[field] = "";
            fields["visible"] = false;
            await updateDoc(docRef, fields);
            refresh();
            setEditableAd({...editableAd, ...fields});
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleLink = (link: string) => {
        if(!link.includes("https://") && !link.includes("http://")){
            return "https://"+link;
        }
        return link;
    }

    const handleUpdate = async () => {
        try {
            if (name === editableAd.name && link === editableAd.link && until === editableAd?.until.toString()) {
                return null;
            }
            setLoading(true);
            const docRef = doc(db, "ads", editableAd?.id);
            await updateDoc(docRef, { link: handleLink(link), until: createDate(until), name });
            refresh();
            setEditableAd({...editableAd, link: handleLink(link), until: Timestamp.fromDate(createDate(until)), name});
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleCancel = () => {
        setName(editableAd?.name || "");
        setLink(editableAd?.link || "");
        setUntil(parseFirebaseTimestamp(editableAd?.until) || "")
        setAlter(false);
    }

    const turnAdOn = async () => {
        try {
            setFeedback("");
            if (!editableAd.visible && (!editableAd.name || !editableAd.link || !editableAd.until || !editableAd.imgUrl || !editableAd.mImgUrl)) {
                setFeedback("Preencha todas as informações e envie as imagens para habilitar este anúncio");
                return null;
            }
            setLoading(true);
            const docRef = doc(db, "ads", editableAd?.id);
            await updateDoc(docRef, { visible: !editableAd.visible });
            refresh();
            setEditableAd({...editableAd, visible: !editableAd.visible});
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const finish = (fields: {}) => {
        setEditableAd({...editableAd, ...fields});
    }

    return (
        <AdminModal close={close}>
            <h1 className="text-center text-lg font-bold mb-4">Detalhes do anúncio</h1>
            <div className="border border-mono p-2 mb-2">
                <h2 className="font-bold text-lg text-center">Visibilidade</h2>
                <button className="m-auto block my-4" onClick={turnAdOn}><Switch on={editableAd.visible} /></button>
                <p className="text-center">O anúncio só ficará visível enquanto o botão acima estiver ligado e a data final não tiver passado.</p>
                <p className="text-center text-red-500">{feedback}</p>
            </div>
            <div className="border border-mono p-2">
                <h2 className="font-bold text-lg text-center">Informações</h2>
                {
                    loading ?
                        <ClockAnimation />
                        :
                        <div>
                            <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Nome</h3>
                                <input className="flex-1 text-center px-2" type="text" disabled={!alter} value={name} onChange={e => setName(e.target.value)} placeholder="Nome" />
                            </div>
                            <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Link</h3>
                                <input className="flex-1 text-center px-2" type="text" disabled={!alter} value={link} onChange={e => setLink(e.target.value)} placeholder="Link" />
                            </div>
                            <div className="flex w-full md:w-1/2 m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Data final</h3>
                                <input className="flex-1 text-center px-2" type="text" disabled={!alter} value={until} onChange={e => setUntil(e.target.value)} placeholder="Data de expiração" />
                            </div>
                            <p className="font-bold text-center">A data deve estar no formato dd/mm/aaaa</p>
                            {
                                alter ?
                                    <div className="flex gap-2 w-fit m-auto mt-4">
                                        <button className="px-2 py-1 bg-[#ff3333] active:bg-[#ff9999] hover:bg-[#ff9999] text-white" onClick={handleCancel}>Cancelar</button>
                                        <button onClick={handleUpdate} className="hover:bg-[#88bb88] bg-[#00bb00] active:[#00bb00] text-white px-2 py-1">Salvar</button>
                                    </div>
                                    :
                                    <button onClick={() => setAlter(true)} className="bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7] text-white w-fit m-auto py-1 px-2 mt-4 block">Alterar</button>
                            }
                        </div>
                }
            </div>
            <h2 className="mt-4 font-bold text-lg text-center">Imagens</h2>
            <div className="text-red-700 text-center my-4">
                <p className="font-bold text-lg">Atenção!</p>
                <ul className="text-left w-fit m-auto list-disc">
                    <li>As imagens devem ter 132px de altura;</li>
                    <li>A imagem desktop deve ter no mínimo 768px de largura;</li>
                    <li>A imagem mobile deve ter no máximo 768px de largura;</li>
                    <li>O conteúdo das imagens deve estar centralizado.</li>
                </ul>
            </div>
            <p className="text-center">Imagem Desktop</p>
            {
                loading ?
                    <ClockAnimation />
                    : editableAd?.imgUrl ?
                        <div className="relative w-full h-[132px] overflow-hidden flex items-center bg-black">
                            <img src={editableAd.imgUrl} alt="desktop" className="mx-auto border border-black" />
                            <div className="absolute top-2 right-2">
                                {
                                    deletion ?
                                        <div className="flex gap-2">
                                            <button onClick={() => deleteImage(editableAd?.imgUrl, "imgUrl")} className="px-2 py-1 border border-black bg-[#ff3333] active:bg-[#ff9999] hover:bg-[#ff9999] text-white">Excluir</button>
                                            <button onClick={() => setDeletion(false)} className="px-2 py-1 border border-black bg-white hover:bg-[#ccc] active:bg-[#ccc]">Cancelar</button>
                                        </div>
                                        :
                                        <button onClick={() => setDeletion(true)} className="bg-white border border-black rounded"><XIcon /></button>
                                }
                            </div>
                        </div>
                        :
                        <AdImagesUploader ad={editableAd} update={finish} refresh={refresh} />
            }
            <p className="text-center mt-10">Imagem Mobile</p>
            {
                loading ?
                    <ClockAnimation />
                    : editableAd?.mImgUrl ?
                        <div className="relative w-full h-[132px] overflow-hidden flex items-center bg-black">
                            <img src={editableAd.mImgUrl} alt="desktop" className="mx-auto border border-black" />
                            <div className="absolute top-2 right-2">
                                {
                                    mobileDeletion ?
                                        <div className="flex gap-2">
                                            <button onClick={() => deleteImage(editableAd?.mImgUrl, "mImgUrl")} className="px-2 py-1 border border-black bg-[#ff3333] active:bg-[#ff9999] hover:bg-[#ff9999] text-white">Excluir</button>
                                            <button onClick={() => setMobileDeletion(false)} className="px-2 py-1 border border-black bg-white hover:bg-[#ccc] active:bg-[#ccc]">Cancelar</button>
                                        </div>
                                        :
                                        <button onClick={() => setMobileDeletion(true)} className="bg-white border border-black rounded"><XIcon /></button>
                                }

                            </div>
                        </div>
                        :
                        <AdImagesUploader ad={editableAd} update={finish} refresh={refresh} mobile />
            }
        </AdminModal>
    )
}
