import { Dispatch, SetStateAction, useState } from "react";
import AdminModal from "../../../../core/admin/adminModal";
import AdminButton from "../../../../core/admin/adminButton";
import { useWatches } from "../../../../../context/watchProvider";
import AdminToast from "../../../../core/admin/adminToast";
import ClockAnimation from "../../../../../img/svg/clockAnimation";
import { braceletMaterialFields, caseMaterialFields, glassFields, movementFields, stateFields } from "../../../../../utils/fields";
import { IWatch } from "../../watches/adminWatches";
import ImagesUploader from "./imagesUploader";
import ImagesReorderer from "./imagesReorderer";

export default function EditDraftModal({ close, watch, setWatch, finish, refresh }: { close: () => void, watch: IWatch | null, setWatch: Dispatch<SetStateAction<IWatch | null>>, finish: () => void, refresh: () => void }) {

    const { updateWatch, getWatch } = useWatches();

    const [tab, setTab] = useState(0);
    const [badToast, setBadToast] = useState(false);
    const [editGeneral, setEditGeneral] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reorder, setReorder] = useState(false);

    const [brand, setBrand] = useState(watch?.brand || "");
    const [model, setModel] = useState(watch?.model || "");
    const [year, setYear] = useState(watch?.year || "");

    const [serial, setSerial] = useState(watch?.serial || ""); // serial
    const [state, setState] = useState(watch?.state || ""); // estado - select
    const [functions, setFunctions] = useState(watch?.functions || ""); // funções - checkboxes
    const [movement, setMovement] = useState(watch?.movement || ""); // movimento - select
    const [braceletMaterial, setBraceletMaterial] = useState(watch?.braceletMaterial || ""); // material do bracelete - select
    const [caseMaterial, setCaseMaterial] = useState(watch?.caseMaterial || ""); // material da caixa - select
    const [diameter, setDiameter] = useState(watch?.diameter || null); // diametro - number
    const [tightness, setTightness] = useState(watch?.tightness || null); // estanqueidade - number
    const [glass, setGlass] = useState(watch?.glass || ""); // vidro - select
    const [content, setContent] = useState(watch?.content || ""); // conteudo fornecido - text
    const [price, setPrice] = useState(watch?.price || null); // preço - number
    const [description, setDescription] = useState(watch?.description || ""); // outras caracteristicas - text
    const [boxAndDocuments, setBoxAndDocuments] = useState<boolean | null>(watch?.boxAndDocuments);

    const doUpdateWatch = async () => {
        if (!watch?.id) {
            return null
        }
        const wtc = await getWatch(watch.id);
        setWatch(wtc);
        setReorder(false);
    }

    const updateInfos = async () => {
        if (!watch?.id || !serial || !state || !functions || !movement ||
            !braceletMaterial || !caseMaterial ||
            !diameter || !tightness || !glass ||
            !price || boxAndDocuments === null
        ) {
            return null
        }
        const update = await updateWatch(watch.id, {
            serial,
            state,
            functions,
            movement,
            braceletMaterial,
            caseMaterial,
            diameter: parseInt(diameter),
            tightness: parseInt(tightness),
            glass,
            content,
            price: parseFloat(price.toString().replace(",", ".")),
            description,
            boxAndDocuments: boxAndDocuments
        });
        if (update) {
            finish();
        }
        setBadToast(true);
    }

    const handleYear = (value: string) => {
        if (value === "") {
            setYear("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setYear(value);
    }
    const handleDiameter = (value: string) => {
        if (value === "") {
            setDiameter("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setDiameter(value);
    }
    const handleTightness = (value: string) => {
        if (value === "") {
            setTightness("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setTightness(value);
    }
    const handlePrice = (value: string) => {
        if (value === "") {
            setPrice("");
            return null;
        }
        const rawPrice = value.replace(".", "");
        if (!/^\d+$/.test(rawPrice)) {
            return null;
        }
        const numericPrice = parseInt(rawPrice);
        const stringPrice = numericPrice.toString().split("");
        if (stringPrice.length < 3) {
            setPrice(`0.${stringPrice.join("")}`);
            return null;
        }
        setPrice([...stringPrice.slice(0, -2), ".", ...stringPrice.slice(-2)].join(""));
    }

    const sendToApproval = async () => {
        if (!watch?.id || propsCount < 11) {
            return null;
        }
        const update = await updateWatch(watch.id, { status: "approval" });
        if (update) {
            finish();
        }
    }

    const updateGeneralInfo = async () => {
        setLoading(true);
        try {
            if (!watch?.id) {
                setLoading(false);
                return null;
            }
            const response = await updateWatch(watch.id, { brand, model, year: parseInt(year) });
            if (!response) {
                setLoading(false);
                return null;
            }
            finish();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleDescription = (value: string) => {
        if (value.length > 422) {
            return null;
        }
        setDescription(value);
    }

    const handleBoxAndDocs = (value: string) => {
        if (value === "sim") {
            setBoxAndDocuments(true);
            return null;
        } if (value === "nao") {
            setBoxAndDocuments(false);
            return null;
        }
        setBoxAndDocuments(null);
    }

    const isSaveButtonDisabled =
        (state === watch?.state &&
            serial === watch?.serial &&
            functions === watch?.functions &&
            movement === watch?.movement &&
            braceletMaterial === watch?.braceletMaterial &&
            caseMaterial === watch?.caseMaterial &&
            diameter === watch?.diameter &&
            tightness === watch?.tightness &&
            glass === watch?.glass &&
            content === watch?.content &&
            price === watch?.price &&
            description === watch?.description &&
            boxAndDocuments === watch?.boxAndDocuments) ||
        serial === "" ||
        state === "" ||
        functions === "" ||
        movement === "" ||
        braceletMaterial === "" ||
        caseMaterial === "" ||
        diameter === "" ||
        tightness === "" ||
        glass === "" ||
        price === "" ||
        boxAndDocuments === null;

    let propsCount = 0;
    if (watch?.serial) {
        propsCount++;
    }
    if (watch?.state) {
        propsCount++;
    }
    if (watch?.functions) {
        propsCount++;
    }
    if (watch?.movement) {
        propsCount++;
    }
    if (watch?.braceletMaterial) {
        propsCount++;
    }
    if (watch?.caseMaterial) {
        propsCount++;
    }
    if (watch?.diameter) {
        propsCount++;
    }
    if (watch?.tightness) {
        propsCount++;
    }
    if (watch?.glass) {
        propsCount++;
    }
    if (watch?.content) {
        propsCount++;
    }
    if (watch?.price) {
        propsCount++;
    }
    if (watch?.boxAndDocuments || watch?.boxAndDocuments === false) {
        propsCount++;
    }

    return (
        <AdminModal close={refresh}>
            <div className="flex absolute top-0 left-0 w-[90%]">
                <div className="border border-[#ccc] p-2 w-1/4 md:w-1/5 cursor-pointer select-none text-center" style={{ backgroundColor: tab === 0 ? "#ccc" : undefined, fontWeight: tab === 0 ? "bold" : "normal" }} onClick={() => setTab(0)}>Geral</div>
                <div className="border border-[#ccc] p-2 w-1/4 md:w-1/5 cursor-pointer select-none text-center" style={{ backgroundColor: tab === 1 ? "#ccc" : undefined, fontWeight: tab === 1 ? "bold" : "normal" }} onClick={() => setTab(1)}>Informações</div>
                <div className="border border-[#ccc] p-2 w-1/4 md:w-1/5 cursor-pointer select-none text-center" style={{ backgroundColor: tab === 2 ? "#ccc" : undefined, fontWeight: tab === 2 ? "bold" : "normal" }} onClick={() => setTab(2)}>Imagens</div>
                <div className="border border-[#ccc] p-2 w-1/4 md:w-1/5 cursor-pointer select-none text-center relative" style={{ backgroundColor: tab === 3 ? "#ccc" : undefined, fontWeight: tab === 3 ? "bold" : "normal" }} onClick={() => setTab(3)}>
                    {
                        watch?.comment ?
                            <div className="absolute top-2 right-4 w-3 h-3 bg-red-500 rounded-full"></div>
                            : null
                    }
                    Comentários
                </div>
            </div>

            {
                tab === 0 ?
                    <div className="flex flex-col justify-center items-center gap-2 mt-10">
                        {
                            loading ?
                                <ClockAnimation />
                                :
                                <div className="flex w-full justify-center items-center gap-4">
                                    <div className="border border-black w-full md:w-1/2 px-2 py-2">
                                        <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Marca</p><input type="text" className="px-2 font-bold w-full text-center" value={brand} onChange={e => setBrand(e.target.value)} disabled={!editGeneral} /></div>
                                        <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Modelo</p><input type="text" className="px-2 font-bold w-full text-center" value={model} onChange={e => setModel(e.target.value)} disabled={!editGeneral} /></div>
                                        <div className="flex text-lg border border-black mb-1"><p className="w-fit lg:w-1/4 text-center px-2 bg-[#444] text-white">Ano</p><input type="text" className="px-2 font-bold w-full text-center" value={year} onChange={e => handleYear(e.target.value)} disabled={!editGeneral} /></div>
                                    </div>
                                    {
                                        editGeneral ?
                                            <div className="flex flex-col gap-2">
                                                <button className="text-white px-2 bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#00bb00] h-fit py-2" onClick={updateGeneralInfo}>Salvar</button>
                                                <button className="text-white px-2 bg-[#ff3333] hover:bg-[#ff9999] active:bg-[#ff3333] h-fit py-2" onClick={() => setEditGeneral(false)}>Cancelar</button>
                                            </div>
                                            :
                                            <button className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7] h-fit py-4" onClick={() => setEditGeneral(true)}>Editar</button>
                                    }
                                </div>
                        }
                        <div className="w-fit border border-black mt-2 px-6 py-2">
                            <p className="font-bold text-lg">Nº de imagens: {watch?.images?.length || 0}</p>
                        </div>
                        <div className="w-fit border border-black my-2 px-6 py-2">
                            <p className="font-bold text-lg">Informações preenchidas: {propsCount}/12</p>
                        </div>
                        <AdminButton onClick={sendToApproval} disabled={propsCount < 11}>Enviar para aprovação</AdminButton>
                    </div>
                    : tab === 1 ?
                        <div className="flex flex-col justify-center items-center gap-2 mt-10">
                            {/* Serial */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Serial<strong className="text-red-500">*</strong></h3>
                                <input type="text" value={serial} onChange={e => setSerial(e.target.value.toUpperCase())} className="flex-1 text-center" />
                            </div>
                            {/* Estado */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Estado<strong className="text-red-500">*</strong></h3>
                                <select value={state} onChange={e => setState(e.target.value)} className="flex-1 text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(stateFields).map((k, i) => (
                                            <option key={i} value={k}>{stateFields[k]}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* Funções */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Funções<strong className="text-red-500">*</strong></h3>
                                <input type="text" value={functions} onChange={e => setFunctions(e.target.value)} className="flex-1 text-center" />
                            </div>
                            {/* movimento - select */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Movimento<strong className="text-red-500">*</strong></h3>
                                <select value={movement} onChange={e => setMovement(e.target.value)} className="flex-1 text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(movementFields).map((k, i) => (
                                            <option key={i} value={k}>{movementFields[k]}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* material do bracelete - select */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Material do Bracelete<strong className="text-red-500">*</strong></h3>
                                <select value={braceletMaterial} onChange={e => setBraceletMaterial(e.target.value)} className="flex-1 text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(braceletMaterialFields).map((k, i) => (
                                            <option key={i} value={k}>{braceletMaterialFields[k]}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* material da caixa - select */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Material da Caixa<strong className="text-red-500">*</strong></h3>
                                <select value={caseMaterial} onChange={e => setCaseMaterial(e.target.value)} className="flex-1 text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(caseMaterialFields).map((k, i) => (
                                            <option key={i} value={k}>{caseMaterialFields[k]}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* diametro - number */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Diâmetro (mm)<strong className="text-red-500">*</strong></h3>
                                <input type="text" value={diameter || ""} onChange={e => handleDiameter(e.target.value)} className="flex-1 text-center" />
                            </div>
                            {/* estanqueidade - number */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Estanqueidade (ATM)<strong className="text-red-500">*</strong></h3>
                                <input type="text" value={tightness || ""} onChange={e => handleTightness(e.target.value)} className="flex-1 text-center" />
                            </div>
                            {/* vidro - select */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Vidro<strong className="text-red-500">*</strong></h3>
                                <select value={glass} onChange={e => setGlass(e.target.value)} className="flex-1 text-center">
                                    <option value="">-</option>
                                    {
                                        Object.keys(glassFields).map((k, i) => (
                                            <option key={i} value={k}>{glassFields[k]}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* possui caixa e documentos originais - boolean */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Caixa e documentos originais<strong className="text-red-500">*</strong></h3>
                                <select value={boxAndDocuments ? "sim" : boxAndDocuments === false ? "nao" : ""} onChange={e => handleBoxAndDocs(e.target.value)} className="flex-1 text-center">
                                    <option value="" hidden>-</option>
                                    <option value="sim">Sim</option>
                                    <option value="nao">Não</option>
                                </select>
                            </div>
                            {/* conteudo fornecido - text */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Conteúdo adicional<strong className="text-red-500"></strong></h3>
                                <input type="text" value={content} onChange={e => setContent(e.target.value)} className="flex-1 text-center" placeholder="Conteúdo adicional" />
                            </div>
                            {/* preço - number */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Preço (R$)<strong className="text-red-500">*</strong></h3>
                                <input type="text" value={price || ""} onChange={e => handlePrice(e.target.value)} className="flex-1 text-center" />
                            </div>
                            {/* descrição - text */}
                            <div className="flex w-full m-auto border border-black mb-2">
                                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Descrição</h3>
                                <textarea value={description} onChange={e => handleDescription(e.target.value)} className="flex-1 text-center h-20 resize-none" />
                            </div>
                            <p className="text-red-500 w-full">* informações obrigatórias</p>

                            <button onClick={updateInfos} disabled={isSaveButtonDisabled} className="px-4 py-2 bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#00bb00] text-white disabled:bg-[#888]">Salvar</button>

                        </div>
                        : tab === 2 ?
                            <div className="flex flex-col justify-center gap-4 items-center mt-10">
                                <h2 className="text-center"><span className="text-lg font-bold">Upload de imagens</span><br />A ordem aqui determinada será a ordem de exibição<br />Apenas imagens <strong>menores que 5MB</strong></h2>
                                {
                                    !reorder ?
                                        <button onClick={() => setReorder(true)} className="bg-[#5804ab] text-white px-4 py-1 hover:bg-[#6d03d6] active:bg-[#6d03d6]">Reordenar</button>
                                        :
                                        <button onClick={() => setReorder(false)} className="bg-[#000] text-white px-4 py-1 hover:bg-[#333] active:bg-[#333]">Cancelar</button>
                                }
                                {
                                    reorder ?
                                        <ImagesReorderer watch={watch} setWatch={setWatch} update={doUpdateWatch} />
                                        :
                                        <ImagesUploader watch={watch} update={doUpdateWatch} />
                                }
                            </div>
                            : tab === 3 ?
                                <div className="mt-10">
                                    <h3 className="text-center text-lg font-bold">Comentários do administrador</h3>
                                    <p className="border border-black mt-4 p-2 text-center">{watch?.comment}</p>
                                </div>
                                : null
            }
            <AdminToast toast={badToast} setToast={setBadToast} message="Algo deu errado" color="#ff3333" />
        </AdminModal>
    )
}