import { useState } from "react";
import AdminModal from "../../../../core/admin/adminModal";
import { useWatches } from "../../../../../context/watchProvider";
import { useAuth } from "../../../../../context/authProvider";
import { collectionHasDoc, randomGenerator } from "../../../../../utils/functions";
import ClockAnimation from "../../../../../img/svg/clockAnimation";

export default function CreateDraftModal({ close, finish }: { close: () => void, finish: () => void }) {

    const { createWatch } = useWatches();
    const { userId } = useAuth();

    const [loading, setLoading] = useState(false);
    const [errored, setErrored] = useState(false);

    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [year, setYear] = useState("");

    const handleStep1 = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setLoading(true);
            if (!userId) {
                throw new Error("Autenticação necessária");
            }
            // criar um slug não utilizado ainda
            let randomStr = "";
            while (true) {
                randomStr = randomGenerator();
                const hasDoc = await collectionHasDoc("watches", "slug", randomStr);
                if (!hasDoc) {
                    break;
                }
            }
            const docId = await createWatch(userId, brand, model, parseInt(year), randomStr);
            if (!docId) {
                setLoading(false);
                setErrored(true);
                return null;
            }
            setErrored(false);
            setLoading(false);
            finish();
        } catch (error) {
            setLoading(false);
            setErrored(true);
        }
    }

    const handleYear = (value: string) => {
        if(value === ""){
            setYear("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        setYear(value);
    }

    return (
        <AdminModal close={close}>
            <h1 className="text-center text-lg font-bold mb-4">Criar rascunho - Dados iniciais</h1>

            <div className="flex-1 border border-black py-4">
            <form onSubmit={e => handleStep1(e)} className="flex flex-col">
                <div className="flex w-1/3 m-auto border border-black mb-2">
                    <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Marca</h3>
                    <input className="flex-1 text-center px-2" type="text" value={brand} onChange={e => setBrand(e.target.value)} placeholder="Marca" />
                </div>
                <div className="flex w-1/3 m-auto border border-black mb-2">
                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Modelo</h3>
                    <input className="flex-1 text-center px-2" type="text" value={model} onChange={e => setModel(e.target.value)} placeholder="Modelo" />
                </div>
                <div className="flex w-1/3 m-auto border border-black mb-2">
                <h3 className="w-1/4 text-center px-2 bg-[#444] text-white">Ano</h3>
                    <input className="flex-1 text-center px-2" type="text" value={year} onChange={e => handleYear(e.target.value)} placeholder="Ano" />
                </div>
                <button className="bg-[#00bb00] hover:bg-[#88bb88] active:bg-[#88bb88] text-white w-fit m-auto py-1 px-2">Criar</button>
            </form>
            {
                loading ?
                    <ClockAnimation />
                    : null
            }
            {
                errored ?
                    <p className="text-red-500 text-center">Ocorreu um erro na operação</p>
                    : null
            }
        </div>

        </AdminModal>
    )
}