import { useState } from "react"
import ClockAnimation from "../../../../img/svg/clockAnimation";
import ReviewsList from "./reviewsList";
import { collection, getDocs, query, Timestamp, where, WhereFilterOp } from "firebase/firestore";
import { db } from "../../../../firebase";
import AdminToast from "../../../core/admin/adminToast";
import { IRequest } from "./requests";

export interface IReview {
    id: string,
    rate: number,
    review: string,
    createdAt: Date,
    hidden: boolean,
    advertiser: string,
    userId: string,
    watch: string
}

export default function Reviews({ requests }: { requests: IRequest[] | null }) {

    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState("rate");
    const [rate, setRate] = useState("1");
    const [advertiser, setAdvertiser] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [reviews, setReviews] = useState<IReview[] | null>(null);
    const [feedback, setFeedback] = useState("");
    const [goodToast, setGoodToast] = useState(false);
    const [badToast, setBadToast] = useState(false);

    const operators: { [key: string]: WhereFilterOp } = {
        "==": "==",
        ">=": ">=",
        ">": ">",
        "<": "<",
        "<=": "<=",
    };

    const getReviews = async () => {
        try {
            setLoading(true);
            setFeedback("");
            let operator = "==";
            let docs: IReview[] = [];
            if (filter === "createdAt") {
                operator = ">=";
            }
            const q = query(collection(db, "reviews"), where(filter, operators[operator], handleFilterValue(filter)));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                setLoading(false);
                setFeedback("Nenhum resultado encontrado");
                setReviews(null);
                return null;
            }
            docsSnap.forEach(document => {
                docs.push({
                    id: document.id,
                    rate: document.data().rate,
                    review: document.data().review,
                    createdAt: document.data().createdAt.toDate(),
                    hidden: document.data().hidden,
                    advertiser: document.data().advertiser,
                    userId: document.data().userId,
                    watch: document.data().watch,
                })
            })

            setReviews(docs);
            setLoading(false);
            return null;
        } catch (error) {
            console.log(error);
            setReviews(null);
            setLoading(false);
            return null;
        }
    }

    const handleFilterValue = (filter: string) => {
        if (filter === "rate") {
            return parseInt(rate);
        }
        if (filter === "advertiser") {
            return advertiser;
        }
        if (filter === "createdAt") {
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            const timestamp = Timestamp.fromDate(date);
            return timestamp;
        }
    }

    const handleDay = (value: string) => {
        if (value === "") {
            setDay("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if (parseInt(value) > 31) {
            return null;
        }
        setDay(value);
    }

    const handleMonth = (value: string) => {
        if (value === "") {
            setMonth("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if (parseInt(value) > 12) {
            return null;
        }
        setMonth(value);
    }

    const handleYear = (value: string) => {
        if (value === "") {
            setYear("");
            return null;
        }
        if (!/^\d+$/.test(value)) {
            return null;
        }
        if (parseInt(value) > 9999) {
            return null;
        }
        setYear(value);
    }

    return (
        <div>
            <div className="flex gap-4 justify-center mt-4">
                <div className="flex gap-1 items-center">
                    <input type="radio" id="rate" name="filter" checked={filter === "rate"} value="rate" onChange={e => setFilter(e.target.value)} />
                    <label htmlFor="rate">Nota</label>
                </div>
                <div className="flex gap-1 items-center">
                    <input type="radio" id="advertiser" name="filter" checked={filter === "advertiser"} value="advertiser" onChange={e => setFilter(e.target.value)} />
                    <label htmlFor="advertiser">ID Anunciante</label>
                </div>
                <div className="flex gap-1 items-center">
                    <input type="radio" id="createdAt" name="filter" checked={filter === "createdAt"} value="createdAt" onChange={e => setFilter(e.target.value)} />
                    <label htmlFor="createdAt">Data</label>
                </div>
            </div>
            {
                filter === "rate" ?
                    <select className="px-2 py-1 w-32 border border-mono text-center block m-auto mt-4" value={rate} onChange={e => setRate(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    : filter === "advertiser" ?
                        <input className="border border-mono text-center px-2 py-1 block m-auto mt-4" type="text" value={advertiser} onChange={e => setAdvertiser(e.target.value)} placeholder="ID" />
                        : filter === "createdAt" ?
                            <div className="mt-4">
                                <p className="text-center">A partir de:</p>
                                <div className="m-auto flex w-fit gap-4">
                                    <input className="border border-mono w-12 text-center" value={day} onChange={e => handleDay(e.target.value)} type="text" placeholder="dia" />
                                    <input className="border border-mono w-12 text-center" value={month} onChange={e => handleMonth(e.target.value)} type="text" placeholder="mês" />
                                    <input className="border border-mono w-12 text-center" value={year} onChange={e => handleYear(e.target.value)} type="text" placeholder="ano" />
                                </div>
                            </div>
                            : null
            }
            <button onClick={getReviews} className="block m-auto my-4 bg-black text-white hover:bg-[#333] active:bg-[#333] px-4 py-1">Listar</button>
            <p className="text-center text-red-500">{feedback}</p>
            {
                loading ?
                    <ClockAnimation />
                    :
                    <ReviewsList requests={requests} reviews={reviews} refresh={getReviews} goodToast={() => setGoodToast(true)} badToast={() => setBadToast(true)} />
            }
            <AdminToast toast={goodToast} setToast={setGoodToast} message="Avaliação atualizada!" color="#00bb00" />
            <AdminToast toast={badToast} setToast={setBadToast} message="Erro!" color="#ff0000" />
        </div>
    )
}
