import GMTIcon from "../../img/svg/gmtIcon";
import LoadingBalls from "./loadingBalls";

export default function GMTLoading() {
    return (
        <main className="p-10 bg-primary h-screen">
            <div className="m-auto flex flex-col gap-12 w-fit items-center mt-10 md:mt-32">
                <GMTIcon width="270px" fill="#ccc"/>
                <LoadingBalls width={150} color="#b3b3b3"/>
            </div>
        </main>
    )
}