import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import AdminButton from "../../../core/admin/adminButton";
import HeaderConfigModal from "./headerConfigModal";

export default function HeaderHome() {

    const [current, setCurrent] = useState("");
    const [configModal, setConfigModal] = useState(false);

    useEffect(() => {
        getCurrentImage();
    }, [configModal])

    const getCurrentImage = async () => {
        try {
            const q = query(collection(db, "headerimages"), where("display", "==", true));
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) {
                return null;
            }
            setCurrent(docsSnap.docs[0].data().url);
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    

    return (
        <div>
            <div className="bg-[#fafafa] px-4 pt-4 pb-10 relative mb-4">
                <h1 className="text-center font-bold text-xl">Imagem do Header</h1>
                {
                    current ?
                    <div className="relative my-10">
                        <img src={current} alt="current" className="h-[100px] w-full object-cover"/>
                        <div className="absolute top-0 bg-gradient-to-r from-[#00000077] via-[#000000ee] to-[#00000077] w-full h-full"></div>
                    </div>
                    :
                    <div className="mt-8">
                        <p className="my-4">Nenhuma imagem configurada</p>
                    </div>
                }
                <AdminButton onClick={() => setConfigModal(true)}>Configurar</AdminButton>
            </div>
            {
                configModal ?
                <HeaderConfigModal close={() => setConfigModal(false)}/>
                : null
            }
        </div>
    )
}