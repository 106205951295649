export default function Bannerzito({ banner, onClick }: { banner: { [key: string]: any }, onClick: () => void }) {

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        onClick();
    }

    return (
        <div className="h-fit w-[180px] flex-none select-none cursor-pointer bg-white p-1 rounded relative z-1" onClick={(e) => handleClick(e)}>
            <div className="h-[180px] w-full relative">
                <img src={banner.imgUrl} alt="banner" className="object-cover h-full w-full" />
                <div className="absolute top-0 w-full h-full"></div>
            </div>
            <div className="py-2 text-primary">
                <p className="text-[8px] text-center">{banner.brand.toUpperCase()} / {banner.year}</p>
                <h3 className="text-sm text-center font-bold -mt-1 text-primary">{banner.model.toUpperCase()}</h3>
                {/* <p className="text-sm text-center -mt-1">R${banner.price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> */}
            </div>
            <div className="absolute top-0 right-0 w-full h-full bg-[#00000099] pointer-events-none rounded"></div>
        </div>
    )
}
