import Login from "./login";
import Register from "./register";
import { useAuth } from "../../../context/authProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./forgotPassword";

export default function Gatekeeper() {

    const { user } = useAuth();
    const navigate = useNavigate();

    const [gate, setGate] = useState(0);

    useEffect(() => {
        if(user){
            navigate("/me");
        }
        // eslint-disable-next-line
    }, [user])

    return (
        <main className="py-10 px-4">
            <div className="w-full md:w-[70%] m-auto">
                <div className="flex gap-4 border-b">
                    <h3 onClick={() => setGate(0)} className="py-4 cursor-pointer flex-1 text-center" style={{backgroundColor: gate === 0 ? "#F3F3F3" : "transparent"}}>Entrar</h3>
                    <h3 onClick={() => setGate(1)} className="py-4 cursor-pointer flex-1 text-center" style={{backgroundColor: gate === 1 ? "#F3F3F3" : "transparent"}}>Registrar</h3>
                </div>
                <div className="py-10">
                    {
                        gate === 0 ?
                            <Login setGate={setGate}/>
                            : gate === 1 ?
                            <Register />
                            : <ForgotPassword/>
                    }
                </div>
            </div>
        </main>
    )
}
