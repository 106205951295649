import { useState } from "react";
import PlusIcon from "../../../../../img/svg/plusIcon";
import CreateDraftModal from "./createDraftModal";
import { useWatches } from "../../../../../context/watchProvider";
import { useAuth } from "../../../../../context/authProvider";
import EditDraftModal from "./editDraftModal";
import DeleteIcon from "../../../../../img/svg/deleteIcon";
import AdminModal from "../../../../core/admin/adminModal";
import ClockAnimation from "../../../../../img/svg/clockAnimation";
import { IWatch } from "../../watches/adminWatches";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../../../utils/functions";

export default function DraftWatches({ watches, finish, editOk, deleteOk, refresh }: { watches: IWatch[], finish: () => void, editOk: () => void, deleteOk: () => void, refresh: () => void }) {

    const { userId, user } = useAuth();
    const { deleteWatch } = useWatches();
    const navigate = useNavigate();

    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);
    const [editableWatch, setEditableWatch] = useState<IWatch | null>(null);

    const [deleteLoading, setDeleteLoading] = useState(false);
    //() => deleteWatch(userId, w.id)
    const handleEditModal = (watch: IWatch) => {
        setEditableWatch(watch);
        setEditModal(true);
    }

    const handleDeleteModal = (watch: IWatch) => {
        setEditableWatch(watch);
        setDeleteModal(true);
    }

    const handleDeleteWatch = async (watchId: string | undefined) => {
        setDeleteLoading(true);
        if (!watchId) {
            setDeleteLoading(false);
            return null;
        }
        const deleteResponse = await deleteWatch(userId, watchId);
        if (!deleteResponse) {
            setDeleteLoading(false);
            return null;
        }
        setDeleteLoading(false);
        setDeleteModal(false);
        deleteOk();
    }

    const handleCreateModal = () => {
        if (!user || !user.phoneNumber || user.phoneNumber.length < 10) {
            setPhoneModal(true);
            return null;
        }
        setCreateModal(true);
    }

    return (
        <div className="bg-[#fafafa] w-full m-auto py-4 px-2 md:px-10 mt-4 relative">
            <button onClick={handleCreateModal} className="w-fit m-auto absolute top-2 right-2 p-2 bg-[#00bb00] flex items-center gap-2 hover:bg-[#88bb88] active:bg-[#00bb00] font-bold text-white"><PlusIcon fill="#fff" width="20px" /> Criar</button>
            <h2 className="text-center font-bold text-lg mb-4">Rascunhos</h2>
            {
                watches.length ?
                    <table className="w-fit m-auto border border-[#aaaaaa]">
                        <thead>
                            <tr>
                                <th className="border border-[#aaaaaa] p-2">Marca</th>
                                <th className="border border-[#aaaaaa] p-2">Modelo</th>
                                <th className="border border-[#aaaaaa] p-2">Ano</th>
                                <th className="border border-[#aaaaaa] p-2"></th>
                                <th className="border border-[#aaaaaa] p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                watches.map((w, idx) => (
                                    <tr key={idx}>
                                        <td className="border border-[#aaaaaa] p-2">{w.brand}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.model}</td>
                                        <td className="border border-[#aaaaaa] p-2">{w.year}</td>
                                        <td className="border border-[#aaaaaa] p-2"><button className="px-2 bg-[#46B1F7] hover:bg-[#86CBF7] active:bg-[#46B1F7]" onClick={() => handleEditModal(w)}>Completar</button></td>
                                        <td className="border border-[#aaaaaa] p-2">
                                            <button onClick={() => handleDeleteModal(w)}><DeleteIcon fill="#990000" /></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p className="text-center">Nenhum relógio listado</p>
            }
            {
                createModal ?
                    <CreateDraftModal close={() => setCreateModal(false)} finish={finish}
                    />
                    : null
            }
            {
                editModal ?
                    <EditDraftModal close={() => setEditModal(false)} watch={editableWatch} setWatch={setEditableWatch} finish={editOk} refresh={refresh} />
                    : null
            }
            {
                deleteModal ?
                    <AdminModal close={() => setDeleteModal(false)}>
                        <p className="text-lg text-center font-bold">Tem certeza que deseja excluir este rascunho de relógio?</p>
                        <div className="flex w-full gap-6 m-auto my-4">
                            <button disabled={deleteLoading} onClick={() => setDeleteModal(false)} className="flex-1 disabled:bg-[#ccc] px-4 py-2 bg-black text-white font-bold hover:bg-[#333] active:bg-black">Cancelar</button>
                            <button disabled={deleteLoading} onClick={() => handleDeleteWatch(editableWatch?.id)} className="flex-1 disabled:bg-[#ccc] px-4 py-2 bg-[#ff0000] text-white font-bold hover:bg-[#ff5555] active:bg-[#ff0000]">Sim</button>
                        </div>
                        {
                            deleteLoading ?
                                <ClockAnimation />
                                : null
                        }
                    </AdminModal>
                    : null
            }
            {
                phoneModal ?
                    <AdminModal close={() => setPhoneModal(false)}>
                        <p className="text-xl text-center font-bold py-4">Para anunciar uma peça, é preciso cadastrar um número de telefone válido!</p>
                        {
                            user?.phoneNumber && user?.phoneNumber.length < 10?
                            <p className="text-lg text-center py-4">O número {formatPhoneNumber(user.phoneNumber)} é muito curto. Certifique-se de que informou o DDD corretamente.</p>
                            : null
                        }
                        <div className="flex w-full gap-6 m-auto my-4">
                            <button onClick={() => setPhoneModal(false)} className="flex-1 disabled:bg-[#ccc] px-4 py-2 bg-black text-white font-bold hover:bg-[#333] active:bg-black">Cancelar</button>
                            <button onClick={() => navigate("/me")} className="flex-1 disabled:bg-[#ccc] px-4 py-2 bg-[#00bb00] text-white font-bold hover:bg-[#88bb88] active:bg-[#88bb88]">Cadastrar telefone</button>
                        </div>
                    </AdminModal>
                    : null
            }
        </div>
    )
}